<div class="mystay-preview-mobile-widgets">
    <div class="mystay-preview-mobile-widget-container preview-mobile-widgets" *ngFor="let item of widgets"
        [ngStyle]="{'background-color': item.backgroundWidgetColor + '20' }">
        <div class="container-label">
            <div class="label-name clearfix">{{item.title}}</div>
            <span [class]="item.icon"> </span>
        </div>
        <div class="dynamic-content">
            <p [ngStyle]="{'font-size': item.contentFontSize }">{{item.content}}</p>
        </div>
    </div>
</div>