import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule, SharedModule } from '@shared';
import { ComponentsModule } from '@shared/components/components.module';
import { SubscriptionService } from '@core/services/subscription.service';
import { UserService } from '@core/services/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { UserTableService } from '@core/services/user-table.service';
import { HelpDialogComponent } from './help-dialog.component';
import { BillingHelpComponent } from './components/billing-help/billing-help.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TransportHelpComponent } from './components/transport-help/transport-help.component';
import { SettingsHelpComponent } from './components/settings-help/settings-help.component';
import { ReservationListHelpComponent } from './components/reservation-list-help/reservationlist-help.component';
import { ReportsHelpComponent } from './components/reports-help/reports-help.component';
import { RemindersHelpComponent } from './components/reminders-help/reminders-help.component';
import { InventoryHelpComponent } from './components/inventory-help/inventory-help.component';
import { HousekeepingHelpComponent } from './components/housekeeping-help/housekeeping-help.component';
import { DashboardHelpComponent } from './components/dashboard-help/dashboard-help.component';
import { CustomersHelpComponent } from './components/customers-help/customers-help.component';
import { ChatHelpComponent } from './components/chat-help/chat-help.component';
import { CalendarHelpComponent } from './components/calendar-help/calendar-help.component';

@NgModule({
  declarations: [
    HelpDialogComponent,
    BillingHelpComponent,
    TransportHelpComponent,
    SettingsHelpComponent,
    ReservationListHelpComponent,
    ReportsHelpComponent,
    RemindersHelpComponent,
    InventoryHelpComponent,
    HousekeepingHelpComponent,
    DashboardHelpComponent,
    CustomersHelpComponent,
    ChatHelpComponent,
    CalendarHelpComponent,
    BillingHelpComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    HelpDialogComponent,
    BillingHelpComponent,
    TransportHelpComponent,
    SettingsHelpComponent,
    ReservationListHelpComponent,
    ReportsHelpComponent,
    RemindersHelpComponent,
    InventoryHelpComponent,
    HousekeepingHelpComponent,
    DashboardHelpComponent,
    CustomersHelpComponent,
    ChatHelpComponent,
    CalendarHelpComponent,
    BillingHelpComponent
  ],
  providers: [],
})
export class HelpDialogModule { }
