import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { Message, ReadMessages } from '@core/models/chats/message';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class MessageService extends BaseAPIService  {
 
  // private connectionIsEstablished = false;
  private _hubConnection: HubConnection;
  private _hubConnectionId: string;
  private _subscriptionId: string;

  messageReceived = new EventEmitter<any>();
  groupJoined = new EventEmitter<any>();
  leftGroup = new EventEmitter<any>();
  messagesRead = new EventEmitter<any>();

  connectionEstablished = new EventEmitter<Boolean>();

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) {
    super(httpClient);
  }

  connect() {
    if (this._hubConnection) return;
    
    const token = TokenService.get();
    if (token) {
      this.createConnection(token);
      this.registerOnServerEvents();
      this.startConnection();
    }
  }

  disconnect() {
    if (this._hubConnection) {
      this.unRegisterOnServerEvents();
      this._hubConnection.stop();
      this._hubConnection = null;
      this._hubConnectionId = null;
    }
  }

  setSubscriptionId(subscriptionId: string) {
    this._subscriptionId = subscriptionId;
  }

  get connectionId() {
    return this._hubConnectionId;
  }

  private createConnection(token: string) {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}hubs/messages`, { accessTokenFactory: () => token })
      .build();
  }

  private startConnection(): void {
    this._hubConnection
      .start()
      .then(async () => {
        // this.connectionIsEstablished = true;
        await this.getConnectionId();

        this.connectionEstablished.emit(true);
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...');
        setTimeout(function () {
          this.startConnection();
        }, 5000);
      });
  }

  private async getConnectionId() {
    this._hubConnection.invoke('GetConnectionId').then(res => {
      this._hubConnectionId = res;
      console.log('>>> Hub connection id', this._hubConnectionId);
    });
  }

  /**
   * INVOKER
   */

  sendMessage(message: Message) {
    if (this._hubConnection) this._hubConnection.invoke('SendMessage', message);
  }

  readMessages(readMessages: ReadMessages) {
    console.log('>>> readMessages');
    if (this._hubConnection) this._hubConnection.invoke('ReadMessages', readMessages);

  }

  joinGroup(groupName: string) {
    if (this._hubConnection) this._hubConnection.invoke('JoinGroup', groupName, "");
  }

  leaveGroup(groupName: string) {
    if (this._hubConnection) this._hubConnection.invoke('LeaveGroup', groupName);
  }

  /**
   * SERVER EVENTS
   */

  registerOnServerEvents(): void {
    this._hubConnection.on('MessageReceived', (data: any) => {
      this.messageReceived.emit(data);
    });

    this._hubConnection.on('JoinGroup', (data: any) => {
      console.log('group joined >>> ', data);
      this.groupJoined.emit(data);
    });

    this._hubConnection.on('LeaveGroup', (data: any) => {
      console.log('left group >>> ', data);
      this.leftGroup.emit(data);
    });

    this._hubConnection.on('MessagesRead', (data: any) => {
      this.messagesRead.emit(data);
    });
  }

  unRegisterOnServerEvents(): void {
    this._hubConnection.off('MessageReceived');
    this._hubConnection.off('JoinGroup');
    this._hubConnection.off('LeaveGroup');
    this._hubConnection.off('MarkAsRead');
  }

}
