import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { StartChatType } from '@core/enums/message.enum';
import { ChatRoomViewModel, ChatRooms, Messages } from '@core/models/chats/message';

@Injectable()
export class ChatService extends BaseAPIService {
  private _subscriptionId: string;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  setSubscriptionId(subscriptionId: string) {
    this._subscriptionId = subscriptionId;
  }

  startNewChat(refId: string, startChatType: StartChatType , subscriptionId : string): Observable<ChatRoomViewModel> {
    return this.post(`chats`, { refId, startChatType , subscriptionId });
  }

  getUnreadMessageCount(): Observable<number> {
    return this.get(`chats/${this._subscriptionId}/unread-count`);
  }

  getActiveChatRooms(): Observable<ChatRooms> {
    return this.get(`chats/${this._subscriptionId}/active-chats`);
  }

  getAvailableChatRooms(): Observable<ChatRooms> {
    return this.get(`chats/${this._subscriptionId}/available-chats`);
  }

  getMessages(reservationId: string): Observable<Messages> {
    return this.get(`chats/${this._subscriptionId}/messages/${reservationId}`);
  }
}
