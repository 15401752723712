<section class="content">
    <div class="content-block">
        <div class="block-header">
            <!-- breadcrumb -->
            <app-breadcrumb [title]="'guestApp'" [items]="['home', 'settings','guestApp']" [active_item]="'hotelGuide'"></app-breadcrumb>
        </div>
        <div class="mystay-container-content">
            <form #hotelGuideForm="ngForm"  [formGroup]="hotelGuideFormGroup">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <app-title-content [title]="'hotelGuide' | translate"
                            [subTitle]="'hotelGuideSubtitle' | translate"></app-title-content>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <mat-label class="mystay-input-upload-label">{{'uploadHotelGuidePDF' | translate}}</mat-label>
                                    <mat-form-field class="mystay-input-upload" appearance="outline">
                                        <button
                                            style="width: 130px; height: 36px; border-radius: 0px; border: 0px; background: transparent; "
                                            matPrefix (click)="f_inputPdf.click()">
                                            <button type="button" class="mystay-button-choose-file">{{'chooseFile' | translate}}</button>
                                        </button>
                                        <input type="text" readonly matInput formControlName="pdfName" />
                                        <input type="file" hidden #f_inputPdf
                                            (change)="handleFileInputChange($event,f_inputPdf.files)"
                                            formControlName="pdfFile" accept=".pdf" />
                                        <mat-error
                                            *ngIf="hotelGuideFormGroup.controls['pdfFile']?.hasError('required')">
                                            {{'pdfFileIsRequired' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="row">
                            <div class="col-12">
                                <app-preview-mobile-hotel-guide [subscribeHotelGuideReceiver]="subscribeHotelGuideEvent.asObservable()" ></app-preview-mobile-hotel-guide>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="mystay-container-action-submit form-actions">
                        <button type="submit"  mat-raised-button color="primary" (click)="onSubmit()" [disabled]="isUploadFile">{{'save' | translate}}</button> 
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>