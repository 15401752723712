import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import {
  MAT_OPTION_PARENT_COMPONENT,
  MatOptgroup,
  MatOption,
  MatOptionParentComponent,
} from '@angular/material/core';
import { AbstractControl } from '@angular/forms';
import { MatPseudoCheckboxState } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-all-option',
  templateUrl: './select-all-option.component.html',
  styleUrls: ['./select-all-option.component.scss'],
})
export class SelectAllOptionComponent extends MatOption implements OnInit, OnDestroy {
  protected unsubscribe: Subject<any>;
  selectedOptions: any[] = [];
  isAllSelected: boolean = false;

  @Input() control: AbstractControl;
  @Input() title: string;
  @Input() values: any[] = [];
  @Input() selectAllInitially: boolean = false;
  @Input() selectAllState: 'checked' | 'unchecked' | 'indeterminate' = 'unchecked';

  @HostBinding('class') cssClass = 'mat-option';

  @HostListener('click') toggleSelection(): void {
    this.selectAllToggle();
    this.refresh();
  }

  constructor(
    elementRef: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_OPTION_PARENT_COMPONENT) parent: MatOptionParentComponent,
    @Optional() group: MatOptgroup,
    private translate: TranslateService,
  ) {
    super(elementRef, changeDetectorRef, parent, group);
    this.translate.get('selectAll').subscribe((res: string) => {
      this.title = res;
    });
  }

  ngOnInit(): void {
    this.unsubscribe = new Subject<any>();

    if (this.selectAllInitially && this.values && this.values.length > 0) {
      this.control.setValue([...this.values]);
    }

    this.control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.refresh();
    });

    this.refresh();
    this._changeDetectorRef.markForCheck();
  }

  selectAllToggle() {
    if (this.isAllSelected || this.selectedPartially) {
      this.control.setValue([]);
      this.isAllSelected = false;
      this.refresh();
    } else {
      this.control.setValue([...this.values]);
      this.isAllSelected = true;
      this.refresh();
    }
  }

  refresh(): void {
    this.isAllSelected = this.selectedItemsCount === this.values.length;
    this._changeDetectorRef.markForCheck();
  }

  deselectAllOptions() {
    this.selectedOptions = [];
    this.control.setValue(this.selectedOptions);
    this.refresh();
  }

  get selectedItemsCount(): number {
    return this.control && Array.isArray(this.control.value)
      ? this.control.value.filter(el => el !== null).length
      : 0;
  }

  get selectedAll(): boolean {
    return this.selectedItemsCount === this.values.length;
  }

  get selectedPartially(): boolean {
    const selectedItemsCount = this.selectedItemsCount;
    const partiallySelected = selectedItemsCount > 0 && selectedItemsCount < this.values.length;
    return partiallySelected;
  }

  get checkboxIcon(): string {
    if (this.isAllSelected) {
      return 'check_box';
    } else if (this.selectedPartially) {
      return 'indeterminate_check_box';
    } else {
      return 'check_box_outline_blank';
    }
  }
}
