import { UserRoles } from '@core/utils/user-roles.enum'
import { RouteInfo } from './sidebar.metadata'
export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'reception',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION]
  },
  {
    path: 'stickynotes',
    title: 'stickynotes',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-sticky-note',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER]
  },
  {
    path: 'customers',
    title: 'customers',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-user-friends',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER , UserRoles.RECEPTION]
  },
  {
    path: 'inventory',
    title: 'inventory',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-barcode',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER , UserRoles.RECEPTION]
  },
  {
    path: 'housekeeping',
    title: 'housekeeping',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-soap',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER , UserRoles.RECEPTION , UserRoles.HOUSE_KEEPING]
  },
  {
    path: 'chat',
    title: 'chat',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-comments',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION]
  },
  {
    path: 'transport',
    title: 'transport',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-taxi',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION , UserRoles.TRANSPORTER]
  },
  {
    path: 'admin/subscriptions',
    title: 'subscriptions',
    view: "admin",
    iconType: 'fontAwesome',
    icon: 'fas fa-hotel',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER]
  },
  {
    path: 'admin/super-admins',
    title: 'administrators',
    view: "admin",
    iconType: 'fontAwesome',
    icon: 'fas fa-user-tie',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN]
  },
  {
    path: 'reports',
    title: 'reports',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-book',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    visible: true,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER]
  },
  {
    path: 'settings',
    title: 'settings',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-cog',
    class: 'menu-toggle settings-menu',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    visible: true,
    submenu: [
      {
        path: 'settings',
        title: 'system',
        view: "hotel",
        iconType: 'fontAwesome',
        icon: 'fas fa-cog',
        class: 'ml-menu  system-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        visible: true,
        submenu: []
      },
      {
        path: 'theme-settings',
        title: 'themeSettingsHeader',
        view: "hotel",
        iconType: 'fontAwesome',
        icon: 'fas fa-mobile', 
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        visible: true,
        submenu: [
        
          {
            path: 'theme-settings/brand-identity',
            title: 'themeSettingsBrandIdentity',
            view: "hotel",
            iconType: '',
            icon: '',
            class: 'ml-menu2',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            visible: true,
            submenu: []
          },
          {
            path: 'theme-settings/housekeeping',
            title: 'themeSettingsHousekeeping',
            view: "hotel",
            iconType: '',
            icon: '',
            class: 'ml-menu2',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            visible: true,
            submenu: []
          },
          {
            path: 'theme-settings/breakfast',
            title: 'themeSettingsBreakfast',
            view: "hotel",
            iconType: 'feather',
            icon: '',
            class: '',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            visible: true,
            submenu: []
          },
          {
            path: 'theme-settings/map',
            title: 'themeSettingsGoogleMap',
            view: "hotel",
            iconType: '',
            icon: '',
            class: '',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            visible: true,
            submenu: []
          },
          {
            path: 'theme-settings/gallery',
            title: 'themeSettingsGallery',
            view: "hotel",
            iconType: '',
            icon: '',
            class: '',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            visible: true,
            submenu: []
          },
          {
            path: 'theme-settings/check-in',
            title: 'themeSettingsCheckIn',
            view: "hotel",
            iconType: '',
            icon: '',
            class: '',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            visible: true,
            submenu: []
          },
          {
            path: 'theme-settings/check-out',
            title: 'themeSettingsCheckOut',
            view: "hotel",
            iconType: '',
            icon: '',
            class: '',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            visible: true,
            submenu: []
          },
        ],
      },
    ],
    roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER]
  },
  {
    path: 'support',
    title: 'support',
    view: "hotel",
    iconType: 'fontAwesome',
    icon: 'fas fa-question-circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    visible: true,
    submenu: []
  }
]