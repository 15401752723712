<div class="container-fluid mystay-preview-mobile">
    <div class="mystay-container-preview-mobile">
        <div class="header-preview">
            <h3>{{'preview' | translate}}</h3>
            <button type="button" class="mystay-button-qrcode" color="primary" (click)="this.qrScanPreviewClick.next($event)">
                <i class="fa fa-qrcode" aria-hidden="true"></i>
            </button>
        </div>
        <div class="mystay-preview-mobile-inside">
            <google-map class="mystay-preview-mobile-background" #myGoogleMap height="100%" width="100%" [zoom]="zoom"
                [center]="center" [options]="options">
                <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position"
                    [label]="marker.label" [title]="marker.title" [options]="marker.options">
                    {{openInfo(markerElem, marker.title)}}
                </map-marker>
                <map-info-window class="map-info-window">
                    <div class="title">{{hotels.fullName}}</div><br />
                </map-info-window>
            </google-map>
           
            <div class="mystay-preview-mobile-image"></div>
            <div class="mystay-preview-mobile"> </div>
            <div class="mystay-previous-arrow"> <i class="previous fas fa-chevron-left"></i>  </div>
            <div class="footer-information">{{hotels.address}}</div>
        </div>
    </div>
</div>


