<div id="leftsidebarContrainer">
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar"  [ngStyle]="this.authService.currentSubscription.value.id && !this.authService.accountSetupCompleted ? {'margin-top': '20px'} : {}"
  >
    <button class="mystay-hide-show-slidebar-open " *ngIf="!isOpenedSlideBar" (click)="mobileMenuSidebarOpen()" type="button"> <i class="fas fa-chevron-right"></i> </button>
    <button class="mystay-hide-show-slidebar-close"  *ngIf="isOpenedSlideBar" (click)="callSidemenuCollapse()" type="button"> <i class="fas fa-chevron-left"></i> </button>
    <div class="navbar-header" style="margin-left:0px;padding-left:0px">
      <ul class="nav navbar-nav flex-row" style="overflow: hidden;">
        <li class="nav-item logo">
          <div class="navbar-brand" style="margin-top:10px">
            <div class="logo-image">
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- Menu -->
    <div class="menu" id="nav-main-menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'"  visibility="hover">
        <ul class="list" *ngIf="subscriptionId || isAdminMode">
          <!-- Top Most level menu -->
          <ng-container *ngFor="let sidebarItem of filteredSideBar;">
            <li visibility="hover" [class.active-top]="isActive(sidebarItem)" *ngIf="hasPermission(sidebarItem)" class="handle-active">
              <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
              <a [matTooltip]="sidebarItem.title | translate" [matTooltipPosition]="'right'" [matTooltipDisabled]="isOpenedSlideBar" [routerLink]="sidebarItem.class === '' ? getRouterLinkForMenuItem(sidebarItem) : [subscriptionId,'settings','general']" *ngIf="!sidebarItem.groupTitle"
                [ngClass]="[sidebarItem.class]" (click)="callToggleMenu($event, sidebarItem.submenu.length)"
                class="menu-top"  [attr.data-path]="sidebarItem.path">
                <i-feather *ngIf="sidebarItem.iconType === 'feather'" [name]="sidebarItem.icon"
                  class="sidebarIcon"></i-feather>
                <i *ngIf="sidebarItem.iconType === 'fontAwesome'" class="sidebarIcon " [class]="sidebarItem.icon"></i>
                <span class="hide-menu">{{sidebarItem.title | translate}}
                </span>
                <span *ngIf="sidebarItem.badge !== '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
              </a>
              <!-- First level menu -->
              <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
                <li *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
                  [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [getRouterLinkForSubMenuItem(sidebarSubItem1)+'/'+sidebarSubItem1.path]"
                    (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]">
                    {{sidebarSubItem1.title | translate}} 
                  </a>
                  <!-- Second level menu -->
                  <ul class="ml-menu-2" *ngIf="sidebarSubItem1.submenu.length > 0">
                    <li *ngFor="let sidebarSubItem2 of sidebarSubItem1.submenu"
                      [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'ml-menu'">
                      <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [getRouterLinkForSubMenuItem(sidebarSubItem1)+'/'+sidebarSubItem2.path]"
                        (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)"
                        [ngClass]="[sidebarSubItem2.class]">
                        {{sidebarSubItem2.title | translate}}
                      </a>
                      <!-- Third level menu -->
                      <ul class="ml-menu-3" *ngIf="sidebarSubItem2.submenu.length > 0">
                        <li *ngFor="let sidebarSubItem3 of sidebarSubItem2.submenu"
                          [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                          <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                            (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                            [ngClass]="[sidebarSubItem3.class]">
                            {{sidebarSubItem3.title | translate}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
        </ng-container>
        </ul>
      </ng-scrollbar>
      <div *ngIf="authService.isSuperAdministrator && !isAdminMode" class="admin-button-container mystay-button-container" [ngClass]="isOpenedSlideBar? 'opendSlideBar' : 'closedSlideBar' ">
        <button mat-raised-button color="primary" class="admin-button mystay-button-submit" (click)="isAdminMode = !isAdminMode" [routerLink]="['admin','subscriptions']" 
        *ngIf="isOpenedSlideBar"><span>{{'sidebarAdminView' | translate}}</span></button>
        <div class="m-l-0 mystay-button-container" matTooltip="{{ 'adminView' | translate }}" [matTooltipPosition]="'left'">
          <button mat-mini-fab color="primary" class="admin-button-small mystay-button-submit" (click)="isAdminMode = !isAdminMode" [routerLink]="['admin','subscriptions']"  *ngIf="!isOpenedSlideBar">
            <i style='color:#FFF'  class="fas fa-user-shield"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>


