import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PreviewMobileDeviceComponent } from './preview-mobile-device.component';
import { PreviewMobileWidgetsModule } from '../preview-mobile-widgets/preview-mobile-widgets.module';
import { MobileNavigatorBarModule } from '../mobile-navigator-bar/mobile-navigator-bar.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PreviewMobileDeviceComponent
  ],
  imports: [
    CommonModule,
    PreviewMobileWidgetsModule,
    MobileNavigatorBarModule,
    TranslateModule,
  ],
  exports: [
    PreviewMobileDeviceComponent
  ],
  providers: [DatePipe],
})
export class PreviewMobileDeviceModule { }
