import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumb, BreadcrumbService } from '@core/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { HelpDialogComponent } from '@shared/modules/help-dialog/help-dialog.component';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input()
  title!: string;
  @Input()
  items!: string[];
  @Input()
  active_item!: string;
  @Input() showBreadcrumbs: boolean = true;
  breadcrumbs: BreadCrumb[] = [];
  homeUrl: string;

  constructor(
    public translateService: TranslateService,
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.breadcrumbService.breadcrumbs.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs;
      const subscriptionId = this.route.snapshot.paramMap.get('subscriptionId');
      this.homeUrl = subscriptionId ? `/${subscriptionId}/dashboard` : '/';
    });
  }

}
