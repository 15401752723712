import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PreviewMobileWidgetsComponent } from './preview-mobile-widgets.component'

@NgModule({
  declarations: [PreviewMobileWidgetsComponent],
  imports: [CommonModule],
  exports: [PreviewMobileWidgetsComponent]
})
export class PreviewMobileWidgetsModule {}
