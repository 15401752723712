import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material.module';
import { FeatherIconsModule } from './components/feather-icons/feather-icons.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SelectAllOptionModule } from './components/select-all-option/select-all-option.module';
import { HelpDialogModule } from './modules/help-dialog/help-dialog.module';
import { C } from '@angular/cdk/keycodes';
import { MyStaySuccessNotificationComponent } from './components/success-notification/mystay-success-notification';

@NgModule({
  declarations: [
    MyStaySuccessNotificationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    SelectAllOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    MatIconModule,
    MatButtonModule,
    HelpDialogModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    SelectAllOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    MatIconModule,
    MatButtonModule,
    MyStaySuccessNotificationComponent
  ],
})
export class SharedModule {}
C