import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ThemeSettingsService } from '@core/services/theme-settings.service';
import { decode } from 'html-entities';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';
import { QRCodeComponent } from 'app/modules/reservations/details/dialogs/qrcode/qrcode.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { IBreakfast, IBreakfastUpdate, IThemeSettings } from '@core/models/theme-settings/theme-settings.model';

@Component({
  selector: 'app-breakfast',
  templateUrl: './breakfast.component.html',
  styleUrls: ['./breakfast.component.scss'],
})
export class BreakfastComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  subscription: Subscription;
  subscriptionId: string;

  constructor(
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    // private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    super();
  }

  public subscribeBreakfastEvent: Subject<IBreakfast> = new Subject();
  public subscribeBreakfastContentEvent: Subject<any> = new Subject();
  public file_store: FileList;
  public editor = ClassicEditor;
  public headerImage: string = '';
  isUploadFile: boolean = false;
  content: any = ``;
  breakfastProps: IBreakfast = {
    headerImage: '',
    headerImageUrl: '',
    description: '',
    notification: '',
  };

  Editor = ClassicEditor;
  ckeditorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link'],
    table: {
      contentToolbar: ['tableRow', 'tableColumn', 'mergeTableCells', 'tableCellProperties'],
    },
  };

  headerImageUrl = this.breakfastProps.headerImageUrl.split('/').pop();

  breakfastGroupForm = new FormGroup({
    headerImage: new FormControl(this.breakfastProps.headerImageUrl, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    headerImageName: new FormControl(this.headerImageUrl, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    description: new FormControl(this.breakfastProps.description, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    notification: new FormControl(this.breakfastProps.notification, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    backgroundUploaded: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, this.fileTypeValidator(['image/jpeg', 'image/png'])],
    }),
  });

  folderBackground: string = 'settingsImage/breakfast';

  public breakfastDropdown: boolean = true;
  public breakfastIncludedControl = new FormControl(this.breakfastDropdown);

  breakfastIncludedList = [];

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.subscriptionId = paramMap.get('subscriptionId');
      firstValueFrom(this.themeSettingsService.getThemeSettings(this.subscriptionId)).then(
        (response: IThemeSettings) => {
          this.translationDropdownList();
          this.breakfastGroupForm.controls.headerImageName.patchValue(
            this.getNameUrl(
              response.appBackgroundImages.breakfastBackgroundUrl.substring(
                response.appBackgroundImages.breakfastBackgroundUrl.lastIndexOf('/') + 1,
                response.appBackgroundImages.breakfastBackgroundUrl.length
              )
            )
          );

          const contentBreakfast = response.contents;
          if (contentBreakfast.length > 0) {
            contentBreakfast.map(item => {
              if (item.key === 'breakfast') {
                this.breakfastGroupForm.controls.description.patchValue(`${decode(item.contentBody)}`);
                this.breakfastProps.headerImageUrl = item.featuredImageUrl;
                this.breakfastProps.description = item.contentBody;
              }

              if (item.key === 'breakfast_not_included') {
                this.breakfastGroupForm.controls.notification.patchValue(`${item.contentBody}`);
                this.breakfastProps.notification = item.contentBody;
              }
            });
          }
          this.subscribeBreakfastEvent.next(this.breakfastProps);
        }
      );
    });
  }

  getNameUrl(fullUrl: string) {
    const url = fullUrl;
    const urlLength = url.lastIndexOf('?');
    const result = url.slice(0, urlLength);
    return result;
  }

  translationDropdownList() {
    this.translate.use(this.translate.store.currentLang);
    this.cdr.detectChanges();
    this.translate.get(['breakfastIncluded', 'breakfastNotIncluded']).subscribe(v => {
      this.breakfastIncludedList = [
        { viewValue: v['breakfastIncluded'], value: true },
        { viewValue: v['breakfastNotIncluded'], value: false },
      ];
      this.subscribeBreakfastContentEvent.next(this.breakfastIncludedList);
    });
  }

  fileTypeValidator(allowedMimeTypes: string[]) {
    return (control: FormControl): { [key: string]: any } | null => {
      const file = control.value;
      if (file) {
        const isValidType = allowedMimeTypes.includes(file.type);
        return isValidType ? null : { invalidFileType: true };
      }
      return null;
    };
  }

  handleFileInputBackgroundChange(event: any, l: FileList): void {
    this.isUploadFile = true;
    this.file_store = l;
    if (l.length) {
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.breakfastGroupForm.controls.headerImageName.patchValue(`${l[0].name}${count}`);
      if (this.breakfastGroupForm.controls['backgroundUploaded']?.hasError('invalidFileType')) {
        if (event.target.files && event.target.files[0]) {
          const reader = new FileReader();
          this.route.paramMap.subscribe(paramMap => {
            this.subscriptionId = paramMap.get('subscriptionId');
            const formData: any = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('folder', this.folderBackground);
            formData.append('Restrict', 'false');
            formData.append('SubscriptionId', this.subscriptionId);

            const upload$ = this.themeSettingsService.uploadFileToCloudStorage(formData);
            this.subscription = upload$.subscribe(response => {
              this.breakfastProps.headerImageUrl = response.imageUrl;
              this.breakfastGroupForm.controls.headerImageName.patchValue(
                this.getNameUrl(
                  response.imageUrl.substring(response.imageUrl.lastIndexOf('/') + 1, response.imageUrl.length)
                )
              );
              this.isUploadFile = false;
              const mapping = {
                subscriptionId: this.subscriptionId,
                breakfastBackgroundUrl: response.imageUrl,
                appBackground: 0,
              };
              const upload$1 = this.themeSettingsService.updateBackgroundImage(mapping);
              upload$1.subscribe(res => {
                res;
              });
            });
            this.isUploadFile = false;
          });

          reader.readAsDataURL(event.target.files[0]); // read file as data url
          let url: any;
          reader.onload = event => {
            url = event.target.result;
            this.breakfastProps.headerImageUrl = url;
            this.subscribeBreakfastEvent.next(this.breakfastProps);
          };
        }
      }
    } else {
      this.breakfastGroupForm.controls.headerImageName.patchValue('');
      this.isUploadFile = false;
    }
  }

  handleDescriptionChange(breakfast: any) {
    this.breakfastProps.description = breakfast.value.description;
    this.subscribeBreakfastEvent.next(this.breakfastProps);
  }

  handleNotificationChange(breakfast: any) {
    this.breakfastProps.notification = breakfast.value.notification;
    this.subscribeBreakfastEvent.next(this.breakfastProps);
  }

  async onSubmit() {
    if (
      this.breakfastGroupForm.controls.description.value !== '' &&
      this.breakfastGroupForm.controls.notification.value !== ''
    ) {
      this.isUploadFile = true;
      this.route.paramMap.subscribe(paramMap => {
        this.subscriptionId = paramMap.get('subscriptionId');

        const updateBreakfast: IBreakfastUpdate = {
          id: this.subscriptionId,
          restaurantImageURL: this.breakfastProps.headerImageUrl,
          breakfastIncludeMessage: this.breakfastGroupForm.controls.description.value,
          breakfastexcludeMessage: this.breakfastGroupForm.controls.notification.value,
        };

        firstValueFrom(this.themeSettingsService.updateBreakfast(updateBreakfast))
          .then(() => {
            const successMessage = this.translate.instant('breakfastHasBeenSaved');
            // this.snackbarService.alertSuccess(successMessage);
            this.toastr.success(successMessage, 'Operation Completed', { timeOut: 3500 });
            this.isUploadFile = false;
          })
          .catch(() => {
            const failedMessage = this.translate.instant('failedToSave');
            // this.snackbarService.alertError(failedMessage);
            this.toastr.error(failedMessage, 'Operation Completed', { timeOut: 3500 });
            this.isUploadFile = false;
          });
      });
    } else {
      const failedMessage = this.translate.instant('failedToSave');
      // this.snackbarService.alertError(failedMessage);
      this.toastr.error(failedMessage, 'Operation Failed', { timeOut: 3500 });
    }
  }

  openQRCodeDialog() {
    const type = this.breakfastDropdown ? 'breakfastIncluded' : 'breakfastNotIncluded';
    this.themeSettingsService.getQRCodeBreakfast(this.subscriptionId, type).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        const dialogRef = this.dialog.open(QRCodeComponent, {
          data: {
            qrCodeImage: base64data,
            qrCodeTitle: 'QR-Code for preview',
          },
        });

        this.subs.sink = dialogRef.afterClosed().subscribe(res => {});
      };
    });
  }

  goToGooglePlayStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
  goToAppStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
}
