import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@core/services/config.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { InConfiguration, AuthService, LanguageService, RightSidebarService, QuickSearchSidebarService } from '@core';
import { TranslateService } from '@ngx-translate/core';

interface Notifications {
  message: string;
  time: string;
  icon: string;
  color: string;
  status: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('openMenu1') openMenu1: ElementRef;
  @ViewChild('openMenu2') openMenu2: ElementRef;
  @ViewChild('openMenu3') openMenu3: ElementRef;

  public config!: InConfiguration;
  userImg?: string;
  homePage?: string;
  isNavbarCollapsed = true;
  flagvalue: string | string[] | undefined;
  countryName: string | string[] = [];
  langStoreValue?: string;
  defaultFlag?: string;
  isOpenSidebar?: boolean;
  docElement?: HTMLElement;
  isFullScreen = false;
  isProfileMenuOpen = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rightSideService : RightSidebarService, 
    private quickSearchSidebarService : QuickSearchSidebarService,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    public languageService: LanguageService
  ) {
    super();
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.toggleButton.nativeElement &&
        e.target !== this.openMenu1.nativeElement &&
        e.target !== this.openMenu2.nativeElement &&
        e.target !== this.openMenu3.nativeElement 
      ) {
        this.isProfileMenuOpen = false;
      }
    });
  }

  get subscriptionId() {
    return this.authService.currentSubscription.value.id;
  }

  get isSuperAdminOrOwner() {
    return this.authService.isSuperAdministrator || this.authService.isAccountOwner;
  }

  ngOnInit() {
    this.config = this.configService.configData;
    this.userImg = this.authService.currentUserValue.img;
    this.docElement = document.documentElement;
    this.homePage = 'dashboard';
    this.langStoreValue = localStorage.getItem('lang') as string;
  
    this.authService.currentSubscription.subscribe((value) => {
      if(this.authService.currentSubscription.value.id && !this.authService.accountSetupCompleted) {
        document.documentElement.style.setProperty('--content-top-padding', '65px');
      } else
      {
        document.documentElement.style.setProperty('--content-top-padding', '25px');
      }
    })
  }

  openActivityLogMenu() {
    this.rightSideService.setRightSidebar(true);
  }
  
  openQuickSearchMenu() {
    this.quickSearchSidebarService.setRightSidebar(true);
  }
  //  <a *ngIf="row.currentReservation" routerLink="/{{this.subscriptionId}}/reservations/{{row.currentReservation.reservationId}}"> {{row.currentReservation.bookingNumber}}</a>

  goToSettings() {
    this.router.navigate(['/'+this.authService.currentSubscription.value.id+'/settings/general']);
  }
  callFullscreen() {
    if (!this.isFullScreen) {
      if (this.docElement?.requestFullscreen != null) {
        this.docElement?.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: Event, className: string) {
    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className
    );
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }

    const hasClass2 = this.document.body.classList.contains('side-closed');
    if (hasClass2) {
      this.renderer.removeClass(this.document.body, 'side-closed');
    } else {
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  callSidemenuCollapse(event: Event) {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'false');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'true');
    }
    const parentElement = (event.target as HTMLInputElement).closest('li');
    const activeClass = parentElement?.classList.contains('active');

    if (activeClass) {
      this.renderer.removeClass(parentElement, 'active');
    } else {
      this.renderer.addClass(parentElement, 'active');
    }
  }

  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        window.location.href = '/authentication/signin';
      }
    });
  }

  openProfileMenu(isOpen: boolean) {
    this.isProfileMenuOpen = isOpen;
  }
}
