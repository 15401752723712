import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ITransport } from '@core/interfaces';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-preview-mobile-transport',
  templateUrl: './preview-mobile-transport.component.html',
  styleUrls: ['./preview-mobile-transport.component.scss']
})
export class PreviewMobileTransportComponent implements OnInit {
  @Input() subscribeTransportReceiver: Observable<ITransport> = null
  public constructor (private elRef: ElementRef) {}

  public transport: ITransport = {
    transportMessage: '',
  }

  
 
  ngOnInit (): void {
    this.subscribeTransportReceiver?.subscribe((result:ITransport) => {
      this.transport.transportMessage = result.transportMessage;
    });
   
  }

 
}
