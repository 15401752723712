import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppSetting } from 'app/app-settings';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { Constant } from '@core/utils/constants';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type':  'application/json' })};

export class BaseAPIService extends UnsubscribeOnDestroyAdapter {

    public constructor(protected http: HttpClient) {
        super();
    }

    protected get(path: string, param: string = '') : Observable<any> {
        const apiPath = this.getApiPath(path, param);
        return this.http.get(AppSetting.apiUrl + apiPath, {headers: this.getHeaders()});
    }

    protected getBlob(path: string, param: string = '') {
        const apiPath = this.getApiPath(path, param);
        return this.http.get(AppSetting.apiUrl + apiPath, {
            headers: this.getHeaders(),
            responseType: 'blob'
        });
    }

    protected post(path: string, param: any): Observable<any> {
        const apiPath = this.getApiPath(path);
        let headers = this.getHeaders();
    
        if (param instanceof FormData) {
            headers = headers.delete('Content-Type');
        } else {
            param = JSON.stringify(param);
        }
    
        return this.http.post(AppSetting.apiUrl + apiPath, param, { headers: headers });
    }
    

    protected put(path: string, param: any) : Observable<any> {
        const apiPath = this.getApiPath(path);
        return this.http.put(AppSetting.apiUrl + apiPath, JSON.stringify(param), {headers: this.getHeaders()})
    }

    protected delete(path: string) : Observable<any> {
        const apiPath = this.getApiPath(path);
        return this.http.delete(AppSetting.apiUrl + apiPath, {headers: this.getHeaders()});
    }

    protected postMultipart(path: string, file: FormData) {
        const apiPath = this.getApiPath(path);
        return  this.http.post(AppSetting.apiUrl + apiPath, file, { headers: new HttpHeaders({ Authorization: 'Bearer ' + TokenService.get() }) })
              
    }
    
    // protected test() : Observable<any> {
    //     return this.http.get;
    // }

    private getApiPath(path: string, param?: string): string{
        const api = 'api/v1';
        let combindedPath = [api, path].join('/');
        const hasQuestionMark = Constant.hasQuestionMark.test(combindedPath);
        const prefix = hasQuestionMark ? '&' : '?';
        
        if(param)
            combindedPath += prefix + param;

        return combindedPath
    }

    private getHeaders() : HttpHeaders
    {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            //'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + TokenService.get()
        });
    }
}
