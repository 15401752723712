import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { InConfiguration, DirectionService, AuthService } from '@core';
import { ConfigService } from '@core/services/config.service';
import { ActivityLog } from '@core/models/activityLog/activity-log.model';
import { ActivityLogService } from '@core/services/activity-log.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { SignalRService } from '@core/services/signalr.service';
import { QuickSearchSidebarService } from '@core/services/quicksearchsidebar.service';
import { SidebarSearchService } from '@core/services/sidebarSearch.service';
import { CustomerSearchDTO } from '@core/models/customers/customers.model';
import { ReservationStatusTypes } from '@core/models/receptions/reception-card.model';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quick-search-sidebar',
  templateUrl: './quick-search-sidebar.component.html',
  styleUrls: ['./quick-search-sidebar.component.scss'],
})
export class QuickSearchSidebarComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ViewChild('myCanvas') myCanvas : ElementRef;
  @ViewChild("search") searchElement : ElementRef;

  selectedBgColor = 'white';
  maxHeight!: string;
  maxWidth!: string;
  showpanel = false;
  isOpenSidebar!: boolean;
  isLoading: boolean = false;
  public innerHeight?: number;
  headerHeight = 60;
  public config!: InConfiguration;
  customerResultList: Array<CustomerSearchDTO> = [];
  searchFilter: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public elementRef: ElementRef,
    private quickSearchSidebarService: QuickSearchSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private sidebarSearchService: SidebarSearchService,
    private router: Router,
    private signalRService: SignalRService
  ) {
    super();
  }
  ngOnInit() {
    this.config = this.configService.configData;
    this.isOpenSidebar = true;
    this.subs.sink = this.quickSearchSidebarService.sidebarState.subscribe(isRunning => {
      this.isOpenSidebar = isRunning;
      if (this.isOpenSidebar) {
        this.initializeSidebar();
      }
    });

    this.subs.sink = this.authService.currentSubscription.subscribe(subscription => {
      if (!subscription.id) {
        this.isOpenSidebar = false;
      }
    });

    this.setRightSidebarWindowHeight();
  }

  get ReservationStatusType(): typeof ReservationStatusTypes {
    return ReservationStatusTypes;
  }


  initializeSidebar() {
    setTimeout(()=>{ 
      this.searchElement.nativeElement.focus();
    },0);  
  }


  prepareActivityText(text: string, fieldValue1: string) {
    if (!fieldValue1) {
      return { before: text, id: '', after: '' };
    }

    const parts = text.split(fieldValue1);
    return {
      before: parts[0],
      id: fieldValue1,
      after: parts[1] ? parts[1] : '',
    };
  }

  navigateToReservationDetail(item : CustomerSearchDTO): void {
      this.router.navigate([`/${this.authService.currentSubscriptionValue.id}/reservations/${item.latestReservationId}`]);
      setTimeout(() => {
        this.closeSidebar();
      }, 100);
  }

  draw() {
    const ctx = this.myCanvas.nativeElement.getContext('2d');
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'rgba(100, 100, 100, 0.5)';
    ctx.beginPath();
    ctx.moveTo(25, 25);
    ctx.lineTo(105, 25);
    ctx.lineTo(25, 105);
    ctx.fill();
  }

  setRightSidebarWindowHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.maxHeight = height + '';
    this.maxWidth = '500px';
  }

  getDayForRow(dateIn: Date) {
    dateIn = new Date(dateIn);
    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (today.toDateString() == dateIn.toDateString()) {
      return 'Today';
    } else if (yesterday.toDateString() == dateIn.toDateString()) {
      return 'Yesterday';
    } else {
      return '';
    }
  }

  closeSidebar() {
    this.isOpenSidebar = false;
    this.searchFilter = '';
    this.customerResultList = [];
  }


  searchActivities() {
    if(this.searchFilter.length > 2) {
      this.isLoading = true;
      const subscriptionId = this.authService.currentSubscriptionValue.id;
      if (subscriptionId) {
        firstValueFrom(this.sidebarSearchService.getSearchResult(this.authService.currentSubscriptionValue.id, this.searchFilter)).then(
          items => {
            this.customerResultList = items;
            this.isLoading = false;
          });
      }
    } else{
      this.customerResultList = [];
    }
  }
}
