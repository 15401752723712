import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { IHotelMap } from '@core/models/theme-settings/theme-settings.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-preview-mobile-google-map',
  templateUrl: './preview-mobile-google-map.component.html',
  styleUrls: ['./preview-mobile-google-map.component.scss'],
})
export class PreviewMobileGoogleMapComponent implements OnInit {
  constructor(private ngZone: NgZone) {}
  @Input() subscribeHotelMapReceiver: Observable<IHotelMap> = null;
  @Input() subscribeHotelMapCoordinatesReceiver: Observable<IHotelMap> = null;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @Output() qrScanPreviewClick: EventEmitter<any> = new EventEmitter();

  zoom = 14;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  hotelLatitude!: any;
  hotelLongitude!: any;

  markers = [];
  infoContent = 'My Content';

  hotels: IHotelMap = {
    fullName: '',
    id: '',
    hotelLatitude: 0,
    hotelLongitude: 0,
    address: '',
  };

  latitude: number = 0;
  longitude: number = 0;

  ngOnInit() {
    if (this.subscribeHotelMapReceiver) {
      this.subscribeHotelMapReceiver?.subscribe(result => {
        if (!result.isUpdateXY) {
          this.hotels.id = result.id;
          this.hotels.fullName = result.fullName;
          this.hotels.address = result.address;
        } else {
          this.hotels.id = result.id;
          this.hotels.fullName = result.fullName;
          this.hotels.hotelLatitude = result.hotelLatitude;
          this.hotels.hotelLongitude = result.hotelLongitude;
          this.hotels.address = result.address;
          this.center = {
            lat: this.hotels.hotelLatitude,
            lng: this.hotels.hotelLongitude,
          };
          this.hotelLatitude = this.hotels.hotelLatitude;
          this.hotelLongitude = this.hotels.hotelLongitude;
          this.addMarker(this.center.lat, this.center.lng);
        }
      });
    }

    if (this.subscribeHotelMapCoordinatesReceiver) {
      this.subscribeHotelMapCoordinatesReceiver?.subscribe(result => {
        this.hotels.id = result.id;
        this.hotels.fullName = result.fullName;
        this.hotels.hotelLatitude = result.hotelLatitude;
        this.hotels.hotelLongitude = result.hotelLongitude;
        this.hotels.address = result.address;
        this.center = {
          lat: result.hotelLatitude,
          lng: result.hotelLongitude,
        };
        this.hotelLatitude = this.hotels.hotelLatitude;
        this.hotelLongitude = this.hotels.hotelLongitude;
        this.addMarker(this.center.lat, this.center.lng);
      });
    }
  }

  addMarker(lat, lng) {
    this.markers = [];
    this.markers.push({
      position: {
        lat: lat,
        lng: lng,
      },

      title: 'Marker title ' + (this.markers.length + 1),
      info: 'Marker info ' + (this.markers.length + 1),
      options: {
        icon: '../../assets/custom/images/marker_preview.png',
      },
    });
  }

  public openInfo(marker: MapMarker, content) {
    this.infoContent = content;
    this.infoWindow.open(marker);
  }
  click(event: google.maps.MapMouseEvent) {
    console.log();
  }
}
