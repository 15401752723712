import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-billing-help-component',
  templateUrl: './billing-help.component.html',
  styleUrls: ['./billing-help.component.scss'],
})
export class BillingHelpComponent {
  constructor
    (public translateService: TranslateService) {
  }
}
