import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewMobileGalleryRoutingModule } from './preview-mobile-gallery-routing.module';
import { PreviewMobileGalleryComponent } from './preview-mobile-gallery.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [PreviewMobileGalleryComponent],
  imports: [
    CommonModule,
    PreviewMobileGalleryRoutingModule,
    TranslateModule,
    NgImageSliderModule,
    SharedModule
  ],
  exports:[PreviewMobileGalleryComponent]
})
export class PreviewMobileGalleryModule { }
