<mat-dialog-content>
    <div style="padding:40px;font-size: 18px;">
        <p>
            Lorem ipsum dolor sit amet. In ipsum repellat qui cupiditate necessitatibus hic assumenda eligendi qui
            deleniti odio ut magni quos et laboriosam quisquam. Ab possimus placeat ut sint commodi rem dolores nisi 33
            distinctio nisi et nihil temporibus. Qui assumenda consequatur id architecto atque qui velit corporis qui
            dolor optio ut fugiat dolor sed harum ratione.Lorem ipsum dolor sit amet. In ipsum repellat qui cupiditate necessitatibus hic assumenda eligendi qui
            deleniti odio ut magni quos et laboriosam quisquam. Ab possimus placeat ut sint commodi rem dolores nisi 33
            distinctio nisi et nihil temporibus. Qui assumenda consequatur id architecto atque qui velit corporis qui
            dolor optio ut fugiat dolor sed harum ratione.
        </p>
        <p>
            At cupiditate iure ex odit fugiat et soluta iure est deserunt molestiae. Ut galisum architecto sed mollitia
            quia et vero animi sit necessitatibus iusto ut architecto dolore. A officia earum ea omnis animi est ducimus
            quibusdam est aliquam similique? Aut alias tenetur aut eius numquam eos voluptatem molestias nam blanditiis
            enim et itaque optio.Lorem ipsum dolor sit amet. In ipsum repellat qui cupiditate necessitatibus hic assumenda eligendi qui
            deleniti odio ut magni quos et laboriosam quisquam. Ab possimus placeat ut sint commodi rem dolores nisi 33
            distinctio nisi et nihil temporibus. Qui assumenda consequatur id architecto atque qui velit corporis qui
            dolor optio ut fugiat dolor sed harum ratione.
        </p>
        <div>
            <iframe src="https://fast.wistia.net/embed/iframe/0lxd3wsk41" style="width: 100%; height: 500px" allowfullscreen="allowfullscreen"></iframe>
        </div>
        <p>
            Eos facilis sint ad quidem harum est laudantium dolore sit sunt consequuntur eos quisquam debitis. Ut
            praesentium recusandae sit quia maxime rem odit commodi non maiores repellendus aut sint facere qui rerum
            beatae. Et dolores accusantium et expedita iusto in quos blanditiis At dicta accusamus est omnis adipisci ad
            repellat eius.Lorem ipsum dolor sit amet. In ipsum repellat qui cupiditate necessitatibus hic assumenda eligendi qui
            deleniti odio ut magni quos et laboriosam quisquam. Ab possimus placeat ut sint commodi rem dolores nisi 33
            distinctio nisi et nihil temporibus. Qui assumenda consequatur id architecto atque qui velit corporis qui
            dolor optio ut fugiat dolor sed harum ratione.

        </p>
    </div>
</mat-dialog-content>