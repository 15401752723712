import { NgModule } from "@angular/core";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { SharedModule } from "../shared.module";
import { SubmitPanelComponent } from "./submit-panel/submit-panel.component";
import { TitleContentComponent } from "./title-content/title-content.component";
import { TranslateModule } from "@ngx-translate/core";
import { SupremeMenuComponent } from './supreme-menu/supreme-menu.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    FileUploadComponent, 
    BreadcrumbComponent, 
    SubmitPanelComponent, 
    TitleContentComponent, 
    SupremeMenuComponent, 
    LoaderComponent
  ],
  imports: [
    SharedModule, 
    TranslateModule,
  ],
  exports: [
    FileUploadComponent, 
    BreadcrumbComponent, 
    SubmitPanelComponent, 
    TitleContentComponent, 
    SupremeMenuComponent, 
    LoaderComponent
  ],
})
export class ComponentsModule { }
