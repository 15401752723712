import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewMobileCheckInOutRoutingModule } from './preview-mobile-check-in-out-routing.module';
import { PreviewMobileCheckInOutComponent } from './preview-mobile-check-in-out.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PreviewMobileCheckInOutComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PreviewMobileCheckInOutRoutingModule
  ],
  exports:[PreviewMobileCheckInOutComponent]
})
export class PreviewMobileCheckInOutModule { }
