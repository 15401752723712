<div>
  <div class="materialTableHeader" (click)="toggleCollapse()">
    <mat-icon>{{ isCollapsed ? 'chevron_right' : 'expand_less' }}</mat-icon>
    <div class="left">
      <ul class="header-buttons-left ms-0">
        <li>
          <div *ngIf="isUpdateGalleryCaption">
            <div>Gallery Name:</div>
            <div>
              <input
                class="form-control"
                [(ngModel)]="hotelCategory.title"
                (ngModelChange)="updateInputs()"
                (click)="$event.stopPropagation()"
                #titleInput="ngModel"
                maxlength="50" />
            </div>
          </div>
          <div *ngIf="!isUpdateGalleryCaption" class="update-hotelGallery-caption">
            Gallery Name: {{ this.hotelCategory.title }}
          </div>
        </li>
        <li>
          <div
            class="demo-google-material-icon"
            (click)="changeModeEditGalleryCaption(true); $event.stopPropagation()"
            *ngIf="!isUpdateGalleryCaption">
            <i class="material-icons">edit</i>
          </div>
          <div
            class="demo-google-material-icon"
            (click)="changeModeEditGalleryCaption(false); $event.stopPropagation()"
            *ngIf="isUpdateGalleryCaption">
            <i class="material-icons">done</i>
          </div>
        </li>
        <div *ngIf="showValidationMessage" class="error-gallery-name">*** Gallery Name is required.</div>
      </ul>
    </div>
    <div class="right">
      <ul class="tbl-export-btn">
        <li class="tbl-header-btn">
          <div class="m-l-10" cdkDragHandle>
            <mat-icon class="drag-indicator">drag_indicator</mat-icon>
          </div>
          <div class="m-l-10">
            <mat-icon class="remove-hotelGallery" (click)="onDelete()">delete_outline</mat-icon>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div
    class="hotelGallery-container"
    [@slideToggle]="isCollapsed ? 'collapsed' : 'expanded'"
    (mousedown)="$event.stopPropagation()">
    <div class="hotelGallery-body">
      <div class="row">
        <div class="col-6">
          <mat-form-field class="mystay-full-width form-group" appearance="outline">
            <mat-label>Tags</mat-label>
            <mat-chip-grid #chipGrid aria-label="Fruit selection">
              <mat-chip-row *ngFor="let tag of tags" (removed)="removeTag(tag)">
                <div class="tag_container">
                  <span class="chip-content">{{ tag }}</span>
                  <button matChipRemove [attr.aria-label]="'remove ' + tag">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
              </mat-chip-row>
            </mat-chip-grid>
            <input
              class="form-control"
              placeholder="Tags"
              #tagsInput
              [formControl]="tagCtrl"
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addTag($event)" />
            <div *ngIf="tagError" class="error-message">Tags must be 50 characters or less.</div>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="mystay-full-width form-group" appearance="outline">
            <mat-label>Open Hours</mat-label>
            <input class="form-control" matInput [(ngModel)]="this.hotelCategory.openHours" maxlength="50" />
          </mat-form-field>
        </div>
        <!-- Gallery caption -->
        <div class="col-12">
          <mat-form-field class="mystay-full-width form-group" appearance="outline">
            <mat-label>Gallery caption</mat-label>
            <textarea
              matInput
              class="form-control"
              rows="4"
              [(ngModel)]="hotelCategory.description"
              [formControl]="galleryCaptionControl"
              (input)="updateGalleryDescription($event)"></textarea>
            <div
              *ngIf="galleryCaptionControl.invalid && (galleryCaptionControl.dirty || galleryCaptionControl.touched)"
              class="error-message">
              <div *ngIf="galleryCaptionControl.hasError('maxlength')" style="color: red">
                Gallery caption cannot be more than 200 characters.
              </div>
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- Gallery Upload area -->
    <div class="gallery-upload-container">
      <!-- <div class="card"> -->
        <div class="title">
          <div class="col-12">
            <div class="header">
              <h5>Images</h5>
            </div>
          </div>
        </div>
        <div class="images-container">
          <div
            class="file-upload-area"
            (dragover)="onDragOver($event)"
            (drop)="onDrop($event)"
            (dragleave)="onDragLeave($event)"
            (dragenter)="onDragEnter($event)"
            [class.drag-hover]="isDragOver">
            <input type="file" #fileInput hidden (change)="onFileSelected($event)" multiple accept="image/*" />
            <div *ngIf="galleryImages.length === 0" class="upload-more" (click)="fileInput.click()">
              <mat-icon>add_photo_alternate</mat-icon>
              <span>Add Images</span>
            </div>
            <div *ngFor="let image of galleryImages" class="fileContainer">
              <div class="imageContainer">
                <img [src]="image.imageUrl" alt="Document" class="document-image" />
                <div class="overlay">
                  <button class="delete-button" (click)="requestDeleteImage(image.blobId)">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <div *ngIf="image.editing; else viewCaption" class="fileName">
                <input [(ngModel)]="image.caption" type="text" />
                <div class="edit-save-icon-container">
                  <mat-icon class="save-icon" (click)="saveCaption(image)">save</mat-icon>
                </div>
              </div>
              <ng-template #viewCaption>
                <div class="fileName" matTooltip="{{ image.caption }}">
                  <span class="caption">{{ image.caption }}</span>
                  <div class="edit-save-icon-container">
                    <mat-icon class="edit-icon" (click)="editCaption(image)">edit</mat-icon>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="upload-more" (click)="fileInput.click()" *ngIf="galleryImages.length > 0">
              <mat-icon>add_photo_alternate</mat-icon>
              <span>Add images</span>
            </div>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </div>
</div>
