<div id="activity-area" class="settingSidebar" [ngClass]="isOpenSidebar ? 'showSettingPanel' : ''">
  <div class="settingSidebar-body ps-container ps-theme-default">
    <div class="fade show active">
      <div class="setting-panel-header">
        <h3>{{ 'Quick Search' | translate }}</h3>
        {{ 'Search customer by name or phone' | translate }}
        <button mat-icon-button class="close-right-menu-button" (click)="closeSidebar()" aria-label="Close  Siderbar">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div style="padding: 10px; overflow: auto">
        <div class="form-group has-search">
          <input #search
            type="text"
            style="padding-left: 10px"
            class="form-control"
            [(ngModel)]="searchFilter"
            placeholder="{{ 'search' | translate }}"
            (keyup)="searchActivities()" />
          <span style="font-size: 8px; margin-right: 10px" class="fa fa-search form-control-feedback"></span>
        </div>
        <div style="height: calc(100vh - 170px)">
          <ng-scrollbar>
            <div style="width:100%;text-align: center;margin-top:20px" *ngIf="isLoading">
              <img src="../../../assets/images/loading/loading-spinner.gif" style="width:90px;height:auto;">
            </div>
            <div *ngFor="let customer of this.customerResultList">
                <div style="margin-bottom: 5px;cursor: pointer;margin-top:5px" (click)="navigateToReservationDetail(customer)">
                  <div class="searchbar-tag-container">
                    <strong>{{customer.displayName}}</strong> 
                    <div *ngIf="customer.reservationStatus == ReservationStatusType.BOOKEND"  class="booked">
                      {{ 'booked' | translate }}
                    </div>
                    <div *ngIf="customer.reservationStatus == ReservationStatusType.DUEIN"   class="due-in">
                      {{ 'dueIn' | translate }}
                    </div>
                    <div *ngIf="customer.reservationStatus == ReservationStatusType.CHECKEDIN" class="checked-in">
                      {{ 'checkedIn' | translate }}
                    </div>
                    <div *ngIf="customer.reservationStatus == ReservationStatusType.DUEOUT"  class="due-out">
                      {{ 'dueOut' | translate }}
                    </div>
                    <div *ngIf="customer.reservationStatus == ReservationStatusType.CHECKEDOUT"  class="checked-out">
                      {{ 'checkedOut' | translate }}
                    </div>
                    <div *ngIf="customer.reservationStatus == ReservationStatusType.NOSHOW" class="no-show">
                      {{ 'noShow' | translate }}
                    </div>
                  </div>
                  <div class="searchbar-info-container">
                    <div>
                      <mat-icon style="transform: scale(0.62);color:grey;margin-bottom: -7px;margin-right:-5px">phone</mat-icon><span style="color:grey">{{customer.phoneNumber}}</span><br>
                      <mat-icon style="transform: scale(0.62);color:grey;margin-bottom: -7px;">work</mat-icon><span *ngIf="customer.lastArrivalDate" style="color:grey">{{customer.lastArrivalDate | date : 'yyyy-MM-dd' }} -> {{customer.lastArrivalDate | date : 'yyyy-MM-dd' }}</span>
                    </div>
                    <div style="margin-top:22px">
                      <div>
                          <mat-icon>navigate_next</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-divider></mat-divider>
            </div>
          </ng-scrollbar>
        </div>
      </div>
    </div>
  </div>
  <canvas #myCanvas width="400" height="400"></canvas>
</div>
