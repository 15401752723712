<div class="breadcrumb-main">
  <div class="row">
    <div class="col-6">
      <div class="breadcrumb-title">
        <h4 style="display: inline-block" class="page-title">{{ title | translate }}</h4>
        <a >
          <i
            class="far fa-question-circle"
            style="font-size: 26px; margin-left: 7px; opacity: 0.6; cursor: pointer"></i>
        </a>
      </div>
    </div>
    <div class="col-6" *ngIf="showBreadcrumbs">
      <ul class="breadcrumb-list">
        <li
          *ngFor="let breadcrumb of breadcrumbs; let last = last; let index = index"
          class="breadcrumb-item"
          [ngClass]="{ active: last }">
          <a *ngIf="!last" [routerLink]="breadcrumb.url">
            <span *ngIf="index === 0" class="fas fa-home"></span>
            <span *ngIf="index !== 0">{{ breadcrumb.label | translate }}</span>
          </a>
          <span *ngIf="last">
            <span *ngIf="index === 0" class="fas fa-home"></span>
            <span *ngIf="index !== 0">{{ breadcrumb.label | translate }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
