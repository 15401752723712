import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectAllOptionComponent } from "./select-all-option.component";
import { MaterialModule } from "@shared/material.module";

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [SelectAllOptionComponent],
  declarations: [SelectAllOptionComponent],
})
export class SelectAllOptionModule {}
