import {  Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ThemeSettingsService } from '@core/services/theme-settings.service'
import { IBrandIdentity, IWidgetCaption } from '@core/interfaces'
import { Subject, Subscription, firstValueFrom } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-widget-captions',
  templateUrl: './widget-captions.component.html',
  styleUrls: ['./widget-captions.component.scss']
})
export class WidgetCaptionsComponent implements OnInit {
  subscription: Subscription
  subscriptionId: string
  constructor (
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate : TranslateService
  ) {}
  
  public subscribeBrandIdentityEvent: Subject<IBrandIdentity> = new Subject()
  public subscribeWidgetEvent: Subject<IWidgetCaption> = new Subject()

  public widgetCaption: IWidgetCaption = {
    id:'',
    breakfast: '',
    gallery: '',
    chat: '',
    call: '',
    wifi:''
  }

  isUpload : boolean = false

  widgetCaptionForm = new FormGroup({
    breakfast: new FormControl(this.widgetCaption.breakfast, {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(255)
      ]
    }),
    gallery: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(255)
      ]
    }),
    chat: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(255)
      ]
    }),
    call: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(255)
      ]
    }),
    wifi: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(255)
      ]
    })
  })

  themeColor: string = 'light'
  widgetColor: string = 'light'
  hotelNameDisplay: string
  brandIdentity: IBrandIdentity = {
    hotelName: '',
    location: '',
    phoneNumber: '',
    brandColor: '',
    logoFile: undefined,
    logoURL: '',
    backgroundFile: undefined,
    backgroundURL: '',
    themeColor: this.themeColor
  }

  themeSettings: Array<any> = [
    {
      fullName: 'Dark theme',
      shortName: 'dark'
    },
    {
      fullName: 'Light theme',
      shortName: 'light'
    }
  ]

  bookingThemeColor: Array<any> = this.themeSettings
  widgetThemeColor: Array<any> = this.themeSettings

  ngOnInit () {
    this.route.paramMap.subscribe(paramMap => {
      this.subscriptionId = paramMap.get('subscriptionId')
      firstValueFrom(
        this.themeSettingsService.getThemeSettings(this.subscriptionId)
      ).then((response: any) => {
        this.brandIdentity.hotelName = response.name
        this.brandIdentity.location = response.contact.location.locationName
        this.brandIdentity.phoneNumber = response.contact.phoneNumber
        this.brandIdentity.brandColor = response.theme.primaryColor
        this.brandIdentity.themeColor = response.theme.textColor
        this.brandIdentity.logoURL = response.media.logoUrl
        this.brandIdentity.backgroundURL = response.media.backgroundUrl
        this.subscribeBrandIdentityEvent.next(this.brandIdentity)

        const widgetCaption : IWidgetCaption = response.widgetCaption;
        this.widgetCaptionForm.controls.breakfast.patchValue(
          `${widgetCaption.breakfast}`
        )
        this.widgetCaptionForm.controls.gallery.patchValue(
          `${widgetCaption.gallery}`
        )
        this.widgetCaptionForm.controls.chat.patchValue(
          `${widgetCaption.chat}`
        )
        this.widgetCaptionForm.controls.call.patchValue(
          `${widgetCaption.call}`
        )
        this.widgetCaptionForm.controls.wifi.patchValue(
          `${widgetCaption.wifi}`
        )
        this.widgetCaption.breakfast = widgetCaption.breakfast;
        this.widgetCaption.gallery = widgetCaption.gallery;
        this.widgetCaption.chat = widgetCaption.chat;
        this.widgetCaption.call = widgetCaption.call;
        this.widgetCaption.wifi = widgetCaption.wifi;
        this.widgetCaption.widgetColor = response.theme.textColor;
        this.subscribeWidgetEvent.next(this.widgetCaption)
      })
    })
  }

  async onSubmit () {
    if (
      this.widgetCaptionForm.controls.breakfast.value !== '' &&
      this.widgetCaptionForm.controls.gallery.value !== '' &&
      this.widgetCaptionForm.controls.chat.value !== '' &&
      this.widgetCaptionForm.controls.call.value !== ''
    ) {
      this.isUpload = true;
      this.route.paramMap.subscribe(paramMap => {
        this.subscriptionId = paramMap.get('subscriptionId')
        this.widgetCaption = {
          id :  this.subscriptionId,
          breakfast: this.widgetCaptionForm.controls.breakfast.value,
          gallery: this.widgetCaptionForm.controls.gallery.value,
          chat: this.widgetCaptionForm.controls.chat.value,
          call: this.widgetCaptionForm.controls.call.value,
          wifi:this.widgetCaptionForm.controls.wifi.value,
        }

        firstValueFrom(
          this.themeSettingsService.updateWidgetCaptions(this.widgetCaption)
        )
          .then(() => {
            const successMessage = this.translate.instant('widgetCaptionsHaveBeenSaved'); 
            // this.snackbarService.alertSuccess(successMessage);
            this.toastr.success(successMessage, 'Operation Completed', {timeOut : 3500});
            this.isUpload = false;
          })
          .catch(() => {
            const failedMessage = this.translate.instant('failedToSave'); 
            // this.snackbarService.alertError(failedMessage);
            this.toastr.error(failedMessage, 'Operation Failed', {timeOut : 3500});
            this.isUpload = false;
          })
          
          this.isUpload = false;
      })
    }else{
      const failedMessage = this.translate.instant('failedToSave'); 
      // this.snackbarService.alertError(failedMessage);
      this.toastr.error(failedMessage, 'Operation Failed', {timeOut : 3500});
      this.isUpload = false;
    }
  }

  onChangeBreakfastEvent (event: any) {
    this.widgetCaption.breakfast = event.target.value
    this.subscribeWidgetEvent.next(this.widgetCaption)
  }
  onChangeGalleryEvent (event: any) {
    this.widgetCaption.gallery = event.target.value
    this.subscribeWidgetEvent.next(this.widgetCaption)
  }
  onChangeChatEvent (event: any) {
    this.widgetCaption.chat = event.target.value
    this.subscribeWidgetEvent.next(this.widgetCaption)
  }
  onChangeCallEvent (event: any) {
    this.widgetCaption.call = event.target.value
    this.subscribeWidgetEvent.next(this.widgetCaption)
  }
  onChangeWifiEvent (event: any) {
    this.widgetCaption.wifi = event.target.value
    this.subscribeWidgetEvent.next(this.widgetCaption)
  }
}
