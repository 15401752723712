import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-content',
  templateUrl: './title-content.component.html',
  styleUrls: ['./title-content.component.scss']
})
export class TitleContentComponent {
  @Input() title! : string;
  @Input() subTitle! : string;
}
