import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './modules/errors/page404/page404.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { RoleGuard } from '@core/guards/role.guard';
import { UserRoles } from '@core/utils/user-roles.enum';
import { ReportPdfComponent } from './modules/billing/reports/report-pdf/report-pdf.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: ':subscriptionId/dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { breadcrumb: 'Dashboard', level: 1 },
      },
      {
        path: ':subscriptionId/stickynotes',
        loadChildren: () => import('./modules/stickynotes/stickynotes.module').then(m => m.StickynotesModule),
        data: { breadcrumb: 'Sticky Notes', level: 1 },
      },
      {
        path: ':subscriptionId/reports/statistics',
        loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Statistics',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER],
        },
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
        data: { breadcrumb: 'Profile', level: 1 },
      },
      {
        path: ':subscriptionId/billing',
        loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Billing',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER],
        },
      },
      {
        path: ':subscriptionId/chat',
        loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Chat',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/theme-settings',
        loadChildren: () => import('./modules/theme-settings/theme-settings.module').then(m => m.ThemeSettingsModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Theme Settings',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER],
        },
      },
      {
        path: ':subscriptionId/transport',
        loadChildren: () => import('./modules/transport/transportations.module').then(m => m.transportationsModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Transport',
          level: 1,
          roles: [
            UserRoles.SUPER_ADMIN,
            UserRoles.OWNER,
            UserRoles.MANAGER,
            UserRoles.RECEPTION,
            UserRoles.TRANSPORTER,
          ],
        },
      },
      {
        path: ':subscriptionId/settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Settings',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER],
        },
      },
      {
        path: ':subscriptionId/reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [RoleGuard],
        data: { breadcrumb: 'Reports', level: 1, roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER] },
      },
      {
        path: ':subscriptionId/rooms',
        loadChildren: () => import('./modules/rooms/rooms.module').then(m => m.RoomsModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Rooms',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER],
        },
      },
      {
        path: ':subscriptionId/housekeeping',
        loadChildren: () => import('./modules/housekeeping/housekeeping.module').then(m => m.HousekeepingModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Housekeeping',
          level: 1,
          roles: [
            UserRoles.SUPER_ADMIN,
            UserRoles.OWNER,
            UserRoles.MANAGER,
            UserRoles.RECEPTION,
            UserRoles.HOUSE_KEEPING,
          ],
        },
      },
      {
        path: ':subscriptionId/calendar',
        loadChildren: () => import('./modules/calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Calendar',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/inventory',
        loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Inventory',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Users',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER],
        },
      },
      {
        path: ':subscriptionId/reservations',
        loadChildren: () => import('./modules/reservations/reservations.module').then(m => m.ReservationsModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Reservations',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/guests',
        loadChildren: () => import('./modules/guests/guests.module').then(m => m.GuestsModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Guests',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/customers',
        loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Customers',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/schedule-reservations',
        loadChildren: () =>
          import('./modules/schedule-reservations/schedule-reservations.module').then(
            m => m.ScheduleReservationsModule
          ),
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Schedule Reservations',
          level: 1,
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER, UserRoles.RECEPTION],
        },
      },

      {
        path: ':subscriptionId/support',
        loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
        data: { breadcrumb: 'Support', level: 1 },
      },
      {
        path: 'admin/subscriptions',
        loadChildren: () => import('./modules/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
        canActivate: [RoleGuard],
        data: { breadcrumb: 'Subscriptions', level: 1, roles: [UserRoles.SUPER_ADMIN] },
      },
      {
        path: 'admin/super-admins',
        loadChildren: () => import('./modules/super-admins/super-admins.module').then(m => m.SuperAdminsModule),
        canActivate: [RoleGuard],
        data: { breadcrumb: 'Super Admins', level: 1, roles: [UserRoles.SUPER_ADMIN] },
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    data: { breadcrumb: 'Authentication', level: 1 },
  },
  {
    path: ':subscriptionId/print/billing/invoice',
    component: ReportPdfComponent,
    loadChildren: () => import('./modules/billing/reports/report-pdf/report-pdf.module').then(m => m.ReportPdfModule),
    data: { breadcrumb: 'Invoice', level: 1 },
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
