import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ThemeSettingsService } from '@core/services/theme-settings.service';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ITransport } from '@core/models/transports/transportations.model';

@Component({
  selector: 'app-hotel-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss'],
})
export class TransportComponent implements OnInit {
  subscription: Subscription;
  public subscribeTransportEvent: Subject<ITransport> = new Subject();

  constructor(
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {}

  isUpload: boolean = false;
  subscriptionId: string;

  transport: ITransport = {
    transportMessage: '',
  };

  transportGroupForm = new FormGroup({
    description: new FormControl(this.transport.transportMessage, {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(160)],
    }),
  });

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.subscriptionId = paramMap.get('subscriptionId');
      firstValueFrom(this.themeSettingsService.getThemeSettings(this.subscriptionId)).then((response: any) => {
        response.contents.forEach(element => {
          if (element.key === 'transport_message') {
            this.transport.transportMessage = element.contentBody;
            this.transportGroupForm.controls.description.patchValue(`${element.contentBody}`);
            this.subscribeTransportEvent.next(this.transport);
          }
        });
      });
    });
  }

  onSubmit() {
    if (this.transportGroupForm.controls.description.value !== '') {
      this.isUpload = true;
      this.route.paramMap.subscribe(paramMap => {
        this.subscriptionId = paramMap.get('subscriptionId');
        const transportSubmit: ITransport = {
          id: this.subscriptionId,
          transportMessage: this.transportGroupForm.controls.description.value,
        };
        firstValueFrom(this.themeSettingsService.updateHotelTransportation(transportSubmit))
          .then(() => {
            const editMessage = this.translate.instant('transportMessageHasBeenSaved');
            this.toastr.success(editMessage, 'Operation Completed', { timeOut: 3500 });
            this.isUpload = false;
          })
          .catch(() => {
            const failedMessage = this.translate.instant('failedToSave');
            this.toastr.success(failedMessage, 'Operation Completed', { timeOut: 3500 });
            this.isUpload = false;
          });
      });
    }
  }

  handleWelcomeMessageChange(transport: any) {
    this.transport.transportMessage = transport.value.description;
    this.subscribeTransportEvent.next(this.transport);
  }
}
