import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customers-help-component',
  templateUrl: './customers-help.component.html',
  styleUrls: ['./customers-help.component.scss'],
})
export class CustomersHelpComponent {
  constructor
    (public translateService: TranslateService) {
  }
}
