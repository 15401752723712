import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QRCodeComponent {
  qrCodeImageUrl: SafeUrl | null = null;
  qrCodeTitle: string = null;
  qrCodeImage: string = null;
  guestFullName: string = null;
  roomNumber: string = null;
  todayDate: string = null;
  bookingNumber: number = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer, private datePipe: DatePipe) {
    this.qrCodeImage = data.qrCodeImage;
    this.qrCodeImageUrl = this.sanitizer.bypassSecurityTrustUrl(data.qrCodeImage);
    this.qrCodeTitle = data.qrCodeTitle;

    if (data.qrCodeDetail) {
      this.guestFullName = data.qrCodeDetail.guestFullName || '';
      this.roomNumber = data.qrCodeDetail.roomNumber || '';
      this.todayDate = this.datePipe.transform(data.qrCodeDetail.todayDate, 'yyyy-MM-dd') || '';
      this.bookingNumber = data.qrCodeDetail.bookingNumber || null;
    } else {
      this.guestFullName = '';
      this.roomNumber = '';
      this.todayDate = '';
      this.bookingNumber = null;
    }
  }

  printQRCode(
    roomNumber: string,
    guestFullName: string,
    bookingNumber: number,
    qrCodeImage: string,
    todayDate: string
  ) {
    // Ensure that the variables are not undefined before proceeding
    if (!roomNumber || !guestFullName || !bookingNumber || !qrCodeImage || !todayDate) {
      console.error('Some data is missing for printing.');
      return;
    }

    // Create a new window for printing
    const printWindow = window.open('', '_blank', 'width=600,height=400');

    // Make sure the window has been opened
    if (printWindow) {
      // Add content to the new window, using the normal image URL in document.write
      printWindow.document.write(`
        <html>
        <head>
          <title>#${bookingNumber}</title>
          <style>
            /* Optional styles for the print page */
            body { font-family: Arial, sans-serif; text-align: center; }
            .print-content { margin: 20px; }
            img { width: 450px; height: auto; margin-top: 20px; }
            .booking-number {font-size : 40px;}
            .guest-name {font-size : 24px;  margin-top: 20px;}
            .date {font-size : 24px;}
          </style>
        </head>
        <body>
          <div class="print-content">
            <div class="booking-number" >#${bookingNumber}</div>
            <div class="guest-name">${guestFullName} (Room ${roomNumber})</div> <!-- Inserted custom text here -->
            <img src="${qrCodeImage}" alt="QR Code Image"> <!-- Use normal image URL here -->
            <div  class="date"> ${todayDate}</div>
          </div>
        </body>
        </html>
      `);

      // Automatically trigger the print preview
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();

      // Optional: close the print window after printing
      printWindow.onafterprint = function () {
        printWindow.close();
      };
    } else {
      console.error('Failed to open print window.');
    }
  }
}
