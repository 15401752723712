import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ThemeSettingsService } from '@core/services/theme-settings.service';
import { ICheckInOut, IThemeSettings } from '@core/interfaces';
import { decode } from 'html-entities';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { QRCodeComponent } from 'app/modules/reservations/details/dialogs/qrcode/qrcode.component';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss'],
})
export class CheckOutComponent extends UnsubscribeOnDestroyAdapter  implements OnInit {
  subscription: Subscription;
  public subscribeCheckOutEvent: Subject<ICheckInOut> = new Subject();
  subscriptionId: string;
  constructor(
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate : TranslateService,
    public dialog: MatDialog,
  ) {
    super()
  }
  formSubmitted: boolean = false;
  public file_store: FileList;
  public editor = ClassicEditor;
  public headerImage: string = '';
  content: any = ``;
  checkOutProps: ICheckInOut = {
    checkOutMessages: '',
    checkInMessages: null,
    backgroundUrl : ''
  };
  isUpload: boolean = false;
  folderBackground: string = 'settingsImage/checkOut';
  headerImageUrl = this.checkOutProps.backgroundUrl.split('/')[1];
  backgroundPreviewUrl : string = "";

  checkOutGroupForm = new FormGroup({
    completeMessage: new FormControl(this.checkOutProps.checkOutMessages, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    backgroundUploaded: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, this.fileTypeValidator(['image/jpeg', 'image/png'])],
    }),
    headerImageName: new FormControl(this.headerImageUrl, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  Editor = ClassicEditor;
  ckeditorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link'],
    table: {
      contentToolbar: ['tableRow', 'tableColumn', 'mergeTableCells', 'tableCellProperties'],
    },
  };

  ngOnInit(): void {
   
    this.route.paramMap.subscribe(paramMap => {
      this.subscriptionId = paramMap.get('subscriptionId');
      firstValueFrom(this.themeSettingsService.getThemeSettings(this.subscriptionId)).then((response: IThemeSettings) => {
        this.checkOutGroupForm.controls.headerImageName.patchValue(this.getNameUrl(
          response.appBackgroundImages.checkOutBackgroundUrl.substring(response.appBackgroundImages.checkOutBackgroundUrl.lastIndexOf('/') + 1, response.appBackgroundImages.checkOutBackgroundUrl.length)
        ));
        this.backgroundPreviewUrl = response.appBackgroundImages.checkOutBackgroundUrl;
        response.contents.forEach(element => {
          if (element.key === 'check_out') {
            this.checkOutGroupForm.controls.completeMessage.patchValue(`${element.contentBody}`);
            this.checkOutProps.checkOutMessages = decode(element.contentBody);
            this.checkOutProps.backgroundUrl = response.appBackgroundImages.checkOutBackgroundUrl;
            this.subscribeCheckOutEvent.next(this.checkOutProps);
          }
        });
      });
    });
  }

  getNameUrl (fullUrl : string) {
    const url = fullUrl;
    const urlLength = url.lastIndexOf('?')
    const result = url.slice(0, urlLength)
    return result;
  }

  fileTypeValidator (allowedMimeTypes: string[]) {
    return (control: FormControl): { [key: string]: any } | null => {
      const file = control.value;
      if (file) {
        const isValidType = allowedMimeTypes.includes(file.type);
        return isValidType ? null : { invalidFileType: true };
      }
      return null;
    };
  }

  handleFileInputBackgroundChange (event: any, l: FileList): void {
    this.isUpload = true;
    this.file_store = l;
    if (l.length) {
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.checkOutGroupForm.controls.headerImageName.patchValue(`${l[0].name}${count}`);
      if (this.checkOutGroupForm.controls['backgroundUploaded']?.hasError('invalidFileType')) {
        if (event.target.files && event.target.files[0]) {
          const reader = new FileReader();
          this.route.paramMap.subscribe(paramMap => {
            this.subscriptionId = paramMap.get('subscriptionId');
            const formData: any = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('folder', this.folderBackground);
            formData.append('Restrict', 'false');
            formData.append('SubscriptionId', this.subscriptionId);

            const upload$ = this.themeSettingsService.uploadFileToCloudStorage(formData);
            this.subscription = upload$.subscribe(response => {
              this.checkOutProps.backgroundUrl = response.imageUrl;
              this.checkOutGroupForm.controls.headerImageName.patchValue(
                this.getNameUrl(response.imageUrl.substring(response.imageUrl.lastIndexOf('/') + 1, response.imageUrl.length))
              );
              this.isUpload = false;
              const mapping = {
                subscriptionId: this.subscriptionId,
                checkOutBackgroundUrl: response.imageUrl,
                appBackground: 2,
              };
              const upload$1 = this.themeSettingsService.updateBackgroundImage(mapping);
              upload$1.subscribe(res => {
                res;
              });
            });
            this.isUpload = false;
          });

          reader.readAsDataURL(event.target.files[0]); // read file as data url
          let url: any;
          reader.onload = event => {
            url = event.target.result;
            this.checkOutProps.backgroundUrl = url;
            this.subscribeCheckOutEvent.next(this.checkOutProps);
          };
        }
      }
    } else {
      this.checkOutGroupForm.controls.headerImageName.patchValue('');
      this.isUpload = false;
    }
  }

  handleCompleteMessageChange(checkout: any) {
    this.checkOutProps.backgroundUrl = this.backgroundPreviewUrl;
    this.checkOutProps.checkOutMessages = checkout.value.completeMessage;
    this.subscribeCheckOutEvent.next(this.checkOutProps);
  }

  async onSubmit() {
    this.formSubmitted = true;
    this.isUpload = true;

    const paramMap = await firstValueFrom(this.route.paramMap);
    this.subscriptionId = paramMap.get('subscriptionId');
    this.checkOutProps = {
      id: this.subscriptionId,
      checkOutMessages: this.checkOutGroupForm.controls.completeMessage.value,
    };

    firstValueFrom(this.themeSettingsService.updateCheckOut(this.checkOutProps))
      .then(() => {
        const successMessage = this.translate.instant('checkOutMessageHasBeenSaved'); 
        // this.snackbarService.alertSuccess(successMessage);
        this.toastr.success(successMessage, 'Operation Completed', {timeOut : 3500});
        this.isUpload = false;
      })
      .catch(() => {
        const failedMessage = this.translate.instant('failedToSave'); 
        this.toastr.error(failedMessage, 'Operation Failed', {timeOut : 3500});
        // this.snackbarService.alertError(failedMessage);
        this.isUpload = false;
      });
  }

  openQRCodeDialog(){
    this.themeSettingsService.getQRCodeCheckout(this.subscriptionId).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64data = reader.result;
        const dialogRef = this.dialog.open(QRCodeComponent, {
          data: {
            qrCodeImage: base64data,
            qrCodeTitle : "QR-Code for preview"
          },
        });

        this.subs.sink = dialogRef.afterClosed().subscribe(res => {});
      };
    });
  }

  goToGooglePlayStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
  goToAppStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
}
