import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { InConfiguration, DirectionService, RightSidebarService, AuthService } from '@core';
import { ConfigService } from '@core/services/config.service';
import { ActivityLog } from '@core/models/activityLog/activity-log.model';
import { ActivityLogService } from '@core/services/activity-log.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { SignalRService } from '@core/services/signalr.service';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
})
export class RightSidebarComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ViewChild('myCanvas')
  private myCanvas: ElementRef = {} as ElementRef;

  selectedBgColor = 'white';
  maxHeight!: string;
  maxWidth!: string;
  showpanel = false;
  isOpenSidebar!: boolean;
  public innerHeight?: number;
  headerHeight = 60;
  public config!: InConfiguration;
  activityLogList: Array<ActivityLog> = [];
  activityLogListDisplay: Array<ActivityLog> = [];
  searchFilter: string = '';
  private signalRSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private activityLogService: ActivityLogService,
    private router: Router,
    private signalRService: SignalRService
  ) {
    super();
  }
  ngOnInit() {
    this.config = this.configService.configData;
    this.isOpenSidebar = true;
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(isRunning => {
      this.isOpenSidebar = isRunning;
      if (this.isOpenSidebar) {
        this.initializeSidebar();
      } else {
        this.cleanupSidebar();
      }
    });

    this.subs.sink = this.authService.currentSubscription.subscribe(subscription => {
      if (!subscription.id) {
        this.isOpenSidebar = false;
        this.cleanupSidebar();
      }
    });

    this.setRightSidebarWindowHeight();
  }

  initializeSidebar() {
    firstValueFrom(this.activityLogService.getActivityLogList(this.authService.currentSubscriptionValue.id)).then(
      activities => {
        this.draw();
        this.activityLogList = <Array<ActivityLog>>activities;
        this.activityLogListDisplay = this.activityLogList;
      }
    );

    this.signalRService.connect();
    this.signalRSubscription = this.signalRService.activityLogUpdated.subscribe(() => {
      this.fetchActivityLogs();
    });
  }

  cleanupSidebar() {
    if (this.signalRSubscription) {
      this.signalRSubscription.unsubscribe();
    }
    this.signalRService.disconnect();
  }

  fetchActivityLogs() {
    const subscriptionId = this.authService.currentSubscriptionValue.id;
    if (subscriptionId) {
      this.activityLogService.getActivityLogList(subscriptionId).subscribe(
        activities => {
          this.activityLogList = <Array<ActivityLog>>activities;
          this.activityLogListDisplay = this.activityLogList;
        },
        error => {
          console.error('Error fetching activity logs', error);
        }
      );
    }
  }

  prepareActivityText(text: string, fieldValue1: string) {
    if (!fieldValue1) {
      return { before: text, id: '', after: '' };
    }

    const parts = text.split(fieldValue1);
    return {
      before: parts[0],
      id: fieldValue1,
      after: parts[1] ? parts[1] : '',
    };
  }

  navigateToReservationDetail(subscriptionId: string, reservationRef: string): void {
    if (subscriptionId && reservationRef) {
      this.router.navigate([`/${subscriptionId}/reservations/${reservationRef}`]);
    }
  }

  draw() {
    const ctx = this.myCanvas.nativeElement.getContext('2d');
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'rgba(100, 100, 100, 0.5)';
    ctx.beginPath();
    ctx.moveTo(25, 25);
    ctx.lineTo(105, 25);
    ctx.lineTo(25, 105);
    ctx.fill();
  }

  setRightSidebarWindowHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.maxHeight = height + '';
    this.maxWidth = '500px';
  }

  getDayForRow(dateIn: Date) {
    dateIn = new Date(dateIn);
    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (today.toDateString() == dateIn.toDateString()) {
      return 'Today';
    } else if (yesterday.toDateString() == dateIn.toDateString()) {
      return 'Yesterday';
    } else {
      return '';
    }
  }

  closeSidebar() {
    this.isOpenSidebar = false;
    this.searchFilter = '';
    this.activityLogListDisplay = this.activityLogList;
    this.cleanupSidebar();
  }

  searchActivities() {
    this.activityLogListDisplay = this.activityLogList.filter(x => x.text.includes(this.searchFilter.trim()));
  }
}
