

<div class="container-fluid mystay-preview-mobile-breakfast">
    <div class="row">
        <div class="col-12">
            <div class="header-preview">
                <h3>{{'preview' | translate}}</h3>
                <button type="button" class="mystay-button-qrcode" color="primary"
                    (click)="this.qrScanPreviewClick.next($event)">
                    <i class="fa fa-qrcode" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="col-12">
            <div class="preview-dropdown">
                <mat-form-field class="example-full-width breakfast-dropdown"
                appearance="outline">
                <mat-select placeholder="select" [(value)]="isIncluded" name="values">
                    <mat-option *ngFor="let b of breakfastList" [value]="b.value">
                        {{b.viewValue | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
        </div>
    </div>
    <div class="mystay-container-preview-mobile-breakfast">
        <div class="mystay-preview-mobile-inside-breakfast">
            <div class="breakfast-preview-mobile-header"
                [ngStyle]="{'background-image': 'url(' + breakfast.headerImageUrl + ') '} ">
                <div class="breakfast-preview-top-nav">
                    <div class="clock">{{clockOnMobile}}</div>
                    <div class="signal-mobile">
                        <span><i class="fas fa-signal"></i></span>
                        <span><i class="fas fa-wifi"></i></span>
                        <span><i class="fas fa-battery-full"></i></span>
                    </div>
                </div>
            </div>
            <div class="breakfast-preview-top-arrow">
                <div class="arrow"></div>
            </div>
            <div class="breakfast-preview-mobile-header-image">
                <div class="text">Breakfast</div>
                <div class="icon"></div>
            </div>
            <div class="breakfast-preview-mobile-body" *ngIf="isIncluded === true">
                <div class="intro">
                    <div class="title">Good Morning!</div>
                    <div class="body">QR Code for the Restaurant to have breakfast</div>
                </div>
                <div class="content">
                    <div class="qr-code">
                        <img [src]="qrCode" width="150" height="150">
                    </div>
                </div>
                <div class="announce">
                    <div class="messages">
                        <p [innerHtml]="breakfast.description "> </p>
                    </div>

                </div>
            </div>
            <div class="breakfast-preview-mobile-body-not-included" *ngIf="isIncluded === false">
                <div class="intro">
                    <div class="title">
                        <div></div>
                    </div>
                    <div class="body">You don't have breakfast included in your stay, but feel free to visit our
                        restaurant. You can always order food and pay over the counter</div>
                </div>
                <div class="announce">
                    <div class="messages">
                        <p [innerHtml]="breakfast.notification"> </p>
                    </div>

                </div>
            </div>
            <div class="mystay-preview-mobile-breakfast"> </div>
        </div>
    </div>
</div>