import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-supreme-menu',
  templateUrl: './supreme-menu.component.html',
  styleUrls: ['./supreme-menu.component.scss']
})
export class SupremeMenuComponent {
  @Input() position: { x: number, y: number } = { x: 0, y: 0 };
  @Input() actions: any[] = [];
  @Output() actionSelected = new EventEmitter<{ action: string; event: MouseEvent }>();



  onActionSelect(action: string, event: MouseEvent) {
    event.stopPropagation();
    this.actionSelected.emit({ action, event });
  }
  

}
