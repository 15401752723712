import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewMobileHotelGuideRoutingModule } from './preview-mobile-hotel-guide-routing.module';
import { PreviewMobileHotelGuideComponent, SafePipe } from './preview-mobile-hotel-guide.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MobileNavigatorBarModule } from '../mobile-navigator-bar/mobile-navigator-bar.module';

@NgModule({
  declarations: [PreviewMobileHotelGuideComponent, SafePipe],
  imports: [
    CommonModule,
    PreviewMobileHotelGuideRoutingModule,
    PdfViewerModule,
    MobileNavigatorBarModule,
    
  ],
  exports : [PreviewMobileHotelGuideComponent]
})
export class PreviewMobileHotelGuideModule { }
