import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public languages: string[] = ['en', 'sv', 'th'];

  constructor(public translate: TranslateService, public authService : AuthService) {
    this.authService = authService;
    translate.addLangs(this.languages);
    this.setLanguageByProfile();
  }

  public setLanguageByProfile() {

    let browserLang: string;
    let languageChoice = 'en';
    if(this.authService.userIdentity.language == 1) {
      languageChoice = "sv";
    } else if (this.authService.userIdentity.language == 2) {
      languageChoice = "th";
    }

    if (localStorage.getItem('lang')) {
      browserLang = localStorage.getItem('lang') as string;
    } else {
      browserLang = this.translate.getBrowserLang() as string;
    }
    this.translate.use(languageChoice ? languageChoice : 'en');
  }

  public setLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }
}
