import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightSidebarService } from './services/rightsidebar.service';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { DirectionService } from './services/direction.service';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { QuickSearchSidebarService } from '@core';
import { SidebarSearchService } from './services/sidebarSearch.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [RightSidebarService, AuthGuard, AuthService, DirectionService, QuickSearchSidebarService, SidebarSearchService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
