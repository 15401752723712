//////////////////// IBrandIdentity

export interface IBrandIdentity {
  id?: string;
  hotelName?: string;
  location: string;
  phoneNumber: string;
  brandColor: string;
  logoFile?: FileList;
  logoURL: string;
  backgroundFile?: FileList;
  backgroundURL: string;
  themeColor?: string;
  appHotelName?: string;
  textColor?: string;
}

/////////////////////// IBreakfast

export interface IBreakfast {
  headerImage: string;
  headerImageUrl?: string;
  description: string;
  notification: string;
}

export interface IBreakfastUpdate {
  id: string;
  restaurantImageURL?: string;
  breakfastIncludeMessage?: string;
  breakfastexcludeMessage: string;
}

//////////////////// IThemeSettings

export interface IThemeSettings {
  id: string;
  widgetCaption: WidgetCaption;
  name: string;
  contents: Content[];
  contact: Contact;
  theme: Theme;
  media: Media;
  gallery: any[];
  ratingCategories: string[];
  appBackgroundImages: AppBackgroundImages;
}

interface WidgetCaption {
  call: string;
  chat: string;
  breakfast: string;
  gallery: string;
  wifi: string;
}

interface Content {
  contentBody: string;
  featuredImageUrl: string;
  key: string;
}

interface Contact {
  address: string;
  phoneNumber: string;
  location: Location;
}

interface Location {
  locationName: string;
  coordinate: Coordinate;
}

interface Coordinate {
  latitude: number;
  longitude: number;
}

interface Theme {
  primaryColor: string;
  textColor: string;
}

interface Media {
  hotelGuideUrl: string;
  logoUrl: string;
  backgroundUrl: string;
}

interface AppBackgroundImages {
  breakfastBackgroundUrl: string;
  checkOutBackgroundUrl: string;
  preCheckInBackgroundUrl: string;
  galleryBackgroundUrl: string;
  houseKeepingBackgroundUrl: string;
}

////////////////// ICheckInOut

export interface ICheckInOut {
  id?: string;
  checkInMessages?: string;
  checkOutMessages?: string;
  backgroundUrl?: string;
}

////////////////// IGallery

export interface IGalleryItem {
  sequence?: number;
  imageFile?: any;
  imageUrl?: any;
  caption?: string;
  blobId?: any;
  newCaption?: string;
  isUploaded?: boolean;
}

export interface IGallery {
  id?: any;
  subscriptionId: string;
  title: string;
  description: string;
  openHours: string;
  tags: any;
  enabled: boolean;
  sequence: number;
  galleryItems: Array<IGalleryItems>;
}
export interface IGalleryItems {
  galleryId: string;
  caption: string;
  blobId: string;
  sequence: number;
}

export interface Gallery {
  subscriptionId: string;
  galleryCategories: GalleryCategory[];
}

export class GalleryCategory {
  id: string;
  title: string;
  description: string;
  openHours: string;
  tags: string;
  enabled: boolean;
  sequence: number;
  galleryCategoryImagesDTOs: Array<GalleryCategoryImagesDTO>;
  backgroundUrl?: string;
}

export interface subscriptionImage {
  id: string;
  imageUrl: string;
}

export interface GalleryCategoryImagesDTO {
  galleryId: string;
  caption: string;
  blobId: string;
  sequence: number;
  imageUrl: string;
}

/////////// IWidgetCaption

export interface IWidgetCaption {
  id?: string;
  breakfast: string;
  gallery: string;
  chat: string;
  call: string;
  wifi: string;
  widgetColor?: string;
}

//////// IWidgetContent

export interface IWidgetContent {
  title: string;
  icon: string;
  content: string;
  contentFontSize: string;
  backgroundWidgetColor: string;
  fontWidgetColor: string;
}

//////// IHotelGuide

export interface IHotelGuide {
  id?: string;
  pdfFile?: any;
  pdfFileUrl?: any;
  HotelGuideUrl?: string;
}

////// IHotelMap

export interface IHotelMap {
  id: string;
  fullName?: string;
  hotelLatitude: number;
  hotelLongitude: number;
  address: string;
  isUpdateXY?: boolean;
}
