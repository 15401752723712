import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckInComponent } from './check-in.component';
import { CheckInRoutingModule } from './check-in-routing.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ComponentsModule } from '@shared/components/components.module';
import { SharedModule } from '@shared';
import { PreviewMobileCheckInOutModule } from '@shared/components/preview-mobile-devices/preview-mobile-check-in-out/preview-mobile-check-in-out.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [CheckInComponent],
  imports: [
    CommonModule,
    CheckInRoutingModule,
    CKEditorModule,
    PreviewMobileCheckInOutModule,
    ComponentsModule,
    TranslateModule,
    SharedModule
  ],
  exports:[CheckInComponent]
})
export class CheckInModule { }
