import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HelpDialogComponent } from '@shared/modules/help-dialog/help-dialog.component';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input()
  title!: string;
  @Input()
  items!: string[];
  @Input()
  active_item!: string;
  @Input() showBreadcrumbs: boolean = true; 

  constructor(public translateService : TranslateService, public dialog: MatDialog) {
    //constructor
  }

  openHelpDialog()
  {
    this.dialog.open(HelpDialogComponent, {
      data: {
        title: this.title
      },
      width: '1900px',
      height: '80vh',
      position: {top: '50px'}
    });
  }
}
