import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './modules/errors/page404/page404.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { RoleGuard } from '@core/guards/role.guard';
import { UserRoles } from '@core/utils/user-roles.enum';
import { ReportPdfComponent } from './modules/billing/reports/report-pdf/report-pdf.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: ':subscriptionId/dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: ':subscriptionId/stickynotes',
        loadChildren: () =>
          import('./modules/stickynotes/stickynotes.module').then((m) => m.StickynotesModule),
      },
      {
        path: ':subscriptionId/reports/statistics',
        loadChildren: () =>
          import('./modules/statistics/statistics.module').then(
            (m) => m.StatisticsModule
          ),
        canActivate: [RoleGuard],
        data: {
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER],
        },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: ':subscriptionId/billing',
        loadChildren: () =>
          import('./modules/billing/billing.module').then((m) => m.BillingModule),
        canActivate: [RoleGuard],
        data: { roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER] },
      },
      {
        path: ':subscriptionId/chat',
        loadChildren: () =>
          import('./modules/chat/chat.module').then((m) => m.ChatModule),
        canActivate: [RoleGuard],
        data: {
          roles: [
            UserRoles.SUPER_ADMIN,
            UserRoles.OWNER,
            UserRoles.MANAGER,
            UserRoles.RECEPTION,
          ],
        },
      },
      {
        path: ':subscriptionId/theme-settings',
        loadChildren: () =>
          import('./modules/theme-settings/theme-settings.module').then(
            (m) => m.ThemeSettingsModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER , UserRoles.MANAGER] },
      },
      {
        path: ':subscriptionId/transport',
        loadChildren: () =>
          import('./modules/transport/transportations.module').then(
            (m) => m.transportationsModule
          ),
        canActivate: [RoleGuard],
        data: {
          roles: [
            UserRoles.SUPER_ADMIN,
            UserRoles.OWNER,
            UserRoles.MANAGER,
            UserRoles.RECEPTION,
            UserRoles.TRANSPORTER
          ],
        },
      },
      {
        path: ':subscriptionId/settings',
        loadChildren: () =>
          import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [RoleGuard],
        data: { roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER , UserRoles.MANAGER] },
      },
      {
        path: ':subscriptionId/reports',
        loadChildren: () =>
          import('./modules/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [RoleGuard],
        data: { roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER , UserRoles.MANAGER] },
      },
      {
        path: ':subscriptionId/rooms',
        loadChildren: () =>
          import('./modules/rooms/rooms.module').then((m) => m.RoomsModule),
        canActivate: [RoleGuard],
        data: {
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER],
        },
      },
      {
        path: ':subscriptionId/housekeeping',
        loadChildren: () =>
          import('./modules/housekeeping/housekeeping.module').then((m) => m.HousekeepingModule),
        canActivate: [RoleGuard],
        data: {
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER , UserRoles.RECEPTION , UserRoles.HOUSE_KEEPING],
        },
      },
      {
        path: ':subscriptionId/calendar',
        loadChildren: () =>
          import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
        canActivate: [RoleGuard],
        data: {
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER,UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/inventory',
        loadChildren: () =>
          import('./modules/inventory/inventory.module').then((m) => m.InventoryModule),
        canActivate: [RoleGuard],
        data: {
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER , UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
        canActivate: [RoleGuard],
        data: { roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER] },
      },
      {
        path: ':subscriptionId/reservations',
        loadChildren: () =>
          import('./modules/reservations/reservations.module').then(
            (m) => m.ReservationsModule
          ),
        canActivate: [RoleGuard],
        data: {
          roles: [
            UserRoles.SUPER_ADMIN,
            UserRoles.OWNER,
            UserRoles.MANAGER,
            UserRoles.RECEPTION,
          ],
        },
      },
      {
        path: ':subscriptionId/guests',
        loadChildren: () =>
          import('./modules/guests/guests.module').then((m) => m.GuestsModule),
        canActivate: [RoleGuard],
        data: {
          roles: [
            UserRoles.SUPER_ADMIN,
            UserRoles.OWNER,
            UserRoles.MANAGER,
            UserRoles.RECEPTION,
          ],
        },
      },
      {
        path: ':subscriptionId/customers',
        loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule),
        canActivate: [RoleGuard],
        data: {
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER , UserRoles.RECEPTION],
        },
      },
      {
        path: ':subscriptionId/schedule-reservations',
        loadChildren: () => import('./modules/schedule-reservations/schedule-reservations.module').then(m => m.ScheduleReservationsModule),
        canActivate: [RoleGuard],
        data: {
          roles: [UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.MANAGER , UserRoles.RECEPTION],
        },
      },

      {
        path: ':subscriptionId/support',
        loadChildren: () =>
          import('./modules/support/support.module').then((m) => m.SupportModule),
      },
      {
        path: 'admin/subscriptions',
        loadChildren: () =>
          import('./modules/subscriptions/subscriptions.module').then(
            (m) => m.SubscriptionsModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [UserRoles.SUPER_ADMIN] },
      },
      {
        path: 'admin/super-admins',
        loadChildren: () =>
          import('./modules/super-admins/super-admins.module').then(
            (m) => m.SuperAdminsModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [UserRoles.SUPER_ADMIN] },
      }
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: ':subscriptionId/print/billing/invoice',
    component: ReportPdfComponent,
    loadChildren: () =>
      import('./modules/billing/reports/report-pdf/report-pdf.module').then(
        (m) => m.ReportPdfModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
