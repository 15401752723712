import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';
import { Identity, User } from '../models/users/user';
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BaseAPIService } from './base-api.service';
import {
  SubscriptionReservation,
  SubscriptionsLight,
} from '@core/models/subscriptions/subscriptions.model';
import { UserRoles } from '@core/utils/user-roles.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseAPIService {
  private currentUserSubject: BehaviorSubject<User>;

  public currentUser: Observable<User>;
  public currentSubscription: BehaviorSubject<SubscriptionsLight>;
  public currentIdentity: Identity;
  public currentReservation: BehaviorSubject<SubscriptionReservation>;

  constructor(http: HttpClient, public afAuth: AngularFireAuth) {
    super(http);
    this.currentUserSubject = new BehaviorSubject<User>(
      new User(sessionStorage.getItem('_displayName'))
    );
    this.currentSubscription = new BehaviorSubject<SubscriptionsLight>({
      id: '',
      name: '',
      billingPackage: '',
      createdDate: new Date(),
      updatedDate: new Date(),
    } as SubscriptionsLight);
    this.currentUser = this.currentUserSubject.asObservable();
    this.initTokenRefresh();
  }

  public get accountSetupCompleted(): boolean {
    return (
      this.currentSubscription.value.id &&
      this.currentSubscription.value.setupStatus.general &&
      this.currentSubscription.value.setupStatus.roomTypes &&
      this.currentSubscription.value.setupStatus.rooms);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentSubscriptionValue(): SubscriptionsLight {
    return this.currentSubscription.value;
  }

  public get userIdentity(): Identity {
    if (this.currentIdentity == null) {
      this.currentIdentity = JSON.parse(sessionStorage.getItem('_identity'));
    }
    return this.currentIdentity;
  }

  public get isSuperAdministrator(): boolean {
    return this.userIdentity.isSuperAdmin;
  }

  public get isAccountOwner(): boolean {
    return this.userIdentity.isSubscriptionOwner;
  }

  public get currentUserDirect(): User {
    return new User(sessionStorage.getItem('_displayName'));
  }

  public refreshUser(): Promise<any> {
    return this.getLoginUser().then((identity) => {
      this.currentIdentity = null;

      this.mapRoles(identity);
      sessionStorage.setItem('_identity', JSON.stringify(identity));
    });
  }

  logout() {
    sessionStorage.removeItem('_identity');
    sessionStorage.removeItem('_jwt');
    this.currentUserSubject.next(this.currentUserValue);
    return of({ success: false });
  }

  googleAuth(): Promise<any> {
    return this.authLogin(new GoogleAuthProvider()).then(async (result) => {
      const user = result.user;
      await result.user.getIdToken().then(async (tkn) => {
        sessionStorage.setItem('_displayName', user.displayName);
        sessionStorage.setItem('_jwt', tkn);

        const lastSubscriptionId = localStorage.getItem('_lastSubscriptionId');
        await this.getLoginUser(lastSubscriptionId).then((identity) => {
          localStorage.setItem('_lastSubscriptionId', identity.subscriptionId);
          localStorage.setItem('_userId', identity.id);
          this.mapRoles(identity);
          sessionStorage.setItem('_identity', JSON.stringify(identity));
        });
      });
    });
  }

  googleSignUp(): Promise<any> {
    return this.authLogin(new GoogleAuthProvider())
      .then(async (result) => {
        const user = result.user;
        return user.getIdToken();
      })
      .catch(() => {
        console.log('Something went wrong');
      });
  }

  appleSignUp(): Promise<any> {
    return this.authLogin(new OAuthProvider('apple.com'))
      .then(async (result) => {
        const user = result.user;
        return user.getIdToken();
      })
      .catch(() => {
        console.log('Something went wrong');
      });
  }

  async initTokenRefresh() {
      setInterval(async () => {
      if(this.afAuth?.currentUser)
        {
          const user = this.afAuth.currentUser;
          const token = await (await user).getIdTokenResult(false);
          if (user) {
            const expirationTime = token.expirationTime;
            const currentTime = new Date().getTime();
            const remainingTime = Date.parse(expirationTime) - currentTime;
            console.log('exiration time',remainingTime);
            const refreshMarginInMs = 5 * 60 * 1000; // 5 minutes in milliseconds
            if (remainingTime < refreshMarginInMs) {
              try {
                const newToken = await (await user).getIdTokenResult(true);
                console.log('New token:', newToken);
                sessionStorage.setItem('_jwt', newToken.token);
              } catch (error) {
                console.error('Failed to refresh token:', error);
              }
            }
          }
        }
    }, 3 * 60 * 1000); // Check every 3 minutes
  }

  appleAuth(): Promise<any> {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    return this.authLogin(new OAuthProvider('apple.com'))
      .then(async (result) => {
        const user = result.user;
        await result.user.getIdToken().then(async (tkn) => {
          sessionStorage.setItem('_displayName', user.displayName);
          sessionStorage.setItem('_jwt', tkn);
          await this.getLoginUser().then((identity) => {
            this.mapRoles(identity);
            sessionStorage.setItem('_identity', JSON.stringify(identity));
          });
        });
      })
      .catch(() => {
        console.log('Something went wrong');
      });
  }

  authLogin(provider): Promise<any> {
    return this.afAuth.signInWithPopup(provider);
  }

  getLoginUser(subscriptionId?: string): Promise<any> {
    return firstValueFrom(this.getIdentity(subscriptionId));
  }

  getIdentity(subscriptionId?: string): Observable<any> {
    console.log('running' , subscriptionId)
    return this.post('authentication/login', {subscriptionId});
  }

 

  mapRoles(identity: any) {
    const roles = [];

    if (identity.isSuperAdmin) roles.push(UserRoles.SUPER_ADMIN);
    if (identity.isSubscriptionOwner) roles.push(UserRoles.OWNER);
    if (identity.managementPermission) roles.push(UserRoles.MANAGER);
    if (identity.housekeepingPermission) roles.push(UserRoles.HOUSE_KEEPING);
    if (identity.receptionPermission) roles.push(UserRoles.RECEPTION);
    if (identity.restaurantPermission) roles.push(UserRoles.RESTAURANT);
    if (identity.transportPermission) roles.push(UserRoles.TRANSPORTER);

    identity.roles = roles;
  }
}
