// export interface IReceptionCardModel {
//     reservationName : string,
//     roomName : string,
//     status: number,
//     information: string,
//     roomNumbers: string,
//     adults: number,
//     children: number,
//     arrivalDate: string,
//     tags: Array<TagType>
// }

import { ITag } from "@core/interfaces";
import { CleaningStatus } from "../rooms/rooms.model";

export interface IReceptionCardModel {
    bookingNumber : number;
    bookingReference: string;
    cancellationNote : string;
    checkInDate : Date;
    checkInDateTime: Date;
    checkOutDate : Date;
    checkOutDateTime : Date;
    children : number;
    adult: number;
    email : string;
    firstName : string;
    id : string;
    internalNote : string;
    isBreakfastInclude : boolean;
    isCanceled : boolean;
    isCheckIn : boolean;
    isRated : boolean;
    lastName : string;
    reservationStatus : ReservationStatusTypes;
    reservationStatusForDashboard : ReservationStatusTypes;
    reservationNote : string;
    reservationSource : string;
    roomNumber : string;
    roomTypeId : string;
    roomTypeName : number;
    specialRequest : string;
    subscriptionId : string;
    tags: ITag[];
    housekeepingStatus : number;
    cleaningStatus : CleaningStatus;
    passportNumber?: string;
    nights: number;
    totalCharges : number;
    totalPayments : number;
    isReturningCustomer : boolean
    amountDue : number;
}


export interface TagType {
    id: string;
    name: string;
    cssClass: string;
  }


export enum ReservationStatusTypes {
    BOOKEND,
    DUEIN,
    CHECKEDIN,
    DUEOUT,
    CHECKEDOUT,
    NOSHOW,
    DUEINTOMORROW,
    DUEOUTTOMORROW
}

export interface CancelReservation {
    id: string,
    note: string,
}
  