import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-navigator-bar',
  templateUrl: './mobile-navigator-bar.component.html',
  styleUrls: ['./mobile-navigator-bar.component.scss']
})
export class MobileNavigatorBarComponent {

}
