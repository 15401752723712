
<nav #navbar class="navbar active">
  <div class="hotel-settings-alert" *ngIf="this.authService.currentSubscription.value.id && !authService.accountSetupCompleted">
    <div>
      <button (click)="goToSettings()">{{ 'missingGeneralHotelSettings' | translate }}</button>  <button class="go-to-settings-button" (click)="goToSettings()"> {{ 'setupHotelSettings' | translate }}  <mat-icon>north_east</mat-icon></button>
    </div>
  </div>
  <div class="container-fluid navbar-top-border"
    [ngStyle]="this.authService.currentSubscription.value.id && !this.authService.accountSetupCompleted ? {'margin-top': '20px'} : {}">
    <div class="mystay-container-navbar">
      <div class="mystay-title-navbar" *ngIf="authService?.currentSubscriptionValue.name !== ''">
        {{ authService?.currentSubscriptionValue.name }}
      </div>
      <div *ngIf="this.authService.currentSubscription.value.id" class="open-right-menu-container">
        <div class="menu-container">
        </div>
        <button mat-icon-button (click)="openQuickSearchMenu()" class="open-right-menu-button">
          <i class="fas fa-search"></i>
        </button>
        <button mat-icon-button (click)="openActivityLogMenu()" class="open-right-menu-button">
          <i class="fas fa-list"></i>
        </button>
      </div>
      <div
        #toggleButton
        class="mystay-open-profile-navbar"
        (click)="openProfileMenu(!isProfileMenuOpen)"
      >
        <div>
          <div #openMenu1 class="username">
            {{ authService.userIdentity.displayName }}
          </div>
          <div #openMenu2 class="role">
            {{
              authService.isSuperAdministrator
                ? ("superAdministrator" | translate)
                : authService.isAccountOwner
                ? ("owner" | translate)
                : ("user" | translate)
            }}
          </div>
        </div>
        <div class="chevron-down" #openMenu3>
          <i class="fa fa-chevron-down"></i>
        </div>
      </div>
      <div
        [class.show]="isProfileMenuOpen"
        class="dropdown-menu dropdown-menu-right dropdown-right-theme">
          <button type="button" [routerLink]="'/profile'">
            <mat-icon>person</mat-icon>
            {{ "account" | translate }}
          </button>
          <button type="button" [routerLink]="[subscriptionId,'billing']" *ngIf="isSuperAdminOrOwner && subscriptionId">
            <mat-icon>attach_money</mat-icon>
            {{ "billing" | translate }}
          </button>
          <button type="button" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            {{ "signOut" | translate }}
          </button>
      </div>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button
            mat-icon-button
            (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
            class="sidemenu-collapse"
          >
            <app-feather-icons
              [icon]="'menu'"
              [class]="'header-icon'"
            ></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="float-start navbar-nav navbar-left">
        <li class="nav-item btnAppList" ngbDropdown>
          <mat-menu
            #appDropdownMenu="matMenu"
            class="notification-dropdown app-dropdown"
            xPosition="before"
          >
          </mat-menu>
        </li>
      </ul>
    </div>
    <div
      class="collapse navbar-collapse"
      [ngClass]="isNavbarCollapsed ? '' : 'show'"
    ></div>
  </div>
</nav>
