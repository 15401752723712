import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-settings-help-component',
  templateUrl: './settings-help.component.html',
  styleUrls: ['./settings-help.component.scss'],
})
export class SettingsHelpComponent {
  constructor
    (public translateService: TranslateService) {
  }
}
