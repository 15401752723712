import { EventEmitter, Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from './base-api.service';
import { TokenService } from './token.service';
import * as signalR from '@aspnet/signalr';

@Injectable({
  providedIn: 'root',
})
export class SignalRService extends BaseAPIService {
  private hubConnection: HubConnection;
  private hubConnectionId: string;

  connectionEstablished = new EventEmitter<Boolean>();
  activityLogUpdated = new EventEmitter<void>();

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  connect() {
    if (this.hubConnection) return;

    const token = TokenService.get();
    if (token) {
      this.createConnection(token);
      this.registerOnServerEvents();
      this.startConnection();
    } else {
      console.error('Token is missing');
    }
  }

  disconnect() {
    if (this.hubConnection) {
      this.unRegisterOnServerEvents();
      this.hubConnection.stop();
      this.hubConnection = null;
    }
  }

  private createConnection(token: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}activityLogHub`, {
        accessTokenFactory: () => token,
        transport: HttpTransportType.WebSockets | HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  private startConnection(): void {
    this.hubConnection
      .start()
      .then(async () => {
        await this.getConnectionId();
        this.connectionEstablished.emit(true);
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...', err);
        setTimeout(() => this.startConnection(), 5000);
      });
  }

  private async getConnectionId() {
    this.hubConnection
      .invoke('GetConnectionId')
      .then(res => {
        this.hubConnectionId = res;
        console.log('>>> Hub connection id', this.hubConnectionId);
      })
      .catch(err => {
        console.error('Error getting connection id', err);
      });
  }

  /**
   * INVOKER
   */


  /**
   * SERVER EVENTS
   */

  private registerOnServerEvents(): void {
    this.hubConnection.on('ActivityLogUpdated', () => {
      this.activityLogUpdated.emit();
    });
  } 

  private unRegisterOnServerEvents(): void {
    this.hubConnection.off('ReceiveMessage');
  }
}
