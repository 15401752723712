import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogService extends BaseAPIService {

  constructor(http: HttpClient) {
   super(http);
  }

  getActivityLogList(subscriptionId:string) : any {
    return this.get(`activity-log/${subscriptionId}/list`)
  }
}
