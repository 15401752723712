/* eslint-disable @typescript-eslint/no-unused-vars */
import { Router, NavigationEnd, ActivatedRoute, RouterLinkActive } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
  ViewChild,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { AuthService } from '@core';
import { RouteInfo } from './sidebar.metadata';
import { PermissionService } from '@core/services/permission.service';
import { MessageService } from '@core/services/message.service';
import { ChatService } from '@core/services/chat.service';
import { SubSink, UnsubscribeOnDestroyAdapter } from '@shared';
import { UserService } from '@core/services/user.service';
import { MessageReceive, MessagesRead } from '@core/models/chats/message';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  @ViewChild(RouterLinkActive) private routerLinkActive: RouterLinkActive;
  public sidebarItems!: RouteInfo[];
  public innerHeight?: number;
  public bodyTag!: HTMLElement;
  public isAdminMode: boolean = false;
  listMaxHeight?: string;
  listMaxWidth?: string;
  userFullName?: string;
  userImg?: string;
  userType?: string;
  headerHeight = 60;
  currentRoute?: string;
  menuIcon = 'radio_button_checked';
  subscriptionId: string;
  isOpenedSlideBar = false;
  unreadMessageCount: number = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    public authService: AuthService,
    public chatService: ChatService,
    public messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    public permissionService: PermissionService,
    private ngZone: NgZone,
  ) {
    super();

    this.elementRef.nativeElement.closest('body');
    this.subs.sink = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });

    this.chatService.setSubscriptionId(this.authService.currentSubscription.value.id);
    this.getUnreadMessageCount();
    this.subscribeMessageEvents();
    this.subscribeMessagesReadEvents();
  }
  ngAfterViewInit(): void {
    this.renderer.addClass(this.document.body, 'submenu-closed');
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall() {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }

  // I need a property saying if body has a class named side-closed
  get isSideClosed(): boolean {
    return this.document.body.classList.contains('side-closed');
  }

  get isSubmenuClosed(): boolean {
    return this.document.body.classList.contains('submenu-closed');
  }
  
  getUnreadMessageCount() {
    this.subs.sink = this.chatService.getUnreadMessageCount().subscribe(res => {
      const unreadCount = res;

      const chatMenu = this.sidebarItems.find(s => s.path.includes('chat'));
      chatMenu.badge = unreadCount > 0 ? unreadCount.toString() : '';
    });
  }

  private subscribeMessageEvents(): void {
    this.subs.sink = this.messageService.messageReceived.subscribe((res: MessageReceive) => {
      this.ngZone.run(() => {
        const unreadCount = res.unreadCount;

        const chatMenu = this.sidebarItems.find(s => s.path.includes('chat'));
        chatMenu.badge = unreadCount > 0 ? unreadCount.toString() : '';
      });
    });
  }

  private subscribeMessagesReadEvents(): void {
    this.subs.sink = this.messageService.messagesRead.subscribe((res: MessagesRead) => {
      this.ngZone.run(() => {
        const readCount = res.count;

        const chatMenu = this.sidebarItems.find(s => s.path.includes('chat'));
        let unreadCount = parseInt(chatMenu.badge) - readCount;

        chatMenu.badge = unreadCount > 0 ? unreadCount.toString() : '';
      });
    });
  }

  callToggleMenu(event: Event, length: number) {
    this.setStylingMenuBar();
    const clickedItemPath = this.getClickedItemPath(event);
    const isSettingsMenu = clickedItemPath.startsWith('settings');


    if (isSettingsMenu && !this.isOpenedSlideBar) {
      this.mobileMenuSidebarOpen();
    }
    const parentElement:any = (event.target as HTMLInputElement).closest('li');
    const activeClass:any = parentElement?.classList.contains('active');
    const handleActive:any = document.getElementsByClassName("handle-active")[8];

    if (length > 0) {
      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        this.extendedLayoutMenu(parentElement, isSettingsMenu);
        this.renderer.addClass(parentElement, 'active');
      }
    }
    if(clickedItemPath && clickedItemPath !='settings'){
      this.renderer.removeClass(parentElement, 'active');
      this.renderer.removeClass(handleActive, 'active');
    }
  }

  getClickedItemPath(event: Event): string {
    let target = event.target as HTMLElement;
    while (target && !target.getAttribute('data-path')) {
      target = target.parentElement;
    }

    if (target) {
      return target.getAttribute('data-path') || '';
    }

    return '';
  }

  extendedLayoutMenu(parentElement, isSettingsMenu) {
    if (isSettingsMenu) {
      this.mobileMenuSidebarOpen();
    }
  }

  ngOnInit() {
    if (this.authService.currentUserValue) {
      // this.renderer.addClass(this.document.body.getElementsByClassName("logo-name")[0], 'show-logo-name');
      this.userFullName = this.authService.currentUserValue.firstName + ' ' + this.authService.currentUserValue.lastName;
      this.userImg = this.authService.currentUserValue.img;
      this.userType = 'Admin';
      this.sidebarItems = ROUTES.filter(sidebarItem => sidebarItem);
      this.router.url;
      this.manageAdminModeRoute(this.router.url);
      this.router.events.subscribe(val => {
        this.setStylingMenuBar();
        this.setMenuHeight()
        if (val instanceof NavigationEnd) {
          this.manageAdminModeRoute(val.url);
        }
      });
      this.callSidemenuCollapse();
    }
   
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  get filteredSideBar(): RouteInfo[] {

    var housekeeping = this.sidebarItems.find(x => x.title == 'housekeeping');
    housekeeping.visible = this.authService.currentSubscriptionValue.isEnableHousekeeping;
    
    var reminders = this.sidebarItems.find(x => x.title == 'stickynotes');
    reminders.visible = this.authService.currentSubscriptionValue.isEnableReminders;

    var transport = this.sidebarItems.find(x => x.title == 'transport');
    transport.visible = this.authService.currentSubscriptionValue.isEnableTransport;

    var chat = this.sidebarItems.find(x => x.title == 'chat');
    chat.visible = this.authService.currentSubscriptionValue.isEnableChat;

    let data = this.isAdminMode
      ? this.sidebarItems.filter(x => x.view == 'admin' && x.visible)
      : this.sidebarItems.filter(x => x.view == 'hotel' && x.visible);
    if (!this.isAdminMode) {
      data.forEach(item => {
        item.subscription = this.subscriptionId;
        item.submenu.forEach(subItem => {
          subItem.subscription = this.subscriptionId;
        });
      });
    }

    return data;
  }

  initLeftSidebar() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }

  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    let compensateSetupBar = (this.authService.currentSubscription.value.id && !this.authService.accountSetupCompleted ? 20 : 0);
    const height = this.innerHeight - this.headerHeight - 70 - compensateSetupBar;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }

  checkStatuForResize(firstTime: boolean) {
    if (window.innerWidth < 1025) {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      this.isOpenedSlideBar = false;
    }
  }

  private manageAdminModeRoute(input: string) {
    if (input.split('/')[1] == 'profile' && this.authService.userIdentity.isSuperAdmin) {
      this.isAdminMode = true;
    } else if (input.split('/')[1] == 'profile') {
      if (!this.authService.currentSubscriptionValue?.id) {
        this.subscriptionId = this.authService.userIdentity.subscriptionId;
      }
    } else if (input.split('/')[1] != 'admin') {
      var subscriptionFromUrl = input.split('/')[1];
      var subscriptionId = this.authService.currentSubscriptionValue?.id;

      if (subscriptionId) this.subscriptionId = subscriptionId;
      else this.subscriptionId = subscriptionFromUrl;

      this.isAdminMode = false;
    } else {
      this.isAdminMode = true;
    }
  }

  getRouterLinkForMenuItem(info: RouteInfo): any {
    if (info.subscription) {
      var tempArray = [info.subscription];
      var test = info.path.split('/');
      return [...tempArray, ...test];
    } else {
      return [...info.path.split('/')];
    }
  }

  getRouterLinkForSubMenuItem(info: RouteInfo): any {
    if (info.subscription) {
      return info.subscription;
    } else {
      return '';
    }
  }

  mobileMenuSidebarOpen() {
    this.renderer.removeClass(this.document.body, 'side-closed');
    this.renderer.removeClass(this.document.body, 'submenu-closed');
    const element:any = document.getElementById("leftsidebar");
    if(element){
      element.classList.add("mobile-show-slidebar-open");
    }
    this.renderer.addClass(this.document.body, 'mobile-show-slidebar-open');
    this.isOpenedSlideBar = true;
  }
  
  callSidemenuCollapse() {
    this.renderer.addClass(this.document.body, 'side-closed');
    this.renderer.addClass(this.document.body, 'submenu-closed');
    const element:any = document.getElementById("leftsidebar");
    if(element){
      element.classList.remove("mobile-show-slidebar-open");
    }

    this.isOpenedSlideBar = false;
  }

  hasPermission(item: RouteInfo) {
    if (item.roles) {
      return this.permissionService.hasAnyRoles(item.roles);
    }

    return true;
  }

  setStylingMenuBar(): any {
    const systemStyle: any = document.getElementsByClassName('settings-menu')[0];
    if (systemStyle) {
      if (window.location.pathname.includes('settings')) {
        systemStyle.style.backgroundColor = '#f0e6e4';
      } else {
        systemStyle.style.backgroundColor = '';
      }
    }
  }

  logout() {
    this.authService.logout().subscribe(res => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }

  isActive(routeInfo: RouteInfo): boolean {
    let currentUrl = this.router.url;

    if (currentUrl === `/${this.subscriptionId}/${routeInfo.path}`) {
      return true;
    }

    if (currentUrl.startsWith(`/${this.subscriptionId}/${routeInfo.path}`)) {
      return true;
    }

    return false;
  }
  
}
