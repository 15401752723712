import { Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {  DomSanitizer } from '@angular/platform-browser';
import { IHotelGuide } from '@core/interfaces/Ihotel-guide';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-preview-mobile-hotel-guide',
  templateUrl: './preview-mobile-hotel-guide.component.html',
  styleUrls: ['./preview-mobile-hotel-guide.component.scss']
})
export class PreviewMobileHotelGuideComponent implements OnInit {

  @Input() subscribeHotelGuideReceiver: Observable<IHotelGuide> = null
  constructor( public sanitizer: DomSanitizer) {}

  public hotelGuide: IHotelGuide = {
    pdfFile: '',
    pdfFileUrl: '',
  }
  urlPDF : any;

  ngOnInit (): void {
    this.subscribeHotelGuideReceiver?.subscribe(result => {
      this.hotelGuide.pdfFileUrl = result.pdfFileUrl+"?v=" + this.generateVersion();
      this.urlPDF = this.sanitizer.bypassSecurityTrustResourceUrl(this.hotelGuide.pdfFileUrl+"#toolbar=0");
    });
  }
  generateVersion (){
    const d = new Date();
    return Math.round(d.getMilliseconds()) * 1000;
  }
}

@Pipe({name: 'mySafe'})
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }
    public transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
