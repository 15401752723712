import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ThemeSettingsService } from '@core/services/theme-settings.service'
import { IHotelGuide } from '@core/interfaces/Ihotel-guide'
import { Subject, Subscription, firstValueFrom } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-hotel-guide',
  templateUrl: './hotel-guide.component.html',
  styleUrls: ['./hotel-guide.component.scss']
})
export class HotelGuideComponent implements OnInit {
  subscription: Subscription

  constructor (
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate : TranslateService
  ) {}

  public subscribeHotelGuideEvent: Subject<IHotelGuide> = new Subject()
  isUploadFile: boolean = false
  fileDirectory: string = 'hotelguide'
  public file_store: FileList
  subscriptionId: string

  pdfFileProps: IHotelGuide = {
    pdfFile: '',
    pdfFileUrl: ''
  }

  filePDFName = this.pdfFileProps.pdfFileUrl.split('/').pop()

  hotelGuideFormGroup = new FormGroup({
    pdfName: new FormControl(this.filePDFName, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pdfFile: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  async handleFileInputChange (event: any, l: FileList) {
    this.isUploadFile = true
    this.file_store = l
    if (l.length) {
      const count = l.length > 1 ? `(+${l.length - 1} files)` : ''
      this.hotelGuideFormGroup.controls.pdfName.patchValue(
        `${l[0].name}${count}`
      )
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader()
        this.route.paramMap.subscribe(paramMap => {
          this.subscriptionId = paramMap.get('subscriptionId')
          const formData: any = new FormData()
          formData.append('file', event.target.files[0])
          formData.append('folder', this.fileDirectory)
          formData.append('Restrict', 'false')
          formData.append('SubscriptionId', this.subscriptionId)
          const upload$ = this.themeSettingsService.uploadFileToCloudStorage(formData)

          this.subscription = upload$.subscribe(response => {
            (this.pdfFileProps.pdfFileUrl = response.imageUrl),
              this.hotelGuideFormGroup.controls.pdfName.patchValue(
                response.imageUrl.substring(
                  response.imageUrl.lastIndexOf('/') + 1,
                  response.imageUrl.length
                )
              )
            this.isUploadFile = false
            this.loadData()
          })
        })

       
      }
    } else {
      this.hotelGuideFormGroup.controls.pdfName.patchValue('')
      this.isUploadFile = false
    }
  }

  ngOnInit () {
    this.loadData()
  }

  loadData () {
    this.route.paramMap.subscribe(paramMap => {
      this.subscriptionId = paramMap.get('subscriptionId')
      firstValueFrom(
        this.themeSettingsService.getThemeSettings(this.subscriptionId)
      ).then((response: any) => {
        let hotelGuideUrl = response.media.hotelGuideUrl
        this.hotelGuideFormGroup.controls.pdfName.patchValue(`${hotelGuideUrl.substring(hotelGuideUrl.lastIndexOf('/') + 1,hotelGuideUrl.length)}`)
        this.pdfFileProps.pdfFileUrl = response.media.hotelGuideUrl;
        this.subscribeHotelGuideEvent.next(this.pdfFileProps)
      })
    })
  }
  async onSubmit () {
    if (this.hotelGuideFormGroup.controls.pdfName.value != '') {
      this.route.paramMap.subscribe(paramMap => {
        this.subscriptionId = paramMap.get('subscriptionId')

        let updateHotelGuide: IHotelGuide = {
          id: this.subscriptionId,
          HotelGuideUrl: this.pdfFileProps.pdfFileUrl
        }

        firstValueFrom(
          this.themeSettingsService.updateHotelGuide(updateHotelGuide)
        )
          .then(() => {
            const successMessage = this.translate.instant('hotelGuideHasBeenSaved'); 
            // this.snackbarService.alertSuccess(successMessage);
            this.toastr.success(successMessage, 'Operation Completed', {timeOut : 3500});
            this.isUploadFile = false
          })
          .catch(() => {
            const failedMessage = this.translate.instant('failedToSave'); 
            // this.snackbarService.alertError(failedMessage);
            this.toastr.success(failedMessage, 'Operation Failed', {timeOut : 3500});
            this.isUploadFile = false
          })
      })
    }
  }

}
