<section class="content">
  <div class="content-block">
    <div class="block-header">
      <!-- breadcrumb -->
      <app-breadcrumb [title]="'guestApp'" [items]="['home', 'settings', 'guestApp']"
        [active_item]="'gallery'"></app-breadcrumb>
    </div>
    <div class="card">
      <div class="body">
        <div class="gallery-preview-wrapper">
          <div class="form">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <app-title-content [title]="'gallery' | translate" [subTitle]="'hotelGallerySubtitle' | translate">
                </app-title-content>
                <form [formGroup]="galleryGroupForm">
                  <div class="row edge-spacing ">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{ 'upload' | translate }} {{ 'background'
                        | translate }} ({{
                        'recommendedSizeOf' | translate
                        }}
                        1080x1920 pixels)</mat-label>
                      <button class="button-upload" matPrefix (click)="f_inputBackground.click()">
                        <button type="button" class="mystay-button-choose-file">{{ 'chooseFile' |
                          translate }}</button>
                      </button>
                      <input type="text" readonly matInput formControlName="headerImageName" />
                      <input type="file" hidden #f_inputBackground formControlName="backgroundUploaded"
                        (change)="handleFileInputBackgroundChange($event, f_inputBackground.files)"
                        accept=".png,.jpeg,.jpg" />
                      <mat-error *ngIf="galleryGroupForm.controls['backgroundUploaded']?.hasError('required')">
                        {{ 'uploadBackgroundIsRequired' | translate }}
                      </mat-error>
                      <mat-error *ngIf="galleryGroupForm.controls['backgroundUploaded']?.hasError('invalidFileType')">
                        {{ 'Invalid file type. Please select a JPG or PNG image.' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="row edge-spacing ">
                    <div class="add-hotelGallery">
                      <button mat-mini-fab color="secondary" class="mystay-button-add-action"
                        (click)="onAddGalleryCategory()">
                        <mat-icon class="col-white">add</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div class="row edge-spacing ">
                    <div class="add-hotelGallery"> &nbsp; </div>
                  </div>
                  <div class="row edge-spacing ">
                    <div cdkDropList [cdkDropListData]="galleryCategories" (cdkDropListDropped)="drop($event)">
                      <div *ngFor="let category of galleryCategories; let i = index" cdkDrag>
                        <div class="card">
                          <div class="card-body">
                            <app-gallery-form [hotelCategory]="category" [subscriptionId]="subscriptionId" [index]="i"
                              (deleteRequest)="onDeleteGalleryCategory($event)"
                              (categoryChange)="handleCategoryChange($event, i)" (isValid)="handleValidity($event)">
                            </app-gallery-form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="body container-preview">
                  <app-preview-mobile-gallery
                    [subscribeGalleryReceiver]="subscribeGalleryEvent.asObservable()" (qrScanPreviewClick)="openQRCodeDialog()"
                    [backgroundUrl]="backgroundUrl"></app-preview-mobile-gallery>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-store-buttons">
          <div class="group-stores">
            <div class="btn-google-play-store" (click)="goToGooglePlayStore()"></div>
            <div class="btn-apple-store" (click)="goToAppStore()"></div>
          </div>
        </div>
        <HR>
        <div class="mystay-button-container form-actions">
          <button type="submit" class="mystay-button-submit" mat-raised-button color="primary" (click)="onSaveGallery()"
            [disabled]="isUpload">
            {{ 'save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>