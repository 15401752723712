import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { hasSome } from '@core/utils/utils';
import { Identity } from '@core/models/users/user';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  identity: Identity;

  constructor(private authService: AuthService) {
  }

  hasAnyRoles(roles: string[]) {
    this.identity = this.authService.userIdentity;

    if (roles && roles.length && this.identity?.roles) {
      const isContains = hasSome(roles, this.identity.roles);
      return roles ? isContains : false || roles == undefined;
    }

    return false;
  }

}
