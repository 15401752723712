<div id="activity-area" class="settingSidebar" [ngClass]="isOpenSidebar ? 'showSettingPanel' : ''">
  <div class="settingSidebar-body ps-container ps-theme-default">
    <div class="fade show active">
      <div class="setting-panel-header">
        <h3>{{ 'activitiesLog' | translate }}</h3>
        {{ 'activitiesLogForTheLast7Days' | translate }}
        <button mat-icon-button class="close-right-menu-button" (click)="closeSidebar()" aria-label="Close  Siderbar">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div style="padding: 10px; overflow: auto">
        <div class="form-group has-search">
          <input
            type="text"
            style="padding-left: 10px"
            class="form-control"
            [(ngModel)]="searchFilter"
            placeholder="{{ 'search' | translate }}"
            (keyup)="searchActivities()" />
          <span style="font-size: 8px; margin-right: 10px" class="fa fa-search form-control-feedback"></span>
        </div>
        <div style="height: calc(100vh - 170px)">
          <ng-scrollbar>
            <div *ngFor="let activity of this.activityLogListDisplay">
              <div class="activity-box">
                <span class="activity-icon">
                  <i *ngIf="activity.activityCategory === 0" class="fas fa-bed" style="font-size: 14px"></i>
                  <i *ngIf="activity.activityCategory === 1" class="fas fa-luggage-cart" style="font-size: 14px"></i>
                  <i *ngIf="activity.activityCategory === 2" class="fas fa-tools" style="font-size: 14px"></i>
                  <i *ngIf="activity.activityCategory === 3" class="fas fa-user" style="font-size: 14px"></i> 
                </span>
                <div class="activity-information"  [class.disabled]="activity.fieldValue3 === 'Deleted'">
                  <!-- Handle reservation related activities -->
                  <span *ngIf="activity.activityCategory === 1">
                    <ng-container>
                      {{ prepareActivityText(activity.text, activity.fieldValue1).before }}
                      <a *ngIf="activity.fieldValue3 !== 'Deleted'"
                         (click)="navigateToReservationDetail(activity.subscriptionId, activity.reservationRef)"
                         class="clickable-link">
                        {{ prepareActivityText(activity.text, activity.fieldValue1).id }}
                      </a>
                      <span *ngIf="activity.fieldValue3 === 'Deleted'">
                        {{ prepareActivityText(activity.text, activity.fieldValue1).id }}
                      </span>
                      {{ prepareActivityText(activity.text, activity.fieldValue1).after }}
                    </ng-container>
                    <ng-template #plainTextForReservation>
                      <span>{{ activity.text }}</span>
                    </ng-template>
                  </span>

                  <!-- Handle room related activities -->
                  <span *ngIf="activity.activityCategory === 0">
                    {{ activity.text }}
                  </span>
                  <span *ngIf="activity.activityCategory === 2">
                    {{ activity.text }}
                  </span>
                  <span *ngIf="activity.activityCategory === 3">
                    {{ activity.text }}
                  </span>
                  <br />
                  <div class="notification-time">
                    {{
                      getDayForRow(activity.occurredAt) !== ''
                        ? getDayForRow(activity.occurredAt) + (activity.occurredAt | date : ', h:mm:ss a')
                        : (activity.occurredAt | date : 'MMM d, h:mm:ss a')
                    }}
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
          </ng-scrollbar>
        </div>
      </div>
    </div>
  </div>
  <canvas #myCanvas width="400" height="400"></canvas>
</div>
