import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from './base-api.service';
import { Observable } from 'rxjs';
import { ITransport } from '@core/models/transports/transportations.model';
import {
  GalleryCategory,
  IBrandIdentity,
  IBreakfastUpdate,
  ICheckInOut,
  IHotelGuide,
  IHotelMap,
  IWidgetCaption,
} from '@core/models/theme-settings/theme-settings.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeSettingsService extends BaseAPIService {
  constructor(http: HttpClient) {
    super(http);
  }

  getThemeSettings(subscriptionId: string): any {
    return this.get(`theme/${subscriptionId}`);
  }
  updateBrandIdentity(brandIdentityItem: IBrandIdentity): any {
    return this.put(`theme/brand-identity`, brandIdentityItem);
  }
  updateWidgetCaptions(widgetCaption: IWidgetCaption): any {
    return this.put(`theme/widget-captions`, widgetCaption);
  }
  uploadFileToCloudStorage(file: FormData): any {
    return this.postMultipart(`cloud-storage/uploads`, file);
  }
  updateBreakfast(breakfast: IBreakfastUpdate): any {
    return this.put(`theme/breakfast`, breakfast);
  }
  updateHotelGuide(hotelGuide: IHotelGuide) {
    return this.put(`theme/hotel-guide`, hotelGuide);
  }
  updateHotelMap(hotelMap: IHotelMap) {
    return this.put(`theme/hotel-map`, hotelMap);
  }
  updateHotelTransportation(transport: ITransport) {
    return this.put(`theme/transport`, transport);
  }

  updateCheckIn(checkIn: ICheckInOut) {
    return this.put(`theme/check-in`, checkIn);
  }
  updateCheckOut(checkOut: ICheckInOut) {
    return this.put(`theme/check-out`, checkOut);
  }
  deleteHotelGalleryFile(blobId: string, subscriptionId: string) {
    return this.delete(`cloud-storage/${blobId}?subscriptionId=${subscriptionId}`);
  }
  deleteHotelGalleryFiles(id: string) {
    return this.delete(`cloud-storage/delete-all/${id}`);
  }
  saveGalleries(subscriptionId: string, galleries: Array<GalleryCategory>) {
    return this.put(`theme/gallery/${subscriptionId}`, galleries);
  }
  getGallery(subscriptionId: string) {
    return this.get(`theme/gallery/${subscriptionId}`);
  }
  deleteGalleryItem(blobId: string, subscriptionId: string) {
    return this.delete(`theme/gallery/${blobId}?subscriptionId=${subscriptionId}`);
  }
  updateBackgroundImage(backgroundImage: any): any {
    return this.put(`theme/background-image`, backgroundImage);
  }
  getQRCodeBrandIdentity(subscriptionId: string): Observable<Blob> {
    return this.getBlob(`theme/mobile/preview/${subscriptionId}/brand-identity`);
  }
  getQRCodeHousekeeping(subscriptionId: string): Observable<Blob> {
    return this.getBlob(`theme/mobile/preview/${subscriptionId}/housekeeping`);
  }
  getQRCodeBreakfast(subscriptionId: string, type: string): Observable<Blob> {
    return this.getBlob(`theme/mobile/preview/${subscriptionId}/breakfast?type=${type}`);
  }
  getQRCodeGallery(subscriptionId: string): Observable<Blob> {
    return this.getBlob(`theme/mobile/preview/${subscriptionId}/gallery`);
  }
  getQRCodeCheckin(subscriptionId: string): Observable<Blob> {
    return this.getBlob(`theme/mobile/preview/${subscriptionId}/checkin`);
  }
  getQRCodeCheckout(subscriptionId: string): Observable<Blob> {
    return this.getBlob(`theme/mobile/preview/${subscriptionId}/checkout`);
  }
  getQRCodeMap(subscriptionId: string): Observable<Blob> {
    return this.getBlob(`theme/mobile/preview/${subscriptionId}/map`);
  }
}
