<div class="container-fluid mystay-preview-mobile">
    <div class="mystay-container-preview-mobile">
        <div class="mystay-preview-mobile-inside">
            <h3>{{'preview' | translate}}</h3>
            <br />
            <div class="mystay-preview-mobile-image"></div>
            <div class="mystay-preview-mobile-background-gradient"></div>
            <div class="mystay-preview-mobile">
                <div class="transport-title">
                    {{'transport' | translate}}
                </div>
                <div class="transport-container">
                    <div class="title">{{'welcome' | translate}}</div>
                    <div class="captions">{{transport.transportMessage}}</div>
                    <div class="arrow-down">
                        <div></div>
                    </div>
                    <div class="pick-me-up">
                        <button>{{'transportPickMeUp' | translate}}</button>
                    </div>
                </div>
            </div>
            <app-mobile-navigator-bar></app-mobile-navigator-bar>
        </div>
    </div>
</div>