<!-- <p style="text-align: center;" [innerHtml]="checkInOut.checkInMessages"> </p> -->
<div class="container-fluid mystay-preview-mobile-check-in-out">
    <div class="mystay-container-preview-mobile-check-in-out">
        <div class="header-preview">
            <h3 class="text">{{'preview' | translate}}</h3>
            <button type="button" class="mystay-button-qrcode" color="primary"
                (click)="this.qrScanPreviewClick.next($event)">
                <i class="fa fa-qrcode" aria-hidden="true"></i>
            </button>
        </div>
        <div class="mystay-preview-mobile-inside-check-in-out">
            <div class="check-in-out-preview-mobile-header"
                [ngStyle]="{'background-image': 'url(' + backgroundImage + ') '} ">
                <div class="check-in-out-preview-top-nav">
                    <div class="clock">{{clockOnMobile}}</div>
                    <div class="signal-mobile">
                        <span><i class="fas fa-signal"></i></span>
                        <span><i class="fas fa-wifi"></i></span>
                        <span><i class="fas fa-battery-full"></i></span>
                    </div>
                </div>
            </div>
            <div class="check-in-out-preview-top-arrow">
                <div class="arrow"></div>
            </div>
            <div class="check-in-out-preview-mobile-header-image" *ngIf="titleMessage === 'check-in'">
                <div class="text">Pre-Checkin</div>
                <div class="icon-checkin"></div>
            </div>
            <div class="check-in-out-preview-mobile-header-image" *ngIf="titleMessage === 'check-out'">
                <div class="text">Checkout</div>
                <div class="icon-checkout"></div>
            </div>
            <div class="check-in-out-preview-mobile-body" *ngIf="titleMessage === 'check-in'">
                <div class="intro">
                    <div class="title"></div>
                    <div class="body">Pre-Checkin completed</div>
                    <div class="footer">
                        <p class="paragraph" [innerHtml]="messages"> </p>
                    </div>
                </div>
                <div class="check-in-out-direct-homepage">
                    <div class="messages">
                        <div class="icon"></div>
                        <div class="text">
                            <u>Homepage</u>
                        </div>
                    </div>
                </div>
            </div>
            <div class="check-in-out-preview-mobile-body-out" *ngIf="titleMessage === 'check-out'">
                <div class="intro">
                    <div class="title"></div>
                    <div class="body">Checkout completed</div>
                    <div class="footer">
                        <p style="text-align: center;" [innerHtml]="messages"> </p>
                    </div>
                </div>
                <div class="check-in-out-direct-homepage">
                    <div class="messages">
                        <div class="question-rating">
                            What did you think about your stay?
                        </div>
                        <div class="question-rating-stars"> </div>
                    </div>
                </div>
                <div class="button-rate-your-stay">
                    <div>
                        Rate your stay
                    </div>
                </div>
            </div>
            <div class="mystay-preview-mobile-check-in-out-cover"> </div>
        </div>
    </div>

</div>