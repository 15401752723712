import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-mystay-success-notification',
  templateUrl: './mystay-success-notification.html',
  styleUrls: ['./mystay-success-notification.scss'],
})
export class MyStaySuccessNotificationComponent implements OnInit, OnDestroy {
  @Input() reservationId: string;
  @Input() message: string;
  @Input() subscriptionId: string;
  @Output() dismissed = new EventEmitter<void>();

  animationState: 'show' | 'hide' = 'show';
  private autoCloseTimer: any;

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.setAutoCloseTimer();
  }

  ngOnDestroy() {
    this.clearAutoCloseTimer();
  }

  copyLink() {
    const reservationLink = `${window.location.origin}/reservations/${this.reservationId}`;
    navigator.clipboard.writeText(reservationLink).then(() => {
      this.snackBar.open('Link copied to clipboard', 'Close', { duration: 2000 });
    });
  }

  dismissAfter300() {
    setTimeout(() => {
      this.dismiss();
    }, 300);
  }

  dismiss() {
    this.clearAutoCloseTimer();
    this.animationState = 'hide';
    setTimeout(() => {
      this.dismissed.emit();
    }, 300);
  }

  private setAutoCloseTimer() {
    this.autoCloseTimer = setTimeout(() => {
      this.dismiss();
    }, 15000);
  }

  private clearAutoCloseTimer() {
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer);
    }
  }
}