import { Direction } from '@angular/cdk/bidi';
import { AfterViewInit, Component, Inject,  Renderer2 } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { AuthService, DirectionService, InConfiguration } from '@core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})
export class MainLayoutComponent implements AfterViewInit {
  direction!: Direction;
  public config!: InConfiguration;
  constructor(
    private directoryService: DirectionService,
    private configService: ConfigService,
    private authService : AuthService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.config = this.configService.configData;
  }
  
  ngAfterViewInit(): void {
   
    if(this.authService?.userIdentity?.uiTheme == 1){

      this.renderer.addClass(this.document.body,'submenu-closed');
      this.renderer.addClass(this.document.body,'menu_dark');
      this.renderer.addClass(this.document.body,'logo-black');
      this.renderer.addClass(this.document.body,'theme-black');
      this.renderer.addClass(this.document.body,'dark');
      this.renderer.removeClass(this.document.body, 'light');
      this.renderer.removeClass(this.document.body, 'logo-white');
     
    } else {
      this.renderer.addClass(this.document.body,'light');
      this.renderer.addClass(this.document.body,'logo-white');
      this.renderer.addClass(this.document.body,'theme-white');

      //this.renderer.removeClass(this.document.body,'submenu-closed');
      this.renderer.removeClass(this.document.body,'menu_dark');
      this.renderer.removeClass(this.document.body,'logo-black');
      this.renderer.removeClass(this.document.body,'theme-black');
      this.renderer.removeClass(this.document.body, 'dark');
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader') as string
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }
  }
}
