import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';

@Component({
  selector: 'app-hotel-google-map',
  templateUrl: './hotel-google-map.component.html',
  styleUrls: ['./hotel-google-map.component.scss']
})
export class HotelGoogleMapComponent {
  apiLoaded: Observable<boolean>;

  constructor(httpClient: HttpClient) {
    console.log("apiLoaded",this.apiLoaded);
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyCqFOwAF93L9l-c5KiCgRmxYAVRULZRe-Y&libraries=places', 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        );
  }
}
