import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

export const hasSome = (arr1: any[], arr2: any[]) => {
  const contains = arr1?.some(element => {
    return arr2?.includes(element);
  });

  return contains;
};

export const updateUrlParams = (route: ActivatedRoute, router: Router, params: any) => {
  router.navigate([], {
    relativeTo: route,
    queryParams: params,
    queryParamsHandling: 'merge',
  });
};

export const exportTableToPDF = (tableId : string, titleTable: string, filename: string, filterTypeOnFooter : string, filterValueOnFooter) => {
  const report:any = new jsPDF({
    unit: "pt",
    format: "a4"
  });
  const refExportExcel = document.getElementById(tableId);
  report.text(35, 30, titleTable);

  // // Header
  // report.setFontSize(9);
  // report.setTextColor(40);
  // report.text("Date: 2024-08-23", 490, 20);

  // Footer
  const date = filterTypeOnFooter + ": " + filterValueOnFooter;
  report.setFontSize(10);
  const pageSizeDate = report.internal.pageSize;
  const pageHeightDate = pageSizeDate.height
    ? pageSizeDate.height
    : pageSizeDate.getHeight();
    report.text(date, 470, pageHeightDate - 10);

  const str = "Page " + report.internal.getNumberOfPages();
  report.setFontSize(10);
  const pageSize = report.internal.pageSize;
  const pageHeight = pageSize.height
    ? pageSize.height
    : pageSize.getHeight();
    report.text(str, 35, pageHeight - 10);


  
  report.autoTable({
    html: refExportExcel,
    margin: [0, 0, 0, 0],
  });

  console.log("pageHeight", report);
  report.save(`${filename}.pdf`);
}

export const  getLastMonthsOfYear = () => {
  const monthName = new Array(
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  );
  const months = [];
  const d = new Date();
  d.setDate(1);
  for (let i = 0; i <= 12; i++) {
    let month = '';
    if (d.getMonth() < 10) {
      month = '0' + (d.getMonth() + 1).toString();
    } else {
      month = (d.getMonth() + 1).toString();
    }
    months.push({
      value: d.getFullYear().toString() + '-' + month + '-01',
      text: monthName[d.getMonth()] + ' ' + d.getFullYear(),
    });
    d.setMonth(d.getMonth() - 1);
  }
  return months;
}

export const get6YearsPervious = () => {
  const d = new Date();
  const pastYear = d.getFullYear();
  const years = [];
  for (let i = 2020; i <= pastYear; i++) {
    years.push(i);
  }
  return years;
}



