import { Component, OnInit } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Subscription, timer } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading = false;
  currentUrl: string = '';
  private loadingTimerSubscription: Subscription | null = null;

  constructor(public _router: Router) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.startLoadingTimer();
        this.currentUrl = routerEvent.url.substring(routerEvent.url.lastIndexOf('/') + 1);
      }
      if (routerEvent instanceof NavigationEnd || 
          routerEvent instanceof NavigationCancel || 
          routerEvent instanceof NavigationError) {
        this.stopLoadingTimer();
        this.loading = false;
      }
      window.scrollTo(0, 0);
    });
  }
  startLoadingTimer() {
    this.loadingTimerSubscription = timer(300).subscribe(() => {
      this.loading = true;
    });
  }
  stopLoadingTimer() {
    setTimeout(() => {
      this.loading = false;
    }, 300);
    if (this.loadingTimerSubscription) {
      this.loadingTimerSubscription.unsubscribe();
      this.loadingTimerSubscription = null;
    }
  }

  ngOnDestroy() {
    this.stopLoadingTimer();
  }

  ngOnInit(): void {}
}
