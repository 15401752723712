import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { WidgetCaptionsRoutingModule } from './widget-captions-routing.module'
import { NgxMatColorPickerModule } from '@angular-material-components/color-picker'
import { NgxFileDropModule } from 'ngx-file-drop'
import { WidgetCaptionsComponent } from './widget-captions.component'
import { PreviewMobileDeviceModule } from '@shared/components/preview-mobile-devices/preview-mobile-device/preview-mobile-device.module'
import { ComponentsModule } from '@shared/components/components.module'
import { SharedModule } from '@shared'
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [WidgetCaptionsComponent],
  imports: [
    CommonModule,
    WidgetCaptionsRoutingModule,
    NgxMatColorPickerModule,
    TranslateModule,
    NgxFileDropModule,
    PreviewMobileDeviceModule,
    ComponentsModule,
    SharedModule
  ],
  exports: [WidgetCaptionsComponent]
})
export class WidgetCaptionsModule {}
