import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewMobileTransportRoutingModule } from './preview-mobile-transport-routing.module';
import { PreviewMobileTransportComponent } from './preview-mobile-transport.component';
import { MobileNavigatorBarModule } from '../mobile-navigator-bar/mobile-navigator-bar.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PreviewMobileTransportComponent
  ],
  imports: [
    CommonModule,
    PreviewMobileTransportRoutingModule,
    TranslateModule,
    MobileNavigatorBarModule
  ],
  exports:[PreviewMobileTransportComponent]
})
export class PreviewMobileTransportModule { }
