import { animate, state, style, transition, trigger } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { IGallery, IGalleryItem, IReservationImage } from '@core/interfaces';
import { GalleryCategory, subscriptionImage } from '@core/interfaces/Igallery';
import { ThemeSettingsService } from '@core/services/theme-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { Observable, Subscription, forkJoin, map, startWith } from 'rxjs';

interface ImageData {
  blobId: string;
  imageUrl: string;
  caption: string;
  editing?: boolean;
  sequence?: number;
}
@Component({
  selector: 'app-gallery-form',
  templateUrl: './gallery-form.component.html',
  styleUrls: ['./gallery-form.component.scss'],
  animations: [
    trigger('slideToggle', [
      state(
        'collapsed',
        style({
          height: '0',
          opacity: '0',
          overflow: 'hidden',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('expanded <=> collapsed', animate('300ms ease-in-out')),
    ]),
  ],
})
export class GalleryFormComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() galleryIndex: number;
  @Input() subscriptionId: string;

  //Gun - Dev
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('dragDropMessage') dragDropMessage: ElementRef;
  @Input() subscriptionImage: subscriptionImage[] = [];
  @Input() isLoading: boolean = false;
  @Input() hotelCategory: GalleryCategory;
  @Input() index: number;
  @Output() deleteRequest = new EventEmitter<number>();
  @Output() categoryChange: EventEmitter<GalleryCategory> = new EventEmitter<GalleryCategory>();
  @Output() isValid = new EventEmitter<boolean>();

  galleryImages: ImageData[] = [];
  isDragOver = false;
  dragEnterCounter = 0;
  isCollapsed: boolean = false;
  showValidationMessage: boolean = false;
  tagError: boolean = false; 
  galleryCaptionControl = new FormControl('', [Validators.required, Validators.maxLength(200)]);

  constructor(
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    super();
    this.filteredGalleryTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice()))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    this.getSubscriptionId = this.subscriptionId;
    this.tags = this.hotelCategory.tags ? this.hotelCategory.tags.split(',') : [];
    this.galleryImages = this.hotelCategory.galleryCategoryImagesDTOs.map(imageDTO => ({
      blobId: imageDTO.blobId,
      imageUrl: imageDTO.imageUrl,
      caption: imageDTO.caption,
      sequence: imageDTO.sequence,
    }));
    this.galleryCaptionControl.setValue(this.hotelCategory.description || '');
  }

  //Tags Var
  tags: string[] = [];
  tagCtrl = new UntypedFormControl();
  tagsInput?: ElementRef<HTMLInputElement>;
  allTags: string[] = [];

  //Other Vay
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredGalleryTags: Observable<string[]>;
  isUpdateGalleryCaption: boolean = false;
  getSubscriptionId: string;

  changeModeEditGalleryCaption(isUpdate) {
    this.isUpdateGalleryCaption = isUpdate;
  }

  //Tags Method
  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    this.updateCategory();
  }
  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    this.tagError = false;

    if (value) {
      if (value.length <= 50) {
        this.tags.push(value);
        if (input) {
          input.value = '';
        }
        this.tagCtrl.setValue(null);
        this.updateCategory();
      } else {
        this.tagError = true;
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    if (this.tagsInput) {
      this.tagsInput.nativeElement.value = '';
    }
    this.tagCtrl.setValue(null);
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  updateGalleryDescription(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.hotelCategory.description = value;
    this.updateInputs();
  }

  updateInputs(): void {
    this.isValid.emit(!!this.hotelCategory.title);
    this.updateCategory();
  }

  triggerValidation(): void {
    this.showValidationMessage = !this.hotelCategory.title;
  }

  onDelete() {
    this.deleteRequest.emit(this.index);
  }

  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragEnter(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dragEnterCounter++;
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dragEnterCounter--;
    if (this.dragEnterCounter === 0) {
      this.isDragOver = false;
    }
  }

  onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dragEnterCounter = 0;
    this.isDragOver = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.processFiles(files);
    }
  }

  openFilePicker() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let files = element.files;
    if (files && files.length > 0) {
      this.processFiles(files);
    }
  }

  processFiles(files: FileList): void {
    let newFilePreviews = [];
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      const fileType = file.type.split('/')[1] === 'pdf' ? 'pdf' : 'image';
      const url = fileType === 'pdf' ? 'path/to/pdf-icon.png' : URL.createObjectURL(file);
      newFilePreviews.push({ file, url, fileType });
    }
    this.uploadImages(newFilePreviews);
  }

  async uploadImages(newFilePreviews: any[]) {
    let uploadCounter = 0;
    const totalFiles = newFilePreviews.length;
    this.isLoading = true;

    for (const preview of newFilePreviews) {
      try {
        const formData = new FormData();
        formData.append('file', preview.file);
        formData.append('folder', 'gallerys');
        formData.append('Restrict', 'false');
        formData.append('SubscriptionId', this.subscriptionId);

        const response = await this.themeSettingsService.uploadFileToCloudStorage(formData).toPromise();
        const sequence = this.galleryImages.length;

        this.galleryImages.push({
          imageUrl: response.imageUrl,
          blobId: response.blobId,
          caption: '[CAPTION]',
          sequence: sequence,
        });

        uploadCounter++;
        if (uploadCounter === totalFiles) {
          console.log(`${totalFiles} files uploaded successfully.`);
        }
        this.isLoading = false;
        this.updateCategory();
      } catch (error) {
        console.error(`Error uploading file:`, error);
        this.isLoading = false;
        break;
      }
    }
  }

  requestDeleteImage(blobId: string) {
    this.isLoading = true;

    const deleteGalleryItem$ = this.themeSettingsService.deleteGalleryItem(blobId , this.subscriptionId);
    const deleteHotelGalleryFile$ = this.themeSettingsService.deleteHotelGalleryFile(blobId , this.subscriptionId);

    this.subs.sink = forkJoin([deleteGalleryItem$, deleteHotelGalleryFile$]).subscribe({
      next: () => {
        this.galleryImages = this.galleryImages.filter(image => image.blobId !== blobId);
        this.updateCategory();
        this.isLoading = false;
      },
      error: error => {
        console.error('Error deleting image:', error);
        this.isLoading = false;
      },
    });
  }

  getFileName(url: string, maxLength: number = 20): string {
    const name = url.split('/').pop();
    if (!name) return '';

    if (name.length <= maxLength) return name;

    const extension = name.substring(name.lastIndexOf('.') + 1);
    const fileNameWithoutExtension = name.substring(0, name.lastIndexOf('.'));

    if (maxLength < extension.length + 3) return name;

    const charsToShow = maxLength - extension.length - 3;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      fileNameWithoutExtension.substring(0, frontChars) +
      '...' +
      fileNameWithoutExtension.substring(fileNameWithoutExtension.length - backChars) +
      '.' +
      extension
    );
  }

  updateCategory() {
    this.hotelCategory.galleryCategoryImagesDTOs = this.galleryImages.map(image => ({
      galleryId: this.hotelCategory.id,
      caption: image.caption,
      blobId: image.blobId,
      sequence: image.sequence,
      imageUrl: image.imageUrl,
    }));
    this.hotelCategory.tags = this.tags.join(',');
    this.hotelCategory.enabled = true;
    this.categoryChange.emit(this.hotelCategory);
  }

  editCaption(image: ImageData): void {
    this.galleryImages.forEach(img => (img.editing = false));
    image.editing = true;
  }

  saveCaption(image: ImageData): void {
    image.editing = false;
    this.updateCategory();
  }
}
