import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HotelGuideRoutingModule } from './hotel-guide-routing.module';
import { ComponentsModule } from '@shared/components/components.module';
import { HotelGuideComponent } from './hotel-guide.component';
import { PreviewMobileHotelGuideModule } from '@shared/components/preview-mobile-devices/preview-mobile-hotel-guide/preview-mobile-hotel-guide.module';
import { SharedModule } from '@shared';
import { TranslateModule } from '@ngx-translate/core'; 

@NgModule({
  declarations: [HotelGuideComponent],
  imports: [
    CommonModule,
    HotelGuideRoutingModule,
    ComponentsModule,
    SharedModule,
    TranslateModule,
    PreviewMobileHotelGuideModule
  ],
  exports:[HotelGuideComponent]
})
export class HotelGuideModule { }
