<div class="container-fluid mystay-preview-mobile-gallery">
  <div class="mystay-container-preview-mobile-gallery">
    <div class="header-preview">
      <h3>{{'preview' | translate}}</h3>
      <button type="button" class="mystay-button-qrcode" color="primary" (click)="this.qrScanPreviewClick.next($event)">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
      </button>
    </div>
    <div class="mystay-preview-mobile-inside-gallery">
      <div class="mystay-preview-mobile-image-gallery">
        <!-- Hard code for example -->
        <img [src]="backgroundUrl" alt="">
        <div class="overlay-text">Gallery</div>
        <mat-icon class="overlay-icon">filter</mat-icon>
      </div>
      <div class="mystay-preview-mobile-background-gradient-gallery"></div>
      <div class="mystay-preview-mobile-content-gallery">
        <div class="scroll-overlay"></div>
        <div class="container">
          <div *ngFor="let category of galleryCategories" class="content-container">
            <div class="header">{{ category.title }}</div>
            <div class="content">
              <div class="open-hours">
                <span class="status-dot" [class.enabled]="category.enabled"></span>
                <div class="open-hours-text">{{ category.openHours }}</div>
              </div>
              <div class="image-gallery">
                <img *ngIf="category.galleryCategoryImagesDTOs.length > 0"
                  [src]="category.galleryCategoryImagesDTOs[0].imageUrl" class="image big-image"
                  [alt]="category.galleryCategoryImagesDTOs[0].caption" />
                <div class="small-images-container">
                  <ng-container *ngFor="let image of category.galleryCategoryImagesDTOs; let i = index">
                    <img *ngIf="i > 0 && i < 3" [src]="image.imageUrl" class="image small-image"
                      [alt]="image.caption" />
                    <ng-container *ngIf="i === 3">
                      <img *ngIf="category.galleryCategoryImagesDTOs.length === 4" [src]="image.imageUrl"
                        class="image small-image" [alt]="image.caption" />
                      <div *ngIf="category.galleryCategoryImagesDTOs.length > 4" class="image small-image overlay"
                        [style.backgroundImage]="'url(' + image.imageUrl + ')'">
                        <span class="more-images">+{{ category.galleryCategoryImagesDTOs.length - 3 }} more</span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="gallery-description">{{ category.description }}</div>
              <div class="tags" *ngIf="category.tags">
                <span *ngFor="let tag of category.tags.split(',')" class="tag">
                  <mat-icon>task_alt</mat-icon>{{ tag.trim() }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mystay-preview-mobile-gallery"></div>
    </div>
  </div>
</div>