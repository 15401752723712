import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform } from '@angular/core'
import { IBreakfast } from '@core/interfaces'
import { Observable } from 'rxjs'
import { DomSanitizer } from '@angular/platform-browser'
import {decode} from 'html-entities';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-preview-mobile-breakfast',
  templateUrl: './preview-mobile-breakfast.component.html',
  styleUrls: ['./preview-mobile-breakfast.component.scss']
})
export class PreviewMobileBreakfastComponent implements OnInit {

  @Input() subscribeBreakfastReceiver: Observable<IBreakfast> = null
  @Input() isIncluded : boolean;
  @Output() qrScanPreviewClick : EventEmitter<any> = new EventEmitter();
  @Input() subscribeBreakfastContentReceiver : Observable<any> =  null;

  public isBreakfastContent: boolean ;
  public headerImg : string;
  public qrCode : string = "../../../../assets/custom/images/qrcode-simple.png";
  public backgroundImage : string = "../../../../../assets/custom/images/restaurant_counter.jpg";
  public breakfastList : Array<any> = [];
  

  public breakfast: IBreakfast = {
    headerImage: '',
    headerImageUrl: this.backgroundImage,
    description : "",
    notification:  "",
  }

  public get clockOnMobile () {
    const datePipe = new DatePipe('en-US');
    return  datePipe.transform(Date.now(), 'H:mm');
  }
  ngOnInit (): void {
    this.subscribeBreakfastReceiver?.subscribe(result => {
      this.breakfast.headerImageUrl = result.headerImageUrl === ""? this.breakfast.headerImageUrl :  result.headerImageUrl 
      this.breakfast.description = result.description  === "" ? decode(this.breakfast.description) : decode(result.description);
      this.breakfast.notification = result.notification === "" ? this.breakfast.notification : result.notification;
    });
    this.subscribeBreakfastContentReceiver?.subscribe(result =>{
      this.breakfastList = result;
    })
  }
}
