<div class="row">
    <div class="col-4" *ngFor="let gallery of galleryItem.controls let galleryIndex=index">
        <div *ngIf="gallery.value.isUploaded">
            <div class="col-12 image-cover" [ngStyle]="{'background':'url(' + gallery.value.imageUrl    + ')' }">
                <button mat-icon-button (click)="deleteGalleryItem(  gallery.value.blobId )">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
            <div class="col-12 image-caption-container">
                <div class="input-image-caption">
                    <input class="form-control" type="text" placeholder="Image caption" />
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!gallery.value.isUploaded" (click)="selectImage (galleryIndex)">
            <div class="col-12 image-upload">
                <div class="image-upload-icon">
                    <mat-icon class="upload-file">upload_file</mat-icon>
                </div>
                <div class="image-upload-title">
                    <input hidden #f_inputImage id="select-file" type="file" style="display :none"
                        accept="image/png, image/jpeg, image/gif, image/jpg"
                        (change)="handleFileInputChange($event, f_inputImage.files)">
                    <span class="image-upload-title">Select a
                        file</span>
                </div>
            </div>
            <!-- <div class="col-12 image-caption-container">
                <div class="input-image-caption">
                    <input class="form-control" type="text" placeholder="Image caption">
                </div>
            </div> -->
        </div>
        </div>
</div>