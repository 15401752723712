import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface BreadCrumb {
  label: string;
  url: string;
  level: number;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbs$ = new BehaviorSubject<BreadCrumb[]>([]);

  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const root = this.router.routerState.snapshot.root;
      const breadcrumbs = this.createBreadcrumbs(root);
      this.breadcrumbs$.next(breadcrumbs);
    });
  }

  get breadcrumbs(): Observable<BreadCrumb[]> {
    return this.breadcrumbs$.asObservable();
  }

  private createBreadcrumbs(
    route: ActivatedRouteSnapshot,
    url: string = '',
    breadcrumbs: BreadCrumb[] = []
  ): BreadCrumb[] {
    const children: ActivatedRouteSnapshot[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeConfig = child.routeConfig;

      if (routeConfig) {
        let routeURL = routeConfig.path || '';

        if (routeURL.includes(':')) {
          Object.keys(child.params).forEach(paramKey => {
            routeURL = routeURL.replace(`:${paramKey}`, child.params[paramKey]);
          });
        }

        if (routeURL) {
          url += `/${routeURL}`;
        }

        const label = routeConfig.data ? routeConfig.data['breadcrumb'] : null;
        const level = routeConfig.data ? routeConfig.data['level'] : null;

        if (label) {
          const breadcrumb: BreadCrumb = {
            label: label,
            url: url,
            level: level,
          };

          if (level === 1) {
            const subscriptionId = this.getRouteParameter(child, 'subscriptionId');
            breadcrumbs.length = 0;
            breadcrumbs.push({
              label: 'Home',
              url: `/${subscriptionId}/dashboard`,
              level: 0,
            });
            breadcrumbs.push(breadcrumb);
          } else {
            breadcrumbs.push(breadcrumb);
          }
        }
      }

      breadcrumbs = this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  private getParentLabel(breadcrumbs: BreadCrumb[], level: number): string | null {
    const parentBreadcrumb = breadcrumbs.find(breadcrumb => breadcrumb.level === level - 1);
    return parentBreadcrumb ? parentBreadcrumb.label : null;
  }

  private getRouteParameter(route: ActivatedRouteSnapshot, paramName: string): string {
    let param = route.params[paramName];
    let parent = route.parent;
    while (!param && parent) {
      param = parent.params[paramName];
      parent = parent.parent;
    }
    return param;
  }
}
