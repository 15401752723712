<div class="breadcrumb-main">
    <div class="row">
        <div class="col-6">
            <!-- <div class="breadcrumb-title">
                <div class="breadcrumb-icon-container">
                    <i class="fas fa-home breadcrumb-icon-container-inner"></i>
                </div>
                <h4 style="display: inline-block;font-size: 24px;vertical-align: top;font-weight: 400;" class="page-title">{{title | translate}} 
                    <i class="far fa-question-circle" style="font-size: 22px;"></i></h4>
            </div> -->
            <div class="breadcrumb-title">
                <h4 style="display: inline-block;" class="page-title">{{title | translate}}</h4>
                <a (click)="openHelpDialog()"><i class="far fa-question-circle" style="font-size: 26px;margin-left:7px;opacity: 0.6;cursor: pointer;"></i></a>
            </div>
        </div>
        <div class="col-6" *ngIf="showBreadcrumbs">
            <ul class="breadcrumb-list">
                <li class="breadcrumb-item bcrumb-1">
                    <a href="#" (click)="$event.preventDefault()">
                        <span class="fas fa-home"></span>
                    </a>
                </li>
                <li class="breadcrumb-item" *ngFor="let item of items">{{item | translate}}</li>
                <li class="breadcrumb-item active">{{active_item | translate}}</li>
            </ul>
        </div>
    </div>
</div>

<!-- fas fa-home -->