<div class="Container">
  <mat-dialog-content class="qr-code-content">
    <h2 class="qr-code-title">{{ qrCodeTitle ? qrCodeTitle : 'Guest App QR Code' }}</h2>
    <div class="qr-code-container">
      <img *ngIf="qrCodeImageUrl" [src]="qrCodeImageUrl" alt="QR Code" />
    </div>
    <div class="qr-code-action">
      <!-- <button mat-button mat-dialog-close>{{'close' | translate}}</button>  -->
      <div class="mystay-button-container form-actions">
        <ul>
          <li> <button mat-button mat-dialog-close class="mystay-button-submit button-print-qrcode"
              (click)="printQRCode(roomNumber, guestFullName, bookingNumber, qrCodeImage, todayDate)">
              <mat-icon class="print-qrcode-icon">print</mat-icon> </button> 
          </li>
          <li style="margin: 12px 0 0px 0;">
            <button mat-button mat-dialog-close class="mystay-button-submit">{{ 'close' | translate }}</button>
          </li>
        </ul>
      </div>
    </div>
  </mat-dialog-content>
</div>