import { AfterContentChecked, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThemeSettingsService } from '@core/services/theme-settings.service';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { GalleryFormComponent } from './gallery-form/gallery-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { QRCodeComponent } from 'app/modules/reservations/details/dialogs/qrcode/qrcode.component';
import {
  Gallery,
  GalleryCategory,
  IGalleryItem,
  IThemeSettings,
} from '@core/models/theme-settings/theme-settings.model';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterContentChecked {
  @ViewChildren(GalleryFormComponent) galleryForms: QueryList<GalleryFormComponent>;

  public gallery: Gallery;
  galleryCategories: GalleryCategory[] = [];
  isFormValid: boolean = true;
  backgroundUrl: string = '';
  subscription: Subscription;

  constructor(
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {
    super();
  }

  public subscribeGalleryEvent: Subject<Array<IGalleryItem>> = new Subject();
  isUpload: boolean = false;
  subscriptionId: string;
  public file_store: FileList;
  headerImageUrl = this.backgroundUrl.split('/').pop();
  folderBackground: string = 'background';

  galleryGroupForm = new FormGroup({
    backgroundUploaded: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, this.fileTypeValidator(['image/jpeg', 'image/png'])],
    }),
    headerImageName: new FormControl(this.headerImageUrl, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  ngOnInit(): void {
    this.route.paramMap.subscribe(async paramMap => {
      this.subscriptionId = paramMap.get('subscriptionId');
      await this.loadGallery(this.subscriptionId);
      this.emitGalleryItems();
    });
  }
  ngAfterContentChecked(): void {
    if (this.galleryCategories.length == 0) {
      this.onAddGalleryCategory();
    }
  }

  async loadGallery(subscriptionId: string) {
    firstValueFrom(this.themeSettingsService.getThemeSettings(subscriptionId)).then((response: IThemeSettings) => {
      this.backgroundUrl = response.appBackgroundImages.galleryBackgroundUrl;
      this.galleryGroupForm.controls.headerImageName.patchValue(
        this.getNameUrl(
          response.appBackgroundImages.galleryBackgroundUrl.substring(
            response.appBackgroundImages.galleryBackgroundUrl.lastIndexOf('/') + 1,
            response.appBackgroundImages.galleryBackgroundUrl.length
          )
        )
      );
      firstValueFrom(this.themeSettingsService.getGallery(subscriptionId)).then((response: any) => {
        this.gallery = response;
        this.galleryCategories = response.galleryCategories.sort((a, b) => a.sequence - b.sequence);
        this.emitGalleryItems();
      });
    });
  }

  fileTypeValidator(allowedMimeTypes: string[]) {
    return (control: FormControl): { [key: string]: any } | null => {
      const file = control.value;
      if (file) {
        const isValidType = allowedMimeTypes.includes(file.type);
        return isValidType ? null : { invalidFileType: true };
      }
      return null;
    };
  }

  handleFileInputBackgroundChange(event: any, l: FileList): void {
    this.isUpload = true;
    this.file_store = l;
    if (l.length) {
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.galleryGroupForm.controls.headerImageName.patchValue(`${l[0].name}${count}`);
      if (this.galleryGroupForm.controls['backgroundUploaded']?.hasError('invalidFileType')) {
        if (event.target.files && event.target.files[0]) {
          const reader = new FileReader();
          this.route.paramMap.subscribe(paramMap => {
            this.subscriptionId = paramMap.get('subscriptionId');
            const formData: any = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('folder', this.folderBackground);
            formData.append('Restrict', 'false');
            formData.append('SubscriptionId', this.subscriptionId);

            const upload$ = this.themeSettingsService.uploadFileToCloudStorage(formData);
            this.subscription = upload$.subscribe(response => {
              this.backgroundUrl = response.imageUrl;
              this.galleryGroupForm.controls.headerImageName.patchValue(
                this.getNameUrl(
                  response.imageUrl.substring(response.imageUrl.lastIndexOf('/') + 1, response.imageUrl.length)
                )
              );
              this.isUpload = false;
              const mapping = {
                subscriptionId: this.subscriptionId,
                galleryBackgroundUrl: response.imageUrl,
                appBackground: 3,
              };
              const upload$1 = this.themeSettingsService.updateBackgroundImage(mapping);
              upload$1.subscribe(res => {
                res;
              });
            });
            this.isUpload = false;
          });

          reader.readAsDataURL(event.target.files[0]); // read file as data url
          let url: any;
          reader.onload = event => {
            url = event.target.result;
            this.backgroundUrl = url;
          };
        }
      }
    } else {
      this.galleryGroupForm.controls.headerImageName.patchValue('');
      this.isUpload = false;
    }
  }

  getNameUrl(fullUrl: string) {
    const url = fullUrl;
    const urlLength = url.lastIndexOf('?');
    const result = url.slice(0, urlLength);
    return result;
  }

  onAddGalleryCategory() {
    const sequence = this.galleryCategories.length;
    const newCategory = new GalleryCategory();
    newCategory.sequence = sequence;
    newCategory.title = `Section ${sequence + 1}`;
    newCategory.galleryCategoryImagesDTOs = [];
    this.galleryCategories.push(newCategory);
    this.emitGalleryItems();
  }

  onDeleteGalleryCategory(index: number) {
    this.galleryCategories.splice(index, 1);
    this.emitGalleryItems();
  }

  async onSaveGallery() {
    try {
      let isValid = true;
      this.galleryForms.forEach(form => {
        if (form.galleryCaptionControl.invalid) {
          isValid = false;
        }

        if (!form.hotelCategory.title) {
          form.triggerValidation();
          isValid = false;
        }
      });
      if (!isValid) {
        this.toastr.error(
          'Check for missing or invalid entries in all galleries before saving.',
          'Incomplete Details',
          { timeOut: 3500 }
        );
        return;
      }
      const galleries: GalleryCategory[] = this.galleryCategories.map((category, index) => ({
        id: category.id ? category.id : undefined,
        subscriptionId: this.subscriptionId,
        title: category.title,
        description: category.description,
        openHours: category.openHours,
        tags: category.tags,
        enabled: category.enabled,
        sequence: index,
        galleryCategoryImagesDTOs: category.galleryCategoryImagesDTOs.map(image => ({
          galleryId: image.galleryId,
          caption: image.caption,
          blobId: image.blobId,
          sequence: image.sequence,
          imageUrl: image.imageUrl,
        })),
      }));

      await firstValueFrom(this.themeSettingsService.saveGalleries(this.subscriptionId, galleries));
      this.toastr.success('Gallery saved successfully!', 'Operation Completed', { timeOut: 3500 });
      this.galleryForms.forEach(form => {
        form.triggerValidation();
        isValid = false;
      });
      this.emitGalleryItems();
    } catch (error) {
      console.log(error);
      this.toastr.error('Failed to save galleries.', 'Operation Failed', { timeOut: 3500 });
    }
  }

  handleCategoryChange(updatedCategory: GalleryCategory, index: number) {
    this.galleryCategories[index] = updatedCategory;
  }

  drop(event: CdkDragDrop<GalleryCategory[]>) {
    moveItemInArray(this.galleryCategories, event.previousIndex, event.currentIndex);
  }

  handleValidity(isValid: boolean): void {
    this.isFormValid = isValid;
  }

  emitGalleryItems(): void {
    this.subscribeGalleryEvent.next(this.galleryCategories);
  }

  openQRCodeDialog() {
    this.themeSettingsService.getQRCodeGallery(this.subscriptionId).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64data = reader.result;
        const dialogRef = this.dialog.open(QRCodeComponent, {
          data: {
            qrCodeImage: base64data,
            qrCodeTitle: 'QR-Code for preview',
          },
        });

        this.subs.sink = dialogRef.afterClosed().subscribe(res => {});
      };
    });
  }

  goToGooglePlayStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
  goToAppStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
}
