<app-header></app-header>
<app-sidebar></app-sidebar>
<app-right-sidebar></app-right-sidebar>
<app-quick-search-sidebar></app-quick-search-sidebar>
<div [dir]="direction">
  <router-outlet></router-outlet>
</div>
<br/><br/>
<!-- <app-footer></app-footer> -->

