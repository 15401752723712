<section>
    <div style="text-align: center;padding-top:20px;padding-bottom:20px">
        <h2>{{title | translate}}</h2>
        <button mat-icon-button class="modal-close-button" style="top:15px;right:20px" aria-label="Close dialog"
            (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-divider></mat-divider>
    <ng-container *ngIf="title == 'billing'">
        <app-billing-help-component></app-billing-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'dashboard'">
        <app-dashboard-help-component></app-dashboard-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'calendarView'">
        <app-calendar-help-component></app-calendar-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'reservationList'">
        <app-reservationlist-help-component></app-reservationlist-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'transport'">
        <app-transport-help-component></app-transport-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'Sticky Notes'">
        <app-reminders-help-component></app-reminders-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'guestChat'">
        <app-chat-help-component></app-chat-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'chat'">
        <app-chat-help-component></app-chat-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'housekeeping'">
        <app-housekeeping-help-component></app-housekeeping-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'settings'">
        <app-settings-help-component></app-settings-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'reports'">
        <app-reports-help-component></app-reports-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'Customers'">
        <app-customers-help-component></app-customers-help-component>
    </ng-container>
    <ng-container *ngIf="title == 'inventory'">
        <app-inventory-help-component></app-inventory-help-component>
    </ng-container>
</section>