<div class="notification" [class]="animationState" role="alert" aria-live="polite">
  <mat-icon class="icon success-icon" aria-label="Success">check_circle</mat-icon>
  <div class="content">
    <h2 class="title">{{ message }}</h2>
    <div class="actions">
      <a [routerLink]="[this.subscriptionId,'reservations', reservationId]" class="action-btn" (click)="dismissAfter300()">View reservation</a>
      <span class="separator">·</span>
      <button class="action-btn" (click)="copyLink()">Copy link</button>
    </div>
  </div>
  <button class="close-btn" (click)="dismiss()" aria-label="Dismiss">
    <mat-icon>close</mat-icon>
  </button>
</div>