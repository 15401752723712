import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { IGalleryItem } from '@core/interfaces'
import { GalleryCategory } from '@core/interfaces/Igallery'
import { NgImageSliderComponent } from 'ng-image-slider'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-preview-mobile-gallery',
  templateUrl: './preview-mobile-gallery.component.html',
  styleUrls: ['./preview-mobile-gallery.component.scss']
})
export class PreviewMobileGalleryComponent implements OnInit {
  @Input() subscribeGalleryReceiver: Observable<Array<IGalleryItem>> = null
  @Input() backgroundUrl : string = "";
  @Output() qrScanPreviewClick : EventEmitter<any> = new EventEmitter();
  @ViewChild('nav') slider: NgImageSliderComponent
  galleryCategories: GalleryCategory[] = [];
  imageObject: Array<object> = []
  clickSum: number = 0


  ngOnInit (): void {
    this.subscribeGalleryReceiver?.subscribe((galleryCategories: GalleryCategory[]) => {
      this.galleryCategories = galleryCategories;
    })
  }


  trackByFn(index, item) {
    return item.sequence; 
  }

  parseTags(tags: string): string[] {
    return tags.split(',').map(tag => tag.trim()); 
  }


  get imageCount (): number {
    return this.imageObject.length
  }

}
