<section class="content">
    <div class="content-block">
        <div class="block-header">
            <!-- breadcrumb -->
            <app-breadcrumb [title]="'guestApp'" [items]="['home', 'settings','guestApp']" [active_item]="'widgetCaption'"></app-breadcrumb>
        </div>

        <div class="mystay-container-content">
            <form #userLogin="ngForm" [formGroup]="widgetCaptionForm">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <app-title-content [title]="'widgetCaption'| translate"
                            [subTitle]="'widgetCaptionSubtitle'| translate "></app-title-content>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field class="mystay-input" appearance="outline">
                                        <mat-label>{{'breakfast' | translate}} {{'widget' | translate}}</mat-label>
                                        <input matInput formControlName="breakfast"
                                            (keyup)="onChangeBreakfastEvent($event)">
                                        <mat-error
                                            *ngIf="widgetCaptionForm.controls['breakfast']?.hasError('required')">
                                            {{'widgetBreakfastCaptionsIsRequired' | translate}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="widgetCaptionForm.controls['breakfast']?.hasError('minlength')">
                                            {{'widgetBreakfastCaptionsAtLeast4Char' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="mystay-input" appearance="outline">
                                        <mat-label>{{'gallery' | translate}} {{'widget' | translate}}</mat-label>
                                        <input matInput formControlName="gallery"
                                            (keyup)="onChangeGalleryEvent($event)">
                                        <mat-error *ngIf="widgetCaptionForm.controls['gallery']?.hasError('required')">
                                            {{'widgetGalleryCaptionsIsRequired' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="widgetCaptionForm.controls['gallery']?.hasError('minlength')">
                                            {{'widgetGalleryCaptionsAtLeast4Char' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="mystay-input" appearance="outline">
                                        <mat-label>{{'chat' | translate}} {{'widget' | translate}}</mat-label>
                                        <input matInput formControlName="chat" (keyup)="onChangeChatEvent($event)">
                                        <mat-error *ngIf="widgetCaptionForm.controls['chat']?.hasError('required')">
                                            {{'widgetChatCaptionsIsRequired' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="widgetCaptionForm.controls['chat']?.hasError('minlength')">
                                            {{'widgetChatCaptionsAtLeast4Char' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="mystay-input" appearance="outline">
                                        <mat-label>{{'call' | translate}} {{'widget' | translate}}</mat-label>
                                        <input matInput formControlName="call" (keyup)="onChangeCallEvent($event)">
                                        <mat-error *ngIf="widgetCaptionForm.controls['call']?.hasError('required')">
                                            {{'widgetCallCaptionsIsRequired' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="widgetCaptionForm.controls['call']?.hasError('minlength')">
                                            {{'widgetCallCaptionsAtLeast4Char' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="mystay-input" appearance="outline">
                                        <mat-label>{{'wifi' | translate}} {{'widget' | translate}}</mat-label>
                                        <input matInput formControlName="wifi" (keyup)="onChangeWifiEvent($event)">
                                        <mat-error *ngIf="widgetCaptionForm.controls['wifi']?.hasError('required')">
                                            {{'widgetWifiCaptionsIsRequired' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="widgetCaptionForm.controls['wifi']?.hasError('minlength')">
                                            {{'widgetWifiCaptionsAtLeast4Char' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="row">
                            <div class="col-12">
                                <app-preview-mobile-device
                                    [subscribeBrandIdentityReceiver]="subscribeBrandIdentityEvent.asObservable()"
                                    [subscribeWidgetReceiver]="subscribeWidgetEvent.asObservable()">
                                </app-preview-mobile-device>
                            </div>
                        </div>
                    </div>
                    <HR>
                    <div class="mystay-container-action-submit form-actions">
                        <button type="submit"  mat-raised-button color="primary" (click)="onSubmit()" [disabled]="isUpload">{{'save' | translate}}</button> 
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>