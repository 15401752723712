import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { GoogleMap } from '@angular/google-maps'
import { ActivatedRoute } from '@angular/router'
import { ThemeSettingsService } from '@core/services/theme-settings.service'
import { IHotelMap, IThemeSettings } from '@core/interfaces'
import { Subject, Subscription, firstValueFrom } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { MatDialog } from '@angular/material/dialog';
import { QRCodeComponent } from 'app/modules/reservations/details/dialogs/qrcode/qrcode.component';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-hotel-map',
  templateUrl: './hotel-map.component.html',
  styleUrls: ['./hotel-map.component.scss']
})
export class HotelMapComponent extends UnsubscribeOnDestroyAdapter   implements OnInit {
  constructor (
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate : TranslateService,
    private ngZone: NgZone,
    public dialog: MatDialog,
  ) {
    super()
  }
  subscription: Subscription
  public subscribeHotelMapEvent: Subject<IHotelMap> = new Subject();
  public subscribeHotelMapCoordinates: Subject<IHotelMap> = new Subject();
  subscriptionId: string
  hotelName: string
  hotelAddress: string

  captionsTitle: string =
    "Pinning and sharing the hotel's location on the map with your guests."
  @ViewChild('search') public searchElementRef!: ElementRef
  @ViewChild(GoogleMap) public map!: GoogleMap

  zoom = 14
  center!: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDefaultUI: true,
    fullscreenControl: true,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    maxZoom: 16,
    minZoom: 7
  }
  latitude!: any
  longitude!: any

  markers = []

  hotels: IHotelMap = {
    fullName: '',
    id: '',
    hotelLatitude: 0,
    hotelLongitude: 0,
    address: '',
    isUpdateXY: false
  }

  isUpload: boolean = false
  //getHotel = this.hotels.find((x: IHotelMap) => x.id === this.hotel)

  hotelMapGroupForm = new FormGroup({
    hotelAddress: new FormControl(this.hotels.address, {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(255)
      ]
    })
  })

  hotelMapSubmit: IHotelMap = null
  hotelLatitude: number = 0
  hotelLongitude: number = 0

  onSubmit () {
    if (this.hotelMapGroupForm.controls.hotelAddress.value !== '') {
      this.isUpload = true
      this.route.paramMap.subscribe(paramMap => {
        this.subscriptionId = paramMap.get('subscriptionId')
        const hotelSubmit: IHotelMap = {
          id: this.subscriptionId,
          address: this.hotelMapGroupForm.controls.hotelAddress.value,
          hotelLatitude: this.hotelLatitude,
          hotelLongitude: this.hotelLongitude
        }

        firstValueFrom(this.themeSettingsService.updateHotelMap(hotelSubmit))
          .then(() => {
            const successMessage = this.translate.instant('hotelMapHasBeenSaved'); 
            // this.snackbarService.alertSuccess(successMessage);
            this.toastr.success(successMessage, 'Operation Completed', {timeOut : 3500});
            this.isUpload = false
          })
          .catch(() => {
            const failedMessage = this.translate.instant('failedToSave'); 
            // this.snackbarService.alertError(failedMessage);
            this.toastr.error(failedMessage, 'Operation Failed', {timeOut : 3500});
            this.isUpload = false
          })
      })
    }
  }


  ngOnInit () {
    this.route.paramMap.subscribe(paramMap => {
      this.subscriptionId = paramMap.get('subscriptionId')
      firstValueFrom(
        this.themeSettingsService.getThemeSettings(this.subscriptionId)
      ).then((subscription: IThemeSettings) => {
        this.searchLocation();

        const hotel: IHotelMap = {
          fullName: subscription.name,
          id: subscription.id,
          hotelLatitude: subscription.contact.location.coordinate.latitude,
          hotelLongitude: subscription.contact.location.coordinate.longitude,
          address: subscription.contact.address,
          isUpdateXY: true
        }
        this.hotelLatitude = subscription.contact.location.coordinate.latitude;
        this.hotelLongitude = subscription.contact.location.coordinate.longitude;
        this.hotelName = subscription.name;
        this.hotelAddress = subscription.contact.address
        this.hotelMapGroupForm.controls.hotelAddress.patchValue(
          `${subscription.contact.address}`
        )
       

        navigator.geolocation?.getCurrentPosition(position => {
          if(this.hotelLatitude === 0 && this.hotelLongitude === 0){
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }

            this.addMarker(this.center.lat, this.center.lng)
          }else{
            this.center = {
              lat: this.hotelLatitude,
              lng: this.hotelLongitude
            }
            this.hotelLatitude = subscription.contact.location.coordinate.latitude,
        this.hotelLongitude = subscription.contact.location.coordinate.longitude,

            this.addMarker(this.center.lat, this.center.lng)
          }
          hotel.hotelLatitude = this.center.lat;
          hotel.hotelLongitude = this.center.lng;
          this.subscribeHotelMapCoordinates.next(hotel);
        })



      })
    })
  }

  searchLocation(){
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.zoom = 12;
        this.center = {
          lat: this.latitude,
          lng: this.longitude
        }
        this.addMarker(this.center.lat, this.center.lng)
      });
    });
  }

  addMarker (lat, lng) {
    this.markers = []
    this.markers.push({
      position: {
        lat: lat,
        lng: lng
      },
      title: 'Marker title ' + (this.markers.length + 1),
      options: {
        draggable: true,
        clickable: true,
        icon: '../../assets/custom/images/marker.png'
      }
    })
  }

  onDragend (event, getHotel: IHotelMap, hotelAddress: string) {
    this.hotelLatitude = event.latLng.lat()
    this.hotelLongitude = event.latLng.lng()
    this.addMarker(event.latLng.lat(), event.latLng.lng())

    this.hotels.id = this.subscriptionId
    this.hotels.fullName = this.hotelName
    this.hotels.hotelLatitude = event.latLng.lat()
    this.hotels.hotelLongitude = event.latLng.lng()
    this.hotels.address = hotelAddress
    this.hotels.isUpdateXY = true
    this.subscribeHotelMapEvent.next(this.hotels)
  }

  onChangeHotelAddressEvent (address) {
    this.hotels.fullName = this.hotelName
    this.hotels.address = address.target.value
    this.hotels.hotelLatitude = this.latitude
    this.hotels.hotelLongitude = this.longitude
    this.hotels.isUpdateXY = false
    this.subscribeHotelMapEvent.next(this.hotels)
  }

  openQRCodeDialog(){
    this.themeSettingsService.getQRCodeMap(this.subscriptionId).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        const dialogRef = this.dialog.open(QRCodeComponent, {
          data: {
            qrCodeImage: base64data,
            qrCodeTitle : "QR-Code for preview"
          },
        });

        this.subs.sink = dialogRef.afterClosed().subscribe(res => {});
      };
    });
  }

  goToGooglePlayStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
  goToAppStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
}
