<section class="content">
    <div class="content-block">
        <div class="block-header">
            <!-- breadcrumb -->
            <app-breadcrumb [title]="'guestApp'" [items]="['home', 'settings','guestApp']"
                [active_item]="'hotelMap'"></app-breadcrumb>
        </div>
        <div class="card">
            <div class="body">
                <div class="map-preview-wrapper">
                    <div class="form">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <app-title-content [title]="'map' | translate"
                                    [subTitle]="'hotelMapSubtitle' | translate"></app-title-content>
                                <form [formGroup]="hotelMapGroupForm">
                                    <div class="row edge-spacing">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>{{'hotelAddress' | translate}}</mat-label>
                                            <input matInput formControlName="hotelAddress"
                                                (keyup)="onChangeHotelAddressEvent($event)">
                                            <mat-error *ngIf="hotelMapGroupForm.controls['hotelAddress'].value === '' ">
                                                {{'hotelAddressIsRequired' | translate}}
                                            </mat-error>
                                            <mat-error
                                                *ngIf="hotelMapGroupForm.controls['hotelAddress']?.hasError('minlength')">
                                                {{'hotelAddressAtLeast4Char' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="row edge-spacing">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label> <mat-icon matSuffix>search</mat-icon>
                                                {{'searchLocation' | translate}}</mat-label>
                                            <input #search matInput (keydown.enter)="$event.preventDefault()"
                                                type="text" placeholder="Search Nearest Location" autocorrect="off"
                                                autocapitalize="off" spellcheck="off" />
                                        </mat-form-field>
                                    </div>
                                    <div class="row edge-spacing">
                                        <google-map #myGoogleMap height="500px" width="100%" [zoom]="zoom"
                                            [center]="center" [options]="options">
                                            <map-marker #markerElem *ngFor="let marker of markers"
                                                [position]="marker.position" [label]="marker.label"
                                                [title]="marker.title" [options]="marker.options"
                                                (mapDragend)="onDragend($event,hotels,hotelMapGroupForm.controls['hotelAddress'].value)">
                                            </map-marker>
                                        </google-map>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <div class="body container-preview">
                                    <app-preview-mobile-google-map
                                        [subscribeHotelMapReceiver]="subscribeHotelMapEvent.asObservable()"
                                        [subscribeHotelMapCoordinatesReceiver]="subscribeHotelMapCoordinates.asObservable()"
                                        (qrScanPreviewClick)="openQRCodeDialog()"></app-preview-mobile-google-map>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-store-buttons">
                    <div class="group-stores">
                        <div class="btn-google-play-store" (click)="goToGooglePlayStore()"></div>
                        <div class="btn-apple-store" (click)="goToAppStore()"></div>
                    </div>
                </div>
                <HR>
                <div class="mystay-button-container form-actions">
                    <button type="submit" class="mystay-button-submit" mat-raised-button color="primary"
                        (click)="onSubmit()" [disabled]="isUpload">
                        {{ 'save' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>