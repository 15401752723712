import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HotelMapRoutingModule } from './hotel-map-routing.module';
import { ComponentsModule } from '@shared/components/components.module';
import { HotelMapComponent } from './hotel-map.component';
import { HotelGoogleMapModule } from './hotel-google-map/hotel-google-map.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { PreviewMobileGoogleMapModule } from '@shared/components/preview-mobile-devices/preview-mobile-google-map/preview-mobile-google-map.module';
import { SharedModule } from '@shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HotelMapComponent],
  imports: [
    CommonModule,
    HotelMapRoutingModule,
    ComponentsModule, 
    HotelGoogleMapModule,
    TranslateModule,
    GoogleMapsModule,
    PreviewMobileGoogleMapModule,
    SharedModule
  ],
  exports:[HotelMapComponent]
})
export class HotelMapModule { }



