import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreakfastRoutingModule } from './breakfast-routing.module';
import { BreakfastComponent } from './breakfast.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PreviewMobileBreakfastModule } from '@shared/components/preview-mobile-devices/preview-mobile-breakfast/preview-mobile-breakfast.module';
import { ComponentsModule } from '@shared/components/components.module';
import { SharedModule } from '@shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BreakfastComponent],
  imports: [
    BreakfastRoutingModule,
    CKEditorModule,
    PreviewMobileBreakfastModule,
    CommonModule,
    ComponentsModule,
    TranslateModule,
    SharedModule
  ],
  exports:[BreakfastComponent]
})
export class BreakfastModule { }
