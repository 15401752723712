import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransportRoutingModule } from './transport-routing.module';
import { ComponentsModule } from '@shared/components/components.module';
import { TransportComponent } from './transport.component';
import { SharedModule } from '@shared';
import { PreviewMobileTransportModule } from '@shared/components/preview-mobile-devices/preview-mobile-transport/preview-mobile-transport.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TransportComponent],
  imports: [
    CommonModule,
    TransportRoutingModule,
    ComponentsModule,
    SharedModule,
    PreviewMobileTransportModule,
    TranslateModule,
  ],
  exports:[TransportComponent]
})
export class TransportModule { }
