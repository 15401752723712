import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { fakeBackendProvider } from './core/interceptors/fake-backend';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FooterComponent } from './layout/footer/footer.component';
import { WidgetCaptionsModule } from './modules/theme-settings/widget-captions/widget-captions.module';
import { BreakfastModule } from './modules/theme-settings/breakfast/breakfast.module';
import { HotelMapModule } from './modules/theme-settings/hotel-map/hotel-map.module';
import { HotelGuideModule } from './modules/theme-settings/hotel-guide/hotel-guide.module';
import { TransportModule } from './modules/theme-settings/transport/transport.module';
import { GalleryModule } from './modules/theme-settings/gallery/gallery.module';
import { CheckInModule } from './modules/theme-settings/check-in/check-in.module';
import { CheckOutModule } from './modules/theme-settings/check-out/check-out.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AppSetting } from 'app/app-settings';
import { RoleGuard } from '@core/guards/role.guard';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { MessageService } from '@core/services/message.service';
import { ToastrModule } from 'ngx-toastr';
import { ChatService } from '@core/services/chat.service';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { QuickSearchSidebarComponent } from './layout/quick-search.sidebar/quick-search-sidebar.component';
import { ExportAsModule } from 'ngx-export-as';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, AppSetting.apiUrl + 'api/v1/translation/', '');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    QuickSearchSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    FooterComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingBarRouterModule,
    NgScrollbarModule,
    MatSelectCountryModule.forRoot('en'),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    // core & shared
    CoreModule,
    SharedModule,
    WidgetCaptionsModule,
    BreakfastModule,
    HotelMapModule,
    HotelGuideModule,
    TransportModule,
    GalleryModule,
    CheckInModule,
    CheckOutModule,
    MatDialogModule,
    MatButtonModule,
    ExportAsModule
  ],
  providers: [
    RoleGuard,
    MessageService,
    ChatService,
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
