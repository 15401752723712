import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBrandIdentity, IWidgetCaption, IWidgetContent } from '@core/models/theme-settings/theme-settings.model';
import { image } from 'd3';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-preview-mobile-device',
  templateUrl: './preview-mobile-device.component.html',
  styleUrls: ['./preview-mobile-device.component.scss'],
  providers: [DatePipe],
})
export class PreviewMobileDeviceComponent implements OnInit {
  @Input() subscribeBrandIdentityReceiver: Observable<IBrandIdentity> = null;
  @Input() subscribeWidgetReceiver: Observable<IWidgetCaption> = null;
  @Output() qrScanPreviewClick: EventEmitter<any> = new EventEmitter();

  public subscribeWidgetEvent: Subject<any> = new Subject();
  constructor(private elRef: ElementRef, private datePipe: DatePipe) {}

  public widgetCaption: IWidgetCaption = {
    breakfast: '',
    gallery: '',
    chat: '',
    call: '',
    wifi: '',
    widgetColor: '',
  };

  public brandIdentity: IBrandIdentity = {
    hotelName: '',
    location: '',
    phoneNumber: '',
    brandColor: '',
    logoFile: undefined,
    logoURL: '',
    backgroundFile: undefined,
    backgroundURL: '',
    textColor: '',
  };

  primaryColor: string = '#8F3C3C';
  backgroundTransparent: string = 'rgba(148, 95, 80, 0.20)';

  public widgets: Array<IWidgetContent> = [
    {
      title: 'Room No.',
      icon: 'icon-bed',
      content: '',
      contentFontSize: '18px',
      backgroundWidgetColor: this.backgroundTransparent,
      fontWidgetColor: this.primaryColor,
    },
    {
      title: 'Wifi',
      icon: 'icon-wifi',
      content: '',
      contentFontSize: '10px',
      backgroundWidgetColor: this.backgroundTransparent,
      fontWidgetColor: this.primaryColor,
    },
    {
      title: 'Housekeeping',
      icon: 'icon-vacuum',
      content: '',
      contentFontSize: '9px',
      backgroundWidgetColor: this.backgroundTransparent,
      fontWidgetColor: this.primaryColor,
    },
    {
      title: 'Breakfast',
      icon: 'icon-breakfast',
      content: this.widgetCaption.breakfast,
      contentFontSize: '9px',
      backgroundWidgetColor: this.backgroundTransparent,
      fontWidgetColor: this.primaryColor,
    },
    {
      title: 'Map',
      icon: 'icon-map',
      content: '',
      contentFontSize: '9px',
      backgroundWidgetColor: this.backgroundTransparent,
      fontWidgetColor: this.primaryColor,
    },
    {
      title: 'Gallery',
      icon: 'icon-gallery',
      content: this.widgetCaption.gallery,
      contentFontSize: '9px',
      backgroundWidgetColor: this.backgroundTransparent,
      fontWidgetColor: this.primaryColor,
    },
    {
      title: 'Chat',
      icon: 'icon-chat',
      content: this.widgetCaption.chat,
      contentFontSize: '9px',
      backgroundWidgetColor: this.backgroundTransparent,
      fontWidgetColor: this.primaryColor,
    },
    {
      title: 'Call',
      icon: 'icon-call',
      content: this.widgetCaption.call,
      contentFontSize: '9px',
      backgroundWidgetColor: this.backgroundTransparent,
      fontWidgetColor: this.primaryColor,
    },
  ];

  public get clockOnMobile() {
    return this.datePipe.transform(Date.now(), 'H:mm');
  }
  public get arrivedDate() {
    return this.datePipe.transform(Date.now(), 'MMM d, YY');
  }
  public get departureDate() {
    const date: Date = new Date();
    date.setDate(date.getDate() + 2);
    return this.datePipe.transform(date, 'MMM d, YY');
  }

  ngOnInit(): void {
    if (this.subscribeBrandIdentityReceiver) {
      this.subscribeBrandIdentityReceiver?.subscribe(result => {
        this.brandIdentity.hotelName = result.hotelName === '' ? this.brandIdentity.hotelName : result.hotelName;
        this.brandIdentity.location = result.location === '' ? this.brandIdentity.location : result.location;
        this.brandIdentity.phoneNumber =
          result.phoneNumber === '' ? this.brandIdentity.phoneNumber : result.phoneNumber;
        this.brandIdentity.brandColor = result.brandColor === '' ? this.brandIdentity.brandColor : result.brandColor;
        this.brandIdentity.logoURL = result.logoURL === '' ? this.brandIdentity.logoURL : result.logoURL;
        this.brandIdentity.backgroundURL =
          result.backgroundURL === '' ? this.brandIdentity.backgroundURL : result.backgroundURL;

        this.brandIdentity.textColor = result.textColor === '' ? this.brandIdentity.textColor : result.textColor;

        this.setPropertyBrandIdentityTextColor(this.brandIdentity.textColor);
        this.widgetCaption.widgetColor = this.brandIdentity.textColor;

        const widgetList: Array<IWidgetContent> = [];
        this.widgets.map(x => {
          x.fontWidgetColor = this.brandIdentity.textColor;
          x.backgroundWidgetColor = this.brandIdentity.brandColor;
          x.fontWidgetColor = this.brandIdentity.textColor;
          widgetList.push(x);
        });
        this.subscribeWidgetEvent.next(widgetList);
      });
    }

    if (this.subscribeWidgetReceiver) {
      this.subscribeWidgetReceiver?.subscribe(result => {
        this.widgets.map((item: IWidgetContent) => {
          if (item.title === 'Breakfast') {
            item.content = result.breakfast === '' ? item.content : result.breakfast;
          }
          if (item.title === 'Gallery') {
            item.content = result.gallery === '' ? item.content : result.gallery;
          }
          if (item.title === 'Chat') {
            item.content = result.chat === '' ? item.content : result.chat;
          }
          if (item.title === 'Call') {
            item.content = result.call === '' ? item.content : result.call;
          }
          if (item.title === 'Wifi') {
            item.content = result.wifi === '' ? item.content : result.wifi;
          }
          if (item.title === 'Room No.') {
            item.content = '-';
          }
          if (item.title === 'Housekeeping') {
            item.content = '-';
          }
          if (item.title === 'Map') {
            item.content = '-';
          }
          item.fontWidgetColor = result.widgetColor;
          this.setPropertyWidgetTextColor(item.fontWidgetColor);
          this.setPropertyBrandIdentityTextColor(item.fontWidgetColor);

          return item;
        });
      });
    }
  }

  setPropertyBrandIdentityTextColor(textColor: string): void {
    this.elRef.nativeElement.style.setProperty('--booking-theme-color', textColor == '#FFFFFF' ? '#FFFFFF' : '#000000');
  }
  setPropertyWidgetTextColor(widgetColor: string): void {
    widgetColor == '#FFFFFF' ? this.backgroundTransparent : '#000000';
    this.elRef.nativeElement.style.setProperty(
      '--dynamic-widget-container-color',
      widgetColor == '#FFFFFF' ? this.backgroundTransparent : ' rgba(255,255,255,0.2)'
    );
    this.elRef.nativeElement.style.setProperty(
      '--dynamic-widget-font-color',
      widgetColor == '#FFFFFF' ? '#FFFFFF' : '#000000'
    );
  }

  getImageURL(imageURL: string) {
    return `url(${imageURL})`;
  }
}
