<div class="container-fluid mystay-preview-mobile-hotel-guide">
    <div class="mystay-container-preview-mobile-hotel-guide">
        <div class="mystay-preview-mobile-inside-hotel-guide">
            <br />
            <div class="mystay-preview-mobile-image-hotel-guide"></div>
            <div class="mystay-preview-mobile-title-hotel-guide">Hotel guide</div>
            <div class="mystay-preview-mobile-content-hotel-guide">
               <iframe class="pdf-viewer-preview" [src]="this.urlPDF?.changingThisBreaksApplicationSecurity  | mySafe "   type="application/pdf" frameborder="0"></iframe>
            </div>
            <div class="mystay-preview-mobile-hotel-guide"> </div>
            <div class="mystay-preview-mobile-nav-bar-main">
                <app-mobile-navigator-bar></app-mobile-navigator-bar>
            </div>

        </div>

    </div>
</div>