import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICheckInOut } from '@core/models/theme-settings/theme-settings.model';
import { decode } from 'html-entities';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-preview-mobile-check-in-out',
  templateUrl: './preview-mobile-check-in-out.component.html',
  styleUrls: ['./preview-mobile-check-in-out.component.scss'],
})
export class PreviewMobileCheckInOutComponent implements OnInit {
  @Input() subscribeCheckInOutReceiver: Observable<ICheckInOut> = null;
  @Input() title: string;
  @Output() qrScanPreviewClick: EventEmitter<any> = new EventEmitter();

  constructor(private route: ActivatedRoute) {}

  public backgroundImage: string = '';

  messages: string;
  titleMessage: string;

  public get clockOnMobile() {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(Date.now(), 'H:mm');
  }
  ngOnInit(): void {
    this.subscribeCheckInOutReceiver?.subscribe(result => {
      this.route.url.subscribe(url => {
        if (url[0].path == 'check-out') {
          this.messages = decode(result.checkOutMessages);
          this.backgroundImage = result.backgroundUrl;
          this.titleMessage = 'check-out';
        }
        if (url[0].path == 'check-in') {
          this.messages = decode(result.checkInMessages);
          this.backgroundImage = result.backgroundUrl;
          this.titleMessage = 'check-in';
        }
      });
    });
  }
}
