<div class="custom-menu" [ngStyle]="{ top: position.y + 'px', left: position.x + 'px' }"   (click)="$event.stopPropagation()">
  <ul>
    <li *ngFor="let action of actions" (click)="onActionSelect(action.value, $event)">
      <span class="menu-item-label">{{ action.label }}</span>
      <mat-icon *ngIf="action.children" class="menu-item-icon">arrow_right</mat-icon>
      <div *ngIf="action.children" class="nested-menu">
        <ul>
          <li
            *ngFor="let child of action.children"
            (click)="$event.stopPropagation(); onActionSelect(child.value, $event)"
            class="nested-menu-label"
            >
            {{ child.label }}
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
