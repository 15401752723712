<mat-pseudo-checkbox class="mat-option-pseudo-checkbox" [ngClass]="selected ? 'bg-accent' : ''">
</mat-pseudo-checkbox>

<mat-icon
  [ngClass]="{
    checked: checkboxIcon === 'check_box',
    indeterminate: checkboxIcon === 'indeterminate_check_box',
    unchecked: checkboxIcon === 'check_box_outline_blank'
  }"
  [color]="'primary'"
  >
  {{ checkboxIcon }}
</mat-icon>
<span class="mat-option-text">
  {{ title }}
</span>
