<section class="content">
    <div class="content-block">
        <div class="block-header">
            <!-- breadcrumb -->
            <app-breadcrumb [title]="'guestApp'" [items]="['home', 'settings','guestApp']"
                [active_item]="'breakfast'"></app-breadcrumb>
        </div>
        <div class="card">
            <div class="body">
                <div class="breakfast-preview-wrapper">
                    <div class="form">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <app-title-content [title]="'breakfast' | translate"
                                    [subTitle]="'breakfastSettingSubtitle' | translate"></app-title-content>
                                <form [formGroup]="breakfastGroupForm">
                                    <div class="row  edge-spacing">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>{{ 'upload' | translate }} {{ 'background'
                                                | translate }} ({{
                                                'recommendedSizeOf' | translate
                                                }}
                                                1080x1920 pixels)</mat-label>
                                            <button class="button-upload" matPrefix (click)="f_inputBackground.click()">
                                                <button type="button" class="mystay-button-choose-file">{{
                                                    'chooseFile'
                                                    |
                                                    translate }}</button>
                                            </button>
                                            <input type="text" readonly matInput formControlName="headerImageName" />
                                            <input type="file" hidden #f_inputBackground
                                                formControlName="backgroundUploaded"
                                                (change)="handleFileInputBackgroundChange($event, f_inputBackground.files)"
                                                accept=".png,.jpeg,.jpg" />
                                            <mat-error
                                                *ngIf="breakfastGroupForm.controls['backgroundUploaded']?.hasError('required')">
                                                {{ 'uploadBackgroundIsRequired' | translate }}
                                            </mat-error>
                                            <mat-error class="breakfast-error"
                                                *ngIf="breakfastGroupForm.controls['backgroundUploaded']?.hasError('invalidFileType')">
                                                {{ "Invalid file type. Please select a JPG or PNG image." |
                                                translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="row edge-spacing">
                                        <div class="mystay-ck-text-editor text-editor-1">
                                            <span class="breakfast-label-font"> {{'notification' |
                                                translate}}: {{'breakfastIncluded' | translate}}</span>
                                            <ckeditor [editor]="editor" [config]="ckeditorConfig" [data]="content"
                                                formControlName="description"
                                                (change)="handleDescriptionChange(breakfastGroupForm)">
                                            </ckeditor>
                                            <mat-error class="breakfast-error"
                                                *ngIf="breakfastGroupForm.controls['description']?.hasError('required')">
                                                {{'descriptionIsRequired' | translate}}
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="row edge-spacing">
                                        <div class="mystay-ck-text-editor text-editor-2">
                                            <span class="breakfast-label-font"> {{'notification' |
                                                translate}}: {{'breakfastNotIncluded' | translate}}</span>
                                            <div class="mystay-ck-text-editor">
                                                <ckeditor [editor]="editor" [config]="ckeditorConfig" [data]="content"
                                                    formControlName="notification"
                                                    (change)="handleNotificationChange(breakfastGroupForm)">
                                                </ckeditor>
                                            </div>
                                            <mat-error
                                                *ngIf="breakfastGroupForm.controls['notification']?.hasError('required')">
                                                {{'notificationIsRequired' | translate}}
                                            </mat-error>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <div class="preview">
                                    <app-preview-mobile-breakfast
                                        [subscribeBreakfastReceiver]="subscribeBreakfastEvent.asObservable()"
                                        (qrScanPreviewClick)="openQRCodeDialog()"
                                        [subscribeBreakfastContentReceiver]="subscribeBreakfastContentEvent.asObservable()"
                                        [isIncluded]="breakfastDropdown"></app-preview-mobile-breakfast>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-store-buttons">
                    <div class="group-stores">
                        <div class="btn-google-play-store" (click)="goToGooglePlayStore()"></div>
                        <div class="btn-apple-store" (click)="goToAppStore()"></div>
                    </div>
                </div>
                <HR>
                <div class="mystay-button-container form-actions">
                    <button type="submit" class="mystay-button-submit" mat-raised-button color="primary"
                        (click)="onSubmit()" [disabled]="isUploadFile">{{'save' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</section>