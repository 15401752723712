import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report-pdf',
  templateUrl: './report-pdf.component.html',
  styleUrls: ['./report-pdf.component.scss'],
})
export class ReportPdfComponent implements OnInit {
  constructor (private route: ActivatedRoute) {}

  previewAction: string;
  ngOnInit (): void {
    this.route.queryParams.subscribe(params => {
      if (params['downloading'] == '1') {
        const downloadPDF = document.getElementById('tm_download_btn');
        setTimeout(() => {
          downloadPDF.click();
        }, 500);

        downloadPDF.addEventListener('click', function () {
          setTimeout(() => {
            window.close();
          }, 1000);
        });
      }
    });
  }
}
