import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { BaseAPIService } from './base-api.service';
import { ICustomerFilterResult } from '@core/models/customers/customer-filter.model';
import { CustomersDataTable } from '@core/models/customers/customers.model';
import { PagingResult } from '@core/models/base/paging-result.model';

@Injectable()
export class SidebarSearchService extends BaseAPIService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getSearchResult(subscriptionId: string, search: string): Observable<any> {
    return this.get(`search/${subscriptionId}?search=${search}`);
  }
}
