import { Component } from '@angular/core';

@Component({
  selector: 'app-submit-panel',
  templateUrl: './submit-panel.component.html',
  styleUrls: ['./submit-panel.component.scss']
})
export class SubmitPanelComponent {

}
