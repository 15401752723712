export interface IGalleryItem {
  sequence?: number;
  imageFile?: any;
  imageUrl?: any;
  caption?: string;
  blobId?: any;
  newCaption?: string;
  isUploaded?: boolean;

}

export interface IGallery {
  id?: any;
  subscriptionId: string;
  title: string;
  description: string;
  openHours: string;
  tags: any;
  enabled: boolean;
  sequence: number;
  galleryItems: Array<IGalleryItems>;
}
export interface IGalleryItems {
  galleryId: string;
  caption: string;
  blobId: string;
  sequence: number;
}

export interface Gallery {
  subscriptionId: string;
  galleryCategories: GalleryCategory[];
}

export class GalleryCategory {

  id: string;
  title: string;
  description: string;
  openHours: string;
  tags: string;
  enabled: boolean;
  sequence: number;
  galleryCategoryImagesDTOs: Array<GalleryCategoryImagesDTO>;
  backgroundUrl? : string;
}

export interface subscriptionImage {
  id: string;
  imageUrl: string;
}

export interface GalleryCategoryImagesDTO {
  galleryId: string;
  caption: string;
  blobId: string;
  sequence: number;
  imageUrl : string ; 
}
