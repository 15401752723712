import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewMobileBreakfastRoutingModule } from './preview-mobile-breakfast-routing.module';
import { PreviewMobileBreakfastComponent } from './preview-mobile-breakfast.component';
import { TranslateModule } from '@ngx-translate/core';
import {  MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations: [PreviewMobileBreakfastComponent],
  imports: [
    CommonModule,
    PreviewMobileBreakfastRoutingModule,
    TranslateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports : [PreviewMobileBreakfastComponent]
})
export class PreviewMobileBreakfastModule { }
