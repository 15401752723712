import { Component, ElementRef, Input, OnInit } from '@angular/core'
import { IWidgetContent } from '@core/interfaces'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-preview-mobile-widgets',
  templateUrl: './preview-mobile-widgets.component.html',
  styleUrls: ['./preview-mobile-widgets.component.scss']
})
export class PreviewMobileWidgetsComponent implements OnInit {
  public constructor (private elRef: ElementRef) {}
  @Input() subscribeWidgetReceiver: Observable<IWidgetContent> = null

  public widgets: any = []

  ngOnInit (): void {
    this.subscribeWidgetReceiver?.subscribe((result: any) => {
      this.widgets = result
      result.map((item: IWidgetContent) => {
        setTimeout(()=>{
          this.setPropertyWidget(item.fontWidgetColor)
        },50)
     
      })
    })
  }

  setPropertyWidget (fontWidgetColor): void {
    this.elRef.nativeElement.style.setProperty('--dynamic-widget-font-color',fontWidgetColor)
    this.elRef.nativeElement.style.setProperty('--booking-theme-color',  fontWidgetColor)  
  }
}
