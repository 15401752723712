import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewMobileGoogleMapComponent } from './preview-mobile-google-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import {MatIconModule} from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PreviewMobileGoogleMapComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
    MatIconModule,
    TranslateModule
  ],
  exports:[PreviewMobileGoogleMapComponent]
})
export class PreviewMobileGoogleMapModule { }
