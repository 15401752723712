import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HotelGoogleMapRoutingModule } from './hotel-google-map-routing.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { HotelGoogleMapComponent } from './hotel-google-map.component';


@NgModule({
  declarations: [HotelGoogleMapComponent],
  imports: [
    CommonModule,
    HotelGoogleMapRoutingModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  exports:[HotelGoogleMapComponent]
})
export class HotelGoogleMapModule { }
