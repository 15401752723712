<section class="content">
    <div class="content-block">
        <div class="block-header">
            <!-- breadcrumb -->
            <app-breadcrumb [title]="'guestApp'" [items]="['home', 'settings','guestApp']" [active_item]="'transport'"></app-breadcrumb>
        </div>
        <div class="mystay-container-content">
            <form #transportForm="ngForm" [formGroup]="transportGroupForm">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <app-title-content [title]="'hotelTransport' | translate"
                            [subTitle]="'hotelTransportSubtitle' | translate"></app-title-content>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div class="mystay-textarea-container">
                                        <mat-label class="mystay-label-text">{{'hotelTransportWelcomMessage' | translate}}
                                        </mat-label>
                                        <textarea matInput class="mystay-textarea"
                                            placeholder="{{'description' | translate}}"
                                            formControlName="description"
                                            (keyup)="handleWelcomeMessageChange(transportGroupForm)" maxlength="165" ></textarea>
                                    </div>
                                    <mat-error *ngIf="transportGroupForm.controls['description']?.hasError('required')">
                                        {{'descriptionIsRequired' | translate}}
                                    </mat-error>
                                    <mat-error
                                    *ngIf="transportGroupForm.controls['description']?.hasError('minlength')">
                                    {{'descriptionAtLeast4Char' | translate}}
                                </mat-error>
                                <mat-error
                                    *ngIf="transportGroupForm.controls['description']?.hasError('maxlength')">
                                    {{'descriptionNoMore4Char' | translate}}
                                </mat-error>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="row">
                            <div class="col-12">
                                <app-preview-mobile-transport [subscribeTransportReceiver]="subscribeTransportEvent.asObservable()"></app-preview-mobile-transport>
                            </div>
                        </div>

                    </div>
                    <hr />

                    <div class="mystay-container-action-submit form-actions">
                        <button type="submit"  mat-raised-button color="primary" (click)="onSubmit()" [disabled]="isUpload">{{'save' | translate}}</button> 
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>