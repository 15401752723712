import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ThemeSettingsService } from '@core/services/theme-settings.service';
import { decode } from 'html-entities';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';
import { MatDialog } from '@angular/material/dialog';
import { QRCodeComponent } from 'app/modules/reservations/details/dialogs/qrcode/qrcode.component';
import { ICheckInOut, IThemeSettings } from '@core/models/theme-settings/theme-settings.model';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
})
export class CheckInComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  subscription: Subscription;
  public subscribeCheckInEvent: Subject<ICheckInOut> = new Subject();
  subscriptionId: string;
  constructor(
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    super();
  }

  public file_store: FileList;
  public editor = ClassicEditor;
  public headerImage: string = '';
  content: any = ``;
  checkInProps: ICheckInOut = {
    checkOutMessages: null,
    checkInMessages: '',
    backgroundUrl: '',
  };
  isUpload: boolean = false;
  formSubmitted: boolean = false;

  folderBackground: string = 'settingsImage/checkIn';
  headerImageUrl = this.checkInProps.backgroundUrl.split('/').pop();
  backgroundPreviewUrl: string = '';

  checkInGroupForm = new FormGroup({
    checkInMessages: new FormControl(this.checkInProps.checkInMessages, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    backgroundUploaded: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, this.fileTypeValidator(['image/jpeg', 'image/png'])],
    }),
    headerImageName: new FormControl(this.headerImageUrl, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  Editor = ClassicEditor;
  ckeditorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link'],
    table: {
      contentToolbar: ['tableRow', 'tableColumn', 'mergeTableCells', 'tableCellProperties'],
    },
  };

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.subscriptionId = paramMap.get('subscriptionId');
      firstValueFrom(this.themeSettingsService.getThemeSettings(this.subscriptionId)).then(
        (response: IThemeSettings) => {
          this.checkInGroupForm.controls.headerImageName.patchValue(
            this.getNameUrl(
              response.appBackgroundImages.preCheckInBackgroundUrl.substring(
                response.appBackgroundImages.preCheckInBackgroundUrl.lastIndexOf('/') + 1,
                response.appBackgroundImages.preCheckInBackgroundUrl.length
              )
            )
          );
          this.backgroundPreviewUrl = response.appBackgroundImages.preCheckInBackgroundUrl;
          response.contents.forEach(element => {
            if (element.key === 'check_in') {
              this.checkInGroupForm.controls.checkInMessages.patchValue(`${element.contentBody}`);
              this.checkInProps.checkInMessages = decode(element.contentBody);
              this.checkInProps.backgroundUrl = response.appBackgroundImages.preCheckInBackgroundUrl;
              this.subscribeCheckInEvent.next(this.checkInProps);
            }
          });
        }
      );
    });
  }

  getNameUrl(fullUrl: string) {
    const url = fullUrl;
    const urlLength = url.lastIndexOf('?');
    const result = url.slice(0, urlLength);
    return result;
  }

  fileTypeValidator(allowedMimeTypes: string[]) {
    return (control: FormControl): { [key: string]: any } | null => {
      const file = control.value;
      if (file) {
        const isValidType = allowedMimeTypes.includes(file.type);
        return isValidType ? null : { invalidFileType: true };
      }
      return null;
    };
  }

  handleFileInputBackgroundChange(event: any, l: FileList): void {
    this.isUpload = true;
    this.file_store = l;
    if (l.length) {
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.checkInGroupForm.controls.headerImageName.patchValue(`${l[0].name}${count}`);
      if (this.checkInGroupForm.controls['backgroundUploaded']?.hasError('invalidFileType')) {
        if (event.target.files && event.target.files[0]) {
          const reader = new FileReader();
          this.route.paramMap.subscribe(paramMap => {
            this.subscriptionId = paramMap.get('subscriptionId');
            const formData: any = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('folder', this.folderBackground);
            formData.append('Restrict', 'false');
            formData.append('SubscriptionId', this.subscriptionId);

            const upload$ = this.themeSettingsService.uploadFileToCloudStorage(formData);
            this.subscription = upload$.subscribe(response => {
              this.checkInProps.backgroundUrl = response.imageUrl;
              this.checkInGroupForm.controls.headerImageName.patchValue(
                this.getNameUrl(
                  response.imageUrl.substring(response.imageUrl.lastIndexOf('/') + 1, response.imageUrl.length)
                )
              );
              this.isUpload = false;
              const mapping = {
                subscriptionId: this.subscriptionId,
                preCheckInBackgroundUrl: response.imageUrl,
                appBackground: 1,
              };
              const upload$1 = this.themeSettingsService.updateBackgroundImage(mapping);
              upload$1.subscribe(res => {
                res;
              });
            });
            this.isUpload = false;
          });

          reader.readAsDataURL(event.target.files[0]); // read file as data url
          let url: any;
          reader.onload = event => {
            url = event.target.result;
            this.checkInProps.backgroundUrl = url;
            this.subscribeCheckInEvent.next(this.checkInProps);
          };
        }
      }
    } else {
      this.checkInGroupForm.controls.headerImageName.patchValue('');
      this.isUpload = false;
    }
  }

  handleCompleteMessageChange(checkIn: any) {
    this.checkInProps.backgroundUrl = this.backgroundPreviewUrl;
    this.checkInProps.checkInMessages = checkIn.value.checkInMessages;
    this.subscribeCheckInEvent.next(this.checkInProps);
  }

  async onSubmit() {
    this.formSubmitted = true;
    const paramMap = await firstValueFrom(this.route.paramMap);
    this.subscriptionId = paramMap.get('subscriptionId');
    this.checkInProps = {
      id: this.subscriptionId,
      checkInMessages: this.checkInGroupForm.controls.checkInMessages.value,
    };

    firstValueFrom(this.themeSettingsService.updateCheckIn(this.checkInProps))
      .then(() => {
        const successMessage = this.translate.instant('checkInMessageHasBeenSaved');
        this.toastr.success(successMessage, 'Operation Completed', { timeOut: 3500 });
      })
      .catch(() => {
        const failedMessage = this.translate.instant('failedToSave');
        this.toastr.error(failedMessage, 'Operation Failed', { timeOut: 3500 });
      })
      .finally(() => {
        this.isUpload = false;
      });
  }

  openQRCodeDialog() {
    this.themeSettingsService.getQRCodeCheckin(this.subscriptionId).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64data = reader.result;
        const dialogRef = this.dialog.open(QRCodeComponent, {
          data: {
            qrCodeImage: base64data,
            qrCodeTitle: 'QR-Code for preview',
          },
        });

        this.subs.sink = dialogRef.afterClosed().subscribe(res => {});
      };
    });
  }

  goToGooglePlayStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
  goToAppStore() {
    window.open(environment.googlePlayUrl, '_blank');
  }
}
