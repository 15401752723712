<section class="content">
  <div class="content-block">
    <div class="block-header">
      <!-- breadcrumb -->
      <app-breadcrumb [title]="'guestApp'" [items]="['home', 'settings', 'guestApp']"
        [active_item]="'checkOut'"></app-breadcrumb>
    </div>
    <div class="card">
      <div class="body">
        <div class="checkout-preview-wrapper">
          <div class="form">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <app-title-content [title]="'checkOut' | translate"
                  [subTitle]="'hotelCheckOutSubtitle' | translate"></app-title-content>
                <form [formGroup]="checkOutGroupForm">
                  <div class="row edge-spacing">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{ 'upload' | translate }} {{ 'background'
                        | translate }} ({{
                        'recommendedSizeOf' | translate
                        }}
                        1080x1920 pixels)</mat-label>
                      <button
                        style="width: 130px; height: 36px; border-radius: 0px; border: 0px; background: transparent"
                        matPrefix (click)="f_inputBackground.click()">
                        <button type="button" class="mystay-button-choose-file">{{ 'chooseFile' |
                          translate }}</button>
                      </button>
                      <input type="text" readonly matInput formControlName="headerImageName" />
                      <input type="file" hidden #f_inputBackground formControlName="backgroundUploaded"
                        (change)="handleFileInputBackgroundChange($event, f_inputBackground.files)"
                        accept=".png,.jpeg,.jpg" />
                      <mat-error *ngIf="checkOutGroupForm.controls['backgroundUploaded']?.hasError('required')">
                        {{ 'uploadBackgroundIsRequired' | translate }}
                      </mat-error>
                      <mat-error *ngIf="checkOutGroupForm.controls['backgroundUploaded']?.hasError('invalidFileType')">
                        {{ 'Invalid file type. Please select a JPG or PNG image.' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="row edge-spacing">
                    <span class="checkout-label-font">{{ 'checkOutCompleteMessage' | translate }}</span>
                    <div class="mystay-ck-text-editor">
                      <ckeditor [editor]="editor" [config]="ckeditorConfig" [data]="content"
                        formControlName="completeMessage" (keyup)="handleCompleteMessageChange(checkOutGroupForm)">
                      </ckeditor>
                    </div>
                    <mat-error *ngIf="formSubmitted && checkOutGroupForm.get('completeMessage').invalid">
                      {{ 'messageIsRequired' | translate }}
                    </mat-error>
                  </div>
                  <div class="row edge-spacing">&nbsp;</div>
                  <div class="row edge-spacing">&nbsp;</div>
                </form>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="container-preview">
                  <app-preview-mobile-check-in-out title="Check-out completed" (qrScanPreviewClick)="openQRCodeDialog()"
                    [subscribeCheckInOutReceiver]="
                  subscribeCheckOutEvent.asObservable()
                "></app-preview-mobile-check-in-out>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-store-buttons">
          <div class="group-stores">
            <div class="btn-google-play-store" (click)="goToGooglePlayStore()"></div>
            <div class="btn-apple-store" (click)="goToAppStore()"></div>
          </div>
        </div>
        <HR>
        <div class="mystay-button-container form-actions">
          <button type="submit" class="mystay-button-submit" mat-raised-button color="primary" (click)="onSubmit()"
            [disabled]="isUpload">
            {{ 'save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>