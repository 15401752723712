export class User {
  constructor(name: string) {
    // Constructor
    this.username = name;
  }
  id!: string;
  img!: string;
  username!: string;
  password!: string;
  firstName!: string;
  lastName!: string;
  token!: string;
}

export interface Identity {
  id: string;
  firebaseUID: string;
  subscriptionId: string;
  subscriptionName: string;
  displayName: string;
  email: string;
  isSuperAdmin: boolean;
  uiTheme: number;
  language: number;
  phoneNumber: string;
  isSubscriptionOwner: boolean;
  housekeepingPermission: boolean;
  receptionPermission: boolean;
  restaurantPermission: boolean;
  transportPermission: boolean;
  managementPermission: boolean;
  roles?: any[];
}
