export const environment = {
  production: true,
  defaultPagingSize: 10,
  apiUrl: 'https://api-dev.supremestay.com/',
  firebaseConfig : {
    apiKey: "AIzaSyCqFOwAF93L9l-c5KiCgRmxYAVRULZRe-Y",
    authDomain: "mystay-eb8fe.firebaseapp.com",
    projectId: "mystay-eb8fe",
    storageBucket: "mystay-eb8fe.appspot.com",
    messagingSenderId: "359730769664",
    appId: "1:359730769664:web:da605307bc71a8abea8de5",
    measurementId: "G-RJL30XNYX7"
  },
  googlePlayUrl : "https://play.google.com/store/apps/details?id=com.supremestay.guest&hl=en-US&ah=9E8MgMMEDkxXPI94iJHVUvNMDoM",
  playStoreUrl : ""
};
