import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobileNavigatorBarRoutingModule } from './mobile-navigator-bar-routing.module';
import { MobileNavigatorBarComponent } from './mobile-navigator-bar.component';


@NgModule({
  declarations: [MobileNavigatorBarComponent],
  imports: [
    CommonModule,
    MobileNavigatorBarRoutingModule
  ],
  exports:[MobileNavigatorBarComponent]
})
export class MobileNavigatorBarModule { }
