import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckOutRoutingModule } from './check-out-routing.module';
import { CheckOutComponent } from './check-out.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PreviewMobileCheckInOutModule } from '@shared/components/preview-mobile-devices/preview-mobile-check-in-out/preview-mobile-check-in-out.module';
import { ComponentsModule } from '@shared/components/components.module';
import { SharedModule } from '@shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CheckOutComponent],
  imports: [
    CommonModule,
    CheckOutRoutingModule,
    CKEditorModule,
    TranslateModule,
    PreviewMobileCheckInOutModule,
    ComponentsModule,
    SharedModule
  ],
  exports:[CheckOutComponent]
})
export class CheckOutModule { }
