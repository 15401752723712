<section class="mystay-content-footer">
    <div class="content-block">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="container-fluid">
                    <p>© 2023, Power by <span>Supreme Stay</span> </p>
                </div>
            </div>
        </div>
    </div>
</section>