import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ThemeSettingsService } from '@core/services/theme-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gallery-items',
  templateUrl: './gallery-items.component.html',
  styleUrls: ['./gallery-items.component.scss']
})
export class GalleryItemsComponent implements OnInit {

  @Input() galleryItem : any;
  @Input() galleryIndex : number;
  @Input() subscriptionId : string;

  constructor (
    private themeSettingsService: ThemeSettingsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {
   
   
  }

  selectedIndex : number;
  public file_store: FileList
  isNewUploading : boolean = false
  isUpload: boolean = false
  folderImage: string = 'gallerys'
  subscription: Subscription
  imageUrl : string = null;
  captionImage : string = null;

  ngOnInit(): void {

  }

  mapGalleryItem(sequence, blobId, imageUrl, caption): FormGroup {
    return this.fb.group({
      blobId:blobId,
      sequence: sequence,
      imageUrl:imageUrl,
      caption: caption,
      isUploaded: true,
    });
  }
  
  mapAddGalleryItem(sequence, blobId, imageUrl, caption) {
    this.galleryItem.controls.push(this.mapGalleryItem(sequence, blobId, imageUrl, caption));
  }

  async handleFileInputChange (event: any, l: FileList) {
    this.galleryItem.removeAt(this.galleryItem.length - 1)
    this.isNewUploading = true;
    this.isUpload = true
    this.file_store = l

    if (l.length) {
       for (let i:any = 0; i < event.target.files.length; i++) {
          const formData: any = new FormData()
          formData.append('file', event.target.files[i])
          formData.append('folder', this.folderImage)
          formData.append('Restrict', 'false')
          formData.append('SubscriptionId', this.subscriptionId)

          const upload$ = this.themeSettingsService.uploadFileToCloudStorage(formData)
          this.subscription = upload$.subscribe(response => {
            this.mapAddGalleryItem(this.galleryItem.length + 1, response.blobId,response.imageUrl, 'caption')
            this.isUpload = false
            });
            
      }
      setTimeout(()=>{
        this.galleryItem.push(this.newGalleryItem())
      },1000)
    }
  }

  selectImage (index) {
    this.selectedIndex = index
    document.getElementById("select-file").click();
 }

 newGalleryItem(): FormGroup {
  return this.fb.group({
    blobId: ["", Validators.required],
    sequence: [0, Validators.required],
    imageFile: ["", Validators.required],
    newCaption: ["", Validators.required],
    isUploaded: false,
  });
}
 deleteGalleryItem( blobId: string) {

   }
 
}
