import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GalleryRoutingModule } from './gallery-routing.module';
import { ComponentsModule } from '@shared/components/components.module';
import { SharedModule } from '@shared';
import { GalleryComponent } from './gallery.component';
import { PreviewMobileGalleryModule } from '@shared/components/preview-mobile-devices/preview-mobile-gallery/preview-mobile-gallery.module';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GalleryFormComponent } from './gallery-form/gallery-form.component';
import { GalleryItemsComponent } from './gallery-form/gallery-items/gallery-items.component';


@NgModule({
  declarations: [GalleryComponent, GalleryFormComponent, GalleryItemsComponent],
  imports: [
    CommonModule,
    GalleryRoutingModule,
    ComponentsModule,
    SharedModule,
    TranslateModule,
    PreviewMobileGalleryModule,
    DragDropModule,
  ],
  exports:[GalleryComponent]
})
export class GalleryModule { }
